import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { authActions } from "../../store/auth";
import "./navbar.css";

const Navbar = ({ ...props }) => {
  const { role, widthIsSmall, loc } = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const checkPathName = (path) => {
    if (path === "inbound") {
      if (
        location.pathname === "/grnlist" ||
        location.pathname === "/grn" ||
        location.pathname === "/gdn" ||
        location.pathname === "/add-items" ||
        location.pathname === "/upload"
      ) {
        return true;
      }
    } else if (path === "outbound") {
      if (location.pathname === "/lpn") {
        return true;
      }
    } else if (path === "admin") {
      if (location.pathname === "/data-purge") {
        return true;
      }
    } else if (path === "grndash") {
      if (location.pathname === "/grndash") {
        return true;
      }
    } else if (path === "print") {
      if (location.pathname === "/print-lpn") {
        return true;
      }
    } else if (path === "mkt") {
      if (location.pathname === "/mkt") {
        return true;
      }
    } else if (path === "store") {
      if (
        location.pathname === "/map-tub" ||
        location.pathname === "/map-bayarea"
      ) {
        return true;
      }
    } else if (path === "report") {
      if (
        location.pathname === "/report-upload" ||
        location.pathname === "/report-grn" ||
        location.pathname === "/report-gdn" ||
        location.pathname === "/report-po" ||
        location.pathname === "/pp-report-ftpicking" ||
        location.pathname === "/pp-report-putaway" ||
        location.pathname === "/pp-report-orangelpn" ||
        location.pathname === "/report-shipping" ||
        location.pathname === "/report-unload" ||
        location.pathname === "/pc-report-ftpickcomplete" ||
        location.pathname === "/pc-report-putaway" ||
        location.pathname === "/pc-report-orangelpn" ||
        location.pathname === "/report-checkincomplete" ||
        location.pathname === "/report-checkinpendency" ||
        location.pathname === "/report-extraction-summary"
      ) {
        return true;
      }
    } else if (path === "rg") {
      if (location.pathname === "/rg-portal") {
        return true;
      } else if (path === "ack") {
        if (location.pathname === "/ack-upload") {
          return true;
        }
      }
    } else if (path === "apk") {
      if (location.pathname === "/apk-upload") {
        return true;
      }
    } else if (path === "extract") {
      if (location.pathname === "/extract") {
        return true;
      }
    } else if (path === "nft-bin") {
      if (
        location.pathname === "/nft-bin-mapping" ||
        location.pathname === "/nft-bin-summary"
      ) {
        return true;
      }
    } else if (path === "merge-to") {
      if (
        location.pathname === "/merge-to" ||
        location.pathname === "/merge-to-summary"
      ) {
        return true;
      }
    } else if (path === "lpn-audit") {
      if (location.pathname === "/lpn-audit") {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <nav
      className={[
        "navbar navbar-expand-lg d-flex flex-column",
        role === "inward" ? "pb-0" : "",
      ].join(" ")}
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav nav-expand ms-xl-5 ms-lg-5 ms-1 mt-xl-0 mt-3">
            {role === "inward" && (
              <li
                className={[
                  "nav-item dropdown",
                  checkPathName("inbound") && "nav-active",
                ].join(" ")}
              >
                <div
                  className={[
                    "nav-link dropdown-toggle nav-text d-flex align-items-center",
                    checkPathName("inbound") && "nav-active-color nav-left-pad",
                  ].join(" ")}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div>Inbound</div>
                  <div
                    className={[
                      "arrow",
                      checkPathName("inbound") && "nav-arrow",
                    ].join(" ")}
                  ></div>
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/upload")}
                    >
                      Upload Invoices
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/grnlist")}
                    >
                      Pending GRN
                    </div>
                  </li>
                </ul>
              </li>
            )}

            {role === "outward" && (
              <li
                className={[
                  "nav-item ms-xl-0 dropdown",
                  checkPathName("outbound") && "nav-active",
                ].join(" ")}
              >
                <div
                  className={[
                    "nav-link dropdown-toggle nav-text d-flex align-items-center",
                    checkPathName("outbound") &&
                    "nav-active-color nav-left-pad",
                  ].join(" ")}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div>Outbound</div>
                  <div
                    className={[
                      "arrow",
                      checkPathName("outbound") && "nav-arrow",
                    ].join(" ")}
                  ></div>
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/lpn")}
                    >
                      LPN Summary for Dispatch
                    </div>
                  </li>
                </ul>
              </li>
            )}

            {role === "admin" && (
              <li
                className={[
                  "nav-item ms-xl-0 dropdown",
                  checkPathName("admin") && "nav-active",
                ].join(" ")}
              >
                <div
                  className={[
                    "nav-link dropdown-toggle nav-text d-flex align-items-center",
                    checkPathName("admin") && "nav-active-color nav-left-pad",
                  ].join(" ")}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div>Admin Console</div>
                  <div
                    className={[
                      "arrow",
                      checkPathName("admin") && "nav-arrow",
                    ].join(" ")}
                  ></div>
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/data-purge")}
                    >
                      Data Purge
                    </div>
                  </li>
                </ul>
              </li>
            )}
            {role === "wms_internal" && (
              <li
                className={[
                  "nav-item nav-text text-nowrap",
                  checkPathName("apk") && "nav-active",
                  role === "inward" && "ms-xl-5 ms-lg-5",
                ].join(" ")}
                onClick={() => navigate("/apk-upload")}
              >
                <div
                  className={[
                    "nav-link active",
                    checkPathName("apk") && "nav-active-color ps-4",
                  ].join(" ")}
                  aria-current="page"
                >
                  APK Uploader
                </div>
              </li>
            )}

            {role === "inward" && (
              <li
                className={[
                  "nav-item ms-xl-5 ms-lg-5 dropdown",
                  checkPathName("grndash") && "nav-active",
                ].join(" ")}
              >
                <div
                  className={[
                    "nav-link dropdown-toggle nav-text d-flex align-items-center",
                    checkPathName("grndash") && "nav-active-color nav-left-pad",
                  ].join(" ")}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div>Dashboard</div>
                  <div
                    className={[
                      "arrow",
                      checkPathName("grndash") && "nav-arrow",
                    ].join(" ")}
                  ></div>
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/grndash")}
                    >
                      GRN/GDN Dashboard
                    </div>
                  </li>
                </ul>
              </li>
            )}

            {role === "inventory" && (
              <li
                className={[
                  "nav-item ms-xl-5 nav-text",
                  checkPathName("mkt") && "nav-active",
                ].join(" ")}
                onClick={() => navigate("/mkt")}
              >
                <div
                  className={[
                    "nav-link active",
                    checkPathName("mkt") && "nav-active-color ps-4",
                  ].join(" ")}
                  aria-current="page"
                >
                  MKT Summary
                </div>
              </li>
            )}

            {(role === "inward" || role === "outward") && (
              <li
                className={[
                  "nav-item dropdown ms-xl-5 ms-lg-5",
                  checkPathName("store") && "nav-active",
                ].join(" ")}
              >
                <div
                  className={[
                    "nav-link dropdown-toggle nav-text d-flex align-items-center",
                    checkPathName("store") && "nav-active-color nav-left-pad",
                  ].join(" ")}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div>Store Mapping</div>
                  <div
                    className={[
                      "arrow",
                      checkPathName("store") && "nav-arrow",
                    ].join(" ")}
                  ></div>
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/map-tub")}
                    >
                      TUB
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/map-bayarea")}
                    >
                      Store Bay Area
                    </div>
                  </li>
                </ul>
              </li>
            )}

            {(role === "inward" || role === "outward") && (
              <li
                className={[
                  "nav-item dropdown ms-xl-5 ms-lg-5",
                  checkPathName("report") && "nav-active",
                ].join(" ")}
              >
                <div
                  className={[
                    "nav-link dropdown-toggle nav-text d-flex align-items-center",
                    checkPathName("report") && "nav-active-color nav-left-pad",
                  ].join(" ")}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div>Reports</div>
                  <div
                    className={[
                      "arrow",
                      checkPathName("report") && "nav-arrow",
                    ].join(" ")}
                  ></div>
                </div>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {role === "inward" && (
                    <>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-upload")}
                        >
                          Pending Upload Invoices
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-grn")}
                        >
                          GRN Submitted
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-gdn")}
                        >
                          GDN Submitted
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-po")}
                        >
                          Open Orders
                        </div>
                      </li>
                      <li className="position-relative">
                        <div
                          className="dropdown-item d-flex align-items-center justify-content-between"
                          id="dropdownSubMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div>Picking Pendency</div>
                          <div className="sub-arrow"></div>
                        </div>
                        <ul
                          className="dropdown-menu dropdown-submenu"
                          aria-labelledby="dropdownSubMenuLink"
                        >
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-ftpicking")}
                            >
                              FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-putaway")}
                            >
                              Putaway FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-orangelpn")}
                            >
                              Orange LPN Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-nftl1")}
                            >
                              NFT L1 Picking
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="position-relative">
                        <div
                          className="dropdown-item d-flex align-items-center justify-content-between"
                          id="dropdownSubMenuLink2"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div>Picking Completed</div>
                          <div className="sub-arrow"></div>
                        </div>
                        <ul
                          className="dropdown-menu dropdown-submenu"
                          aria-labelledby="dropdownSubMenuLink2"
                        >
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() =>
                                navigate("/pc-report-ftpickcomplete")
                              }
                            >
                              FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pc-report-putaway")}
                            >
                              Putaway FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pc-report-orangelpn")}
                            >
                              Orange LPN Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pc-report-nftl1")}
                            >
                              NFT L1 completed
                            </div>
                          </li>
                        </ul>
                      </li>
                    </>
                  )}
                  {role === "outward" && (
                    <>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-shipping")}
                        >
                          Shipping Pendency
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-unload")}
                        >
                          Unload Pendency
                        </div>
                      </li>
                      <li className="position-relative">
                        <div
                          className="dropdown-item d-flex align-items-center justify-content-between"
                          id="dropdownSubMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div>Picking Pendency</div>
                          <div className="sub-arrow"></div>
                        </div>
                        <ul
                          className="dropdown-menu dropdown-submenu"
                          aria-labelledby="dropdownSubMenuLink"
                        >
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-ftpicking")}
                            >
                              FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-putaway")}
                            >
                              Putaway FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-orangelpn")}
                            >
                              Orange LPN Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pp-report-nftl1")}
                            >
                              NFT L1 Picking
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="position-relative">
                        <div
                          className="dropdown-item d-flex align-items-center justify-content-between"
                          id="dropdownSubMenuLink2"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div>Picking Completed</div>
                          <div className="sub-arrow"></div>
                        </div>
                        <ul
                          className="dropdown-menu dropdown-submenu"
                          aria-labelledby="dropdownSubMenuLink2"
                        >
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() =>
                                navigate("/pc-report-ftpickcomplete")
                              }
                            >
                              FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pc-report-putaway")}
                            >
                              Putaway FT Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pc-report-orangelpn")}
                            >
                              Orange LPN Picking
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={() => navigate("/pc-report-nftl1")}
                            >
                              NFT L1 completed
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-checkinpendency")}
                        >
                          Check In Pendency
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-checkincomplete")}
                        >
                          Check In Completed
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-item"
                          onClick={() => navigate("/report-extraction-summary")}
                        >
                          Extraction Summary
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            )}

            {(role === "inward" || role === "cm") && (
              <>
                <li
                  className={[
                    "nav-item nav-text text-nowrap",
                    checkPathName("rg") && "nav-active",
                    role === "inward" && "ms-xl-5 ms-lg-5",
                  ].join(" ")}
                  onClick={() => navigate("/rg-portal")}
                >
                  <div
                    className={[
                      "nav-link active",
                      checkPathName("rg") && "nav-active-color ps-4",
                    ].join(" ")}
                    aria-current="page"
                  >
                    RG Portal
                  </div>
                </li>

                <li
                  className={[
                    "nav-item nav-text text-nowrap",
                    checkPathName("ack") && "nav-active",
                    role === "inward" && "ms-xl-5 ms-lg-5",
                  ].join(" ")}
                  onClick={() => navigate("/ack-upload")}
                >
                  <div
                    className={[
                      "nav-link active",
                      checkPathName("ack") && "nav-active-color ps-4",
                    ].join(" ")}
                    aria-current="page"
                  >
                    Ack Copy upload
                  </div>
                </li>
              </>
            )}

            {role === "outward" && (
              <>
                <li
                  className={[
                    "nav-item dropdown ms-xl-5 ms-lg-5",
                    checkPathName("nft-bin") && "nav-active",
                  ].join(" ")}
                >
                  <div
                    className={[
                      "nav-link dropdown-toggle nav-text d-flex align-items-center",
                      checkPathName("nft-bin") &&
                      "nav-active-color nav-left-pad",
                    ].join(" ")}
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div>NFT-BIN</div>
                    <div
                      className={[
                        "arrow",
                        checkPathName("nft-bin") && "nav-arrow",
                      ].join(" ")}
                    ></div>
                  </div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => navigate("/nft-bin-mapping")}
                      >
                        NFT-BIN Mapping
                      </div>
                    </li>
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => navigate("/nft-bin-summary")}
                      >
                        NFT-BIN Summary
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  className={[
                    "nav-item nav-text text-nowrap",
                    checkPathName("extract") && "nav-active",
                    role === "outward" && "ms-xl-5 ms-lg-5",
                  ].join(" ")}
                  onClick={() => navigate("/extract")}
                >
                  <div
                    className={[
                      "nav-link active",
                      checkPathName("extract") && "nav-active-color ps-4",
                    ].join(" ")}
                    aria-current="page"
                  >
                    Extraction
                  </div>
                </li>
                <li
                  className={[
                    "nav-item dropdown ms-xl-5 ms-lg-5",
                    checkPathName("merge-to") && "nav-active",
                  ].join(" ")}
                >
                  <div
                    className={[
                      "nav-link dropdown-toggle nav-text d-flex align-items-center",
                      checkPathName("merge-to") &&
                      "nav-active-color nav-left-pad",
                    ].join(" ")}
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div>TO Merge</div>
                    <div
                      className={[
                        "arrow",
                        checkPathName("merge-to") && "nav-arrow",
                      ].join(" ")}
                    ></div>
                  </div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => navigate("/merge-to")}
                      >
                        Merge TO
                      </div>
                    </li>
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => navigate("/merge-to-summary")}
                      >
                        Merge TO Summary
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  className={[
                    "nav-item nav-text text-nowrap",
                    checkPathName("lpn-audit") && "nav-active",
                    role === "outward" && "ms-xl-5 ms-lg-5",
                  ].join(" ")}
                  onClick={() => navigate("/lpn-audit")}
                >
                  <div
                    className={[
                      "nav-link active",
                      checkPathName("lpn-audit") && "nav-active-color ps-4",
                    ].join(" ")}
                    aria-current="page"
                  >
                    LPN Audit
                  </div>
                </li>
              </>
            )}

            {/* <li
              className={[
                "nav-item ms-xl-5 nav-text",
                checkPathName("print") && "nav-active",
              ].join(" ")}
              onClick={() => navigate("/print-lpn")}
            >
              <div
                className={[
                  "nav-link active",
                  checkPathName("print") && "nav-active-color ps-4",
                ].join(" ")}
                aria-current="page"
              >
                Print LPN
              </div>
            </li> */}

            {/* <li className="nav-item ms-xl-5 nav-text nav-disable">
              <div className="nav-link active nav-disable" aria-current="page">
                StockAudit
              </div>
            </li>
            <li className="nav-item ms-xl-5 nav-text nav-disable">
              <div className="nav-link active nav-disable" aria-current="page">
                RTV
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      {role === "inward" && (
        <div
          className={
            widthIsSmall ? "maintain-container-sm" : "maintain-container"
          }
        >
          <div
            className={["maintain-text", !widthIsSmall && "anime"].join(" ")}
          >
            Receiving Screens for PO and TO will be unavailable everyday from
            3.00 AM to 3.30 AM
          </div>
        </div>
      )}
    </nav>
  );
};
const mapStateToProps = (state) => {
  return {
    role: state?.auth?.user?.role,
    loc: state?.auth?.user?.loc,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthState: () => dispatch(authActions.checkauth()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
