import CloseIcon from "../../assets/icons/close.svg";
import "./error_modal.css";

const ErrorModal = ({
  id,
  openRef,
  closeRef,
  msg,
  headerColor = "#ffffff",
  modalSize,
  contents,
  pb = true,
  backDrop = false,
  closeModal,
}) => {
  return (
    <>
      <div data-bs-toggle="modal" data-bs-target={`#${id}`} ref={openRef}></div>
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop={backDrop ? "static" : "not"}
        data-bs-keyboard={backDrop ? "false" : "true"}
      >
        <div
          className={[
            "modal-dialog modal-dialog-centered",
            modalSize === "xl"
              ? "modal-xl"
              : modalSize === "lg"
              ? "modal-lg"
              : modalSize === "sm"
              ? "modal-sm"
              : "",
          ].join(" ")}
        >
          <div className="modal-content">
            <div
              className="modal-header d-flex"
              style={{ backgroundColor: headerColor }}
            >
              <img
                className="ms-auto modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                src={CloseIcon}
                onClick={closeModal}
              />
            </div>
            <div
              className="modal-body d-flex flex-column align-items-center justify-content-center"
              style={{ padding: pb ? "8%" : "10% 10% 0% 10%" }}
            >
              <div className="error-msg-text">{msg}</div>
              {contents && <div className="mt-4 w-100">{contents}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ErrorModal;
