import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./download_excel.css";

export const DownloadExcel = ({
  data,
  fileName,
  buttonName,
  margin = "0px",
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="btn btn-download-lpn"
      onClick={() => exportToCSV(data, fileName)}
      disabled={!data?.length}
      style={{ margin: margin }}
    >
      {buttonName}
    </button>
  );
};

export default DownloadExcel;
