import { createSlice } from "@reduxjs/toolkit";
// import { dataFormatting } from "../../utils/formatGrnWeb";

const INITIAL_STATE = {
  grnList: [],
  grnData: [],
  allGdnData: [],
  gdnData: [],
  po: "",
  invoiceNo: "",
  supplier: "",
  invoiceAmount: "",
  invoiceDate: "",
  rgCheck: {},
  receiptId: "",
  skuCount: 0,
  supplierId: "",
  gdnDate: "",
  grndashboard: [],
  gdnCheck: "",
  softallocCheck: "",
  gdnNo: "",
  lpnData: [],
  // textractComplete: false,
  previewFileName: "",
  poRefresh: true,
  mrpCheck: "",
  enableGdnSubmit: false,
  rgCheckDone: false,
  isManualGDN: false,
  lambdaErr: false,
};

const grnSlice = createSlice({
  name: "grn",
  initialState: INITIAL_STATE,
  reducers: {
    addGrnList(state, action) {
      state.grnList = action.payload;
    },
    addGrnData(state, action) {
      let allItems = action.payload.grnData
        .map((item) => {
          return {
            ...item,
            total_rejected_qty: item.rejected_qty?.reduce((a, b) => a + b, 0),
            po_item: item?.lpn ? 1 : 0,
            itemIsAdded: false,
          };
        })
        .sort((a, b) => b.po_item - a.po_item);

      state.grnData = allItems;

      let gdnItems = allItems
        .map((item) => {
          if (
            item?.received !== item?.expected ||
            (item?.received === 0 && item?.expected === 0) ||
            item?.expected < item?.total_rejected_qty + item?.received ||
            (item?.received !== item?.expected &&
              item?.total_rejected_qty > 0 &&
              item?.lpn)
          ) {
            return {
              ...item,
              itemIsAdded:
                !item?.gkm_flag && item?.item_inv_amt > 0 ? true : false,
              itemAlreadyInPO: false,
            };
          }
          return null;
        })
        .filter((item) => item);

      gdnItems?.map((gdn) => {
        const itemAlreadyInPoExist = allItems?.find(
          (item) => item?.item === gdn?.item && item?.lpn
        );
        if (itemAlreadyInPoExist) {
          gdn.itemAlreadyInPO = true;
        }
        return gdn;
      });

      state.gdnData = gdnItems;

      state.allGdnData = allItems;
      state.supplier = action.payload.supplier;
      state.invoiceAmount = action.payload.invoiceAmount;
      state.invoiceDate = action.payload.invoiceDate;
      state.receiptId = action.payload.receiptId;
      state.skuCount = action.payload.skuCount;
      state.supplierId = action.payload.supplierId;
      state.gdnDate = action.payload.gdnDate;
    },
    addManualGDNData(state, action) {
      let allItems = action.payload.grnData
        .map((item) => {
          return {
            ...item,
            total_rejected_qty: item.rejected_qty?.reduce((a, b) => a + b, 0),
            po_item: item?.lpn ? 1 : 0,
            itemIsAdded: false,
          };
        })
        .sort((a, b) => b.po_item - a.po_item);

      state.grnData = allItems;
      let gdnItems = allItems
        .map((item) => {
          return {
            ...item,
            itemIsAdded:
              item?.expected < item?.total_rejected_qty + item?.received
                ? true
                : false,
          };
        })
        .filter((item) => item);
      state.gdnData = gdnItems;
      state.allGdnData = allItems;
      state.supplier = action.payload.supplier;
      state.invoiceAmount = action.payload.invoiceAmount;
      state.invoiceDate = action.payload.invoiceDate;
      state.receiptId = action.payload.receiptId;
      state.skuCount = action.payload.skuCount;
      state.supplierId = action.payload.supplierId;
      state.gdnDate = action.payload.gdnDate;
    },
    updateisManualGDN(state, action) {
      state.isManualGDN = action.payload;
    },
    updateGdnData(state, action) {
      state.gdnData = action.payload;
      let gdns = [...state.allGdnData];
      let updatedGdns = gdns.map((gdn) => {
        let updatedGdn = action.payload?.find(
          (newGdn) => gdn?.item === newGdn?.item
        );
        if (updatedGdn) {
          return updatedGdn;
        } else {
          return gdn;
        }
      });
      state.allGdnData = updatedGdns;
    },
    updatePO(state, action) {
      state.po = action.payload;
    },
    updateInvoiceNo(state, action) {
      state.invoiceNo = action.payload;
    },
    addRgCheck(state, action) {
      state.rgCheck = action.payload;
    },
    addGrndashboard(state, action) {
      state.grndashboard = action.payload;
    },
    addItemsToGdn(state, action) {
      const gdnItems = [...state.gdnData];
      const newGdnItems = gdnItems.concat(action.payload);
      state.gdnData = newGdnItems;
      state.allGdnData = newGdnItems;
    },
    updateRgChecks(state, action) {
      state.gdnCheck = action.payload.gdnCheck;
      state.softallocCheck = action.payload.softallocCheck;
    },
    updateGdnNo(state, action) {
      state.gdnNo = action.payload;
    },
    addLpnData(state, action) {
      state.lpnData = action.payload;
    },
    markTextractComplete(state, action) {
      // state.textractComplete = action.payload;
    },
    markGdnSaveComplete(state, action) {
      state.enableGdnSubmit = action.payload;
    },
    markPoRefresh(state, action) {
      state.poRefresh = action.payload;
    },
    markMrpCheck(state, action) {
      state.mrpCheck = action.payload;
    },
    markRgCheckDone(state, action) {
      state.rgCheckDone = action.payload;
    },
    addPreviewFileName(state, action) {
      state.previewFileName = action.payload;
    },
    filterGdnData(state, action) {
      const gdns = [...state.allGdnData];
      const filteredGdns = gdns.filter(
        (gdn) =>
          gdn?.item.toLowerCase().includes(action.payload.toLowerCase()) ||
          gdn?.item_desc.toLowerCase().includes(action.payload.toLowerCase())
      );
      if (filteredGdns.length) {
        state.gdnData = filteredGdns;
      } else {
        state.gdnData = state.allGdnData;
      }
    },
    clearGrnData(state, action) {
      state.grnList = [];
      state.grnData = [];
      state.gdnData = [];
      state.allGdnData = [];
      state.po = "";
      state.invoiceNo = "";
      state.supplier = "";
      state.invoiceAmount = "";
      state.invoiceDate = "";
      state.rgCheck = {};
      state.receiptId = "";
      state.skuCount = 0;
      state.supplierId = "";
      state.gdnDate = "";
      state.gdnCheck = "";
      state.softallocCheck = "";
      state.gdnNo = "";
      // state.textractComplete = false;
      state.previewFileName = "";
      state.poRefresh = true;
      state.mrpCheck = "";
      state.enableGdnSubmit = false;
      state.isManualGDN = false;
    },
    clearGrnTables(state, action) {
      state.grnData = [];
    },
    updateLambdaErr(state, action) {
      state.lambdaErr = action.payload;
    },
  },
});

export const grnActions = grnSlice.actions;

export default grnSlice.reducer;
