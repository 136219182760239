import { useEffect, useRef, useState } from "react";
import { grnDashboardChange, grnWebChange } from "../../api";
import { connect } from "react-redux";
import { grnActions } from "../../store/grn";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../../containers/content_header";
import GrnContentView from "../../containers/grn_content_view";
import moment from "moment";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import ErrorModal from "../../containers/error_modal";
import "./grn_dashboard.css";

const GrnDashboard = ({ ...props }) => {
  const {
    grndashboard,
    addGrndashboard,
    loc,
    locationName,
    widthIsSmall,
    addManualGDNData,
    updatePO,
    updateInvoiceNo,
    markRgCheckDone,
    clearGrnData,
  } = props;
  const [lambdaError, setLambdaError] = useState("");
  const [isDashboardLoading, setIsDashboardLoading] = useState("");
  const [isGdnLoading, setIsGdnLoading] = useState("");
  const [manualSelected, setManualSelected] = useState("");
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    clearGrnData();
    addGrndashboard([]);
    onGrnDashboardSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGrnDashboardSubmit = async () => {
    try {
      setIsDashboardLoading(true);
      let payload = {
        date: moment(new Date()).format("YYYY-MM-DD"),
        loc: loc,
      };

      const grnDashData = await grnDashboardChange(payload);

      if (grnDashData) {
        setIsDashboardLoading(false);
        if (grnDashData?.status === "error") {
          setLambdaError(`DashError :  ${grnDashData?.error_message}`);
          openErrRef.current.click();
        } else {
          if (Object.keys(grnDashData?.body)?.length) {
            addGrndashboard(grnDashData?.body);
          }
        }
      } else {
        setIsDashboardLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onManualGDN = async (po, invoiceNo) => {
    try {
      setManualSelected({ po, invoiceNo });
      setIsGdnLoading(true);

      let payload = {
        order_no: parseInt(po),
        invoice_no: invoiceNo,
        loc: loc,
        gdn_type: "Manual",
      };

      const grnSubmitted = await grnWebChange(payload);
      if (grnSubmitted) {
        if (grnSubmitted?.status === "error") {
          setLambdaError(grnSubmitted?.error_message);
          openErrRef.current.click();
          setIsGdnLoading(false);
        } else {
          if (Object.keys(grnSubmitted)) {
            setIsGdnLoading(false);
            updatePO(po);
            updateInvoiceNo(invoiceNo);
            markRgCheckDone(true);
            addManualGDNData({
              grnData: grnSubmitted?.body || [],
              supplier: grnSubmitted?.supplier || "",
              invoiceAmount: grnSubmitted?.invoice_amount || "",
              invoiceDate: grnSubmitted?.invoice_date || "",
              receiptId: grnSubmitted?.receipt_id || "",
              skuCount: grnSubmitted?.item_count || "",
              supplierId: grnSubmitted?.supplier_id || "",
              gdnDate: grnSubmitted?.gdn_date || "",
            });
            navigate("../manual-gdn");
          }
        }
      } else {
        setIsGdnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsGdnLoading(false);
    }
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="home d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          headerText={"GRN/GDN Dashboard"}
        />
        <GrnContentView
          grndashboard={grndashboard}
          isDashboardLoading={isDashboardLoading}
          onManualGDN={onManualGDN}
          isGdnLoading={isGdnLoading}
          manualSelected={manualSelected}
        />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    grndashboard: state?.grn?.grndashboard,
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGrndashboard: (data) => dispatch(grnActions.addGrndashboard(data)),
    addManualGDNData: (data) => dispatch(grnActions.addManualGDNData(data)),
    updatePO: (data) => dispatch(grnActions.updatePO(data)),
    updateInvoiceNo: (data) => dispatch(grnActions.updateInvoiceNo(data)),
    markRgCheckDone: (data) => dispatch(grnActions.markRgCheckDone(data)),
    updateGdnData: (data) => dispatch(grnActions.updateGdnData(data)),
    clearGrnData: () => dispatch(grnActions.clearGrnData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GrnDashboard);
