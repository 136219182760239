import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { grnActions } from "../../store/grn";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "../content_header";
import CommonModal from "../../containers/common_modal";
import ErrorModal from "../../containers/error_modal";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import "./add_items.css";

const AddItems = ({ ...props }) => {
  const [items, seItems] = useState([
    {
      item_id: "",
      invoice_amount: 0,
    },
  ]);
  const navigate = useNavigate();
  const modalOpenRef = useRef();
  const modalCloseRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const [lambdaError, setLambdaError] = useState("");
  const [isError, setIsError] = useState("");

  const {
    gdnData,
    grnData,
    invoiceAmount,
    po,
    invoiceNo,
    addItemsToGdn,
    locationName,
    loc,
    supplier,
    receiptId,
    widthIsSmall,
    isManualGDN,
  } = props;

  useEffect(() => {
    if (!po || !invoiceNo) {
      navigate("/grnlist");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [po, invoiceNo]);

  const addItems = () => {
    let validateEnterItems = validateItems();
    if (!validateEnterItems) {
      return;
    } else {
      let newItems = [
        ...items,
        ...[
          {
            item_id: "",
            invoice_amount: 0,
          },
        ],
      ];
      seItems(newItems);
    }
  };

  const removeItems = (i) => {
    let newItems = [...items];
    newItems.splice(i, 1);
    seItems(newItems);
  };

  const onSubmitItems = () => {
    setIsError(false);

    let validateListItems = validateItems();
    if (!validateListItems) return;

    const itemValidates = items.find((item) => {
      let itemExistInPO = grnData?.find((grn) => grn?.item === item?.item_id);
      return (
        item?.item_id?.length !== 9 ||
        (!itemExistInPO && item?.invoice_amount <= 0) ||
        isNaN(item?.item_id) ||
        item?.invoice_amount === "" ||
        (!isNaN(item?.item_id) && Number(item?.item_id) < 1)
      );
    });
    if (itemValidates) {
      setIsError(true);
      setLambdaError(
        `Item id must be equal to 9 digits and invoice should be greater than or equal to 0`
      );
      openErrRef.current.click();
      return;
    }

    const itemArray = items
      .map((item) => {
        const itemExistInPO = grnData?.find(
          (grn) => grn?.item === item?.item_id
        );

        if (itemExistInPO) {
          return {
            ...itemExistInPO,
            rej_rsn_code: [4],
            rej_rsn_desc: ["Item in Invoice but not in PO"],
            rejected_qty: [0],
            total_rejected_qty:
              item.rejected_qty?.reduce((a, b) => a + b, 0) || 0,
            item_inv_amt: item.invoice_amount || 0,
            itemIsAdded: true,
            gkm_flag: false,
            itemAlreadyInPO: true,
          };
        } else {
          if (item?.item_id && item?.invoice_amount) {
            return {
              amended_mrp: 0,
              expected: 0,
              invoice_amount: 0,
              invoice_date: "",
              invoice_no: "",
              item: item?.item_id,
              item_desc: "",
              item_type: "",
              lpn: 0,
              mfg_date: "",
              mrp: 0,
              order_no: po,
              received: 0,
              sps: 0,
              supplier: supplier,
              unit_cost: 0,
              uom: "",
              rej_rsn_code: [4],
              rej_rsn_desc: ["Item in Invoice but not in PO"],
              rejected_qty: [0],
              total_rejected_qty:
                item.rejected_qty?.reduce((a, b) => a + b, 0) || 0,
              item_inv_amt: item.invoice_amount || 0,
              invoice_qty: 0,
              itemIsAdded: true,
              gkm_flag: false,
              itemAlreadyInPO: false,
            };
          }
        }
        return null;
      })
      .filter((item) => item);

    addItemsToGdn(itemArray);
    modalOpenRef.current.click();
  };

  const validateItems = () => {
    let itemExistInPo = items.filter((item) => {
      let itemPresent = gdnData.find(
        (gdn) => gdn?.item_desc && gdn?.item === item?.item_id
      );
      if (itemPresent) return item;
    });
    if (itemExistInPo?.length) {
      setLambdaError(
        `Item with item id ${itemExistInPo[0].item_id} already in Order`
      );
      openErrRef.current.click();
      return false;
    }

    let itemExistInList = items.filter((item) => {
      let itemPresent = gdnData.find(
        (gdn) => !gdn?.item_desc && gdn?.item === item?.item_id
      );
      if (itemPresent) return item;
    });
    if (itemExistInList?.length) {
      setLambdaError(
        `Item with item id ${itemExistInList[0].item_id} already exist`
      );
      openErrRef.current.click();
      return false;
    }

    let itemExistInGrnList = items.filter((item) => {
      let itemPresent = grnData.find(
        (grn) => grn?.item === item?.item_id && item?.invoice_amount > 0
      );
      if (itemPresent) return item;
    });
    if (itemExistInGrnList?.length) {
      setLambdaError(
        `Item with item id ${itemExistInGrnList[0].item_id} that exist in PO shouldn't have invoice amount greater than zero`
      );
      openErrRef.current.click();
      return false;
    }

    return true;
  };

  const SubmitItems = () => (
    <div className="d-flex flex-column mt-4 mx-3 mb-2">
      <div className="add-item-head">Request successful</div>
      <div className="d-flex flex-column">
        <div className="submit-add-text">
          Items added in Order successfully.
        </div>
        <div className="submit-add-text">Proceed to GDN submit</div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-preview mt-5"
          onClick={() => {
            modalCloseRef.current.click();
            if (isManualGDN) {
              navigate("../manual-gdn");
            } else {
              navigate("../gdn");
            }
          }}
        >
          Proceed to GDN
        </button>
        <button
          className="btn btn-guide mt-5 ms-3"
          onClick={() => modalCloseRef.current.click()}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const updateItemData = (index, type, value) => {
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        if (type === "id") item.item_id = value;
        if (type === "amount") item.invoice_amount = value;
      }
      return item;
    });

    seItems(updatedItems);
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="d-flex flex-column add-hm"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header text={`${loc} - ${locationName}`} headerText={"Add Items"} />
        {widthIsSmall ? (
          <div className="submit-form d-flex flex-column">
            <div className="d-flex align-items-start">
              <label className="sf-response-label w-50">Supplier</label>
              <div className="sf-response-data w-50">
                :&nbsp;&nbsp;{supplier || "NA"}
              </div>
            </div>
            <div className="d-flex align-items-start">
              <label className="sf-response-label w-50">Order No</label>
              <div className="sf-response-data w-50">
                :&nbsp;&nbsp;{po || "NA"}
              </div>
            </div>
            <div className="d-flex align-items-start">
              <label className="sf-response-label w-50">Invoice/DC No</label>
              <div className="sf-response-data w-50">
                :&nbsp;&nbsp;{invoiceNo || "NA"}
              </div>
            </div>
            <div className="d-flex align-items-start">
              <label className="sf-response-label w-50">Invoice Amount</label>
              <div className="sf-response-data w-50">
                :&nbsp;&nbsp;{invoiceAmount || 0}
              </div>
            </div>
            <div className="d-flex align-items-start">
              <label className="sf-response-label w-50">GRN Number</label>
              <div className="sf-response-data w-50">
                :&nbsp;&nbsp;{receiptId || "NA"}
              </div>
            </div>
          </div>
        ) : (
          <div className="submit-form">
            <div className="d-flex">
              <div className="d-flex flex-column w-25">
                <label className="add-sf-label">Supplier</label>
                <label className="add-sf-label">Order No</label>
                <label className="add-sf-label">Invoice/DC No</label>
                <label className="add-sf-label">Invoice Amount</label>
                <label className="add-sf-label">GRN Number</label>
              </div>
              <div className="d-flex flex-column">
                <div className="add-sf-label">
                  :&nbsp;&nbsp;&nbsp;{supplier}
                </div>
                <div className="add-sf-label">:&nbsp;&nbsp;&nbsp;{po}</div>
                <div className="add-sf-label">
                  :&nbsp;&nbsp;&nbsp;{invoiceNo}
                </div>
                <div className="add-sf-label">
                  :&nbsp;&nbsp;&nbsp;{invoiceAmount}
                </div>
                <div className="add-sf-label">
                  :&nbsp;&nbsp;&nbsp;{receiptId}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex flex-column item-list">
          <div
            className="d-flex mb-2 justify-content-between"
            style={{ width: widthIsSmall ? "100%" : "50%" }}
          >
            <span
              className="add-text"
              style={{
                width: widthIsSmall ? "auto" : "50%",
                fontSize: widthIsSmall ? "14px" : "18px",
              }}
            >
              Item Id
            </span>
            <span
              className={["add-text", !widthIsSmall && "ms-5"].join(" ")}
              style={{
                width: widthIsSmall ? "auto" : "50%",
                fontSize: widthIsSmall ? "14px" : "18px",
              }}
            >
              {"Item Invoice Amount(incl. tax)"}
            </span>
          </div>
          {items.map((item, i) => {
            return (
              <div key={i} className="d-flex mb-3 align-items-center">
                <div
                  className="d-flex"
                  style={{ width: widthIsSmall ? "100%" : "50%" }}
                >
                  <input
                    type="text"
                    className={[
                      "form-control",
                      isError &&
                        (item?.item_id?.length !== 9 || !item?.item_id) &&
                        "border-red",
                    ].join(" ")}
                    placeholder="Enter item"
                    value={item?.item_id}
                    onChange={(e) => updateItemData(i, "id", e.target.value)}
                  />
                  <input
                    type={"number"}
                    className={[
                      "form-control ms-5",
                      isError &&
                        (item?.invoice_amount < 0 || !item?.invoice_amount) &&
                        "border-red",
                    ].join(" ")}
                    placeholder="Item invoice amount"
                    value={item?.invoice_amount}
                    onChange={(e) =>
                      updateItemData(i, "amount", e.target.value)
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className="add-btn border-0"
                    disabled={!items[items?.length - 1]?.item_id}
                    onClick={addItems}
                  >
                    +
                  </button>
                  <button
                    className="sub-btn border-0"
                    disabled={i === 0 && items.length <= 1}
                    onClick={() => removeItems(i)}
                  >
                    -
                  </button>
                </div>
              </div>
            );
          })}
          <div className="d-flex mt-5 mb-3" style={{ width: "60%" }}>
            <button
              className="ms-auto btn btn-add-submit"
              onClick={onSubmitItems}
            >
              Save
            </button>
          </div>
        </div>

        <CommonModal
          id={"submitAddItemModal"}
          content={<SubmitItems />}
          modalOpenRef={modalOpenRef}
          modalCloseRef={modalCloseRef}
        />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    grnData: state?.grn?.grnData,
    gdnData: state?.grn?.gdnData,
    supplier: state?.grn?.supplier,
    invoiceAmount: state?.grn?.invoiceAmount,
    invoiceDate: state?.grn?.invoiceDate,
    po: state?.grn?.po,
    invoiceNo: state?.grn?.invoiceNo,
    locationName: state?.auth?.user?.locationName,
    loc: state?.auth?.user?.loc,
    receiptId: state?.grn?.receiptId,
    widthIsSmall: state?.auth?.widthIsSmall,
    isManualGDN: state?.grn?.isManualGDN,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addItemsToGdn: (data) => dispatch(grnActions.addItemsToGdn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItems);
