export const reasonCodes = [
  { code: 1, desc: "Cost GKM issue" },
  { code: 2, desc: "Excess Qty against Invoice" },
  { code: 3, desc: "Item in PO but not in Invoice" },
  { code: 4, desc: "Item in Invoice but not in PO" },
  { code: 5, desc: "HSN Mismatch" },
  { code: 6, desc: "EAN not matching" },
  // { code: 8, desc: "Excess Markout" },
  { code: 9, desc: "Near Expiry Stock" },
  { code: 10, desc: "Remove Item/rsn" },
  { code: 11, desc: "Damaged Stock" },
  { code: 12, desc: "Quality Issue" },
  { code: 88, desc: "Short  Supply" },
];

export const inboundPriority = [1, 3, 5, 7, 9, 11, 16];
export const outboundPriority = [2, 5, 8, 11, 12, 13, 14, 15];
export const inventoryPriority = [4];
export const adminPriority = [6];
export const cmPriority = [9];
export const internalPriority = [10];

export const viewPriorityList = [
  { key: 1, value: "grn" },
  { key: 2, value: "lpn" },
  { key: 3, value: "dashboard" },
  { key: 4, value: "mkt" },
  { key: 5, value: "store" },
  { key: 6, value: "admin" },
  { key: 7, value: "reports-inbound" },
  { key: 8, value: "reports-outbound" },
  { key: 9, value: "rg" },
  { key: 10, value: "wms_internal" },
  { key: 11, value: "reports-multi" },
  { key: 12, value: "extract" },
  { key: 13, value: "nft-bin" },
  { key: 14, value: "merge-to" },
  { key: 15, value: "lpn-audit" },
  { key: 16, value: "ack" },
];

export const userRoles = [
  { role: "inward" },
  { role: "outward" },
  { role: "inventory" },
  { role: "admin" },
  { role: "cm" },
  { role: "wms_internal" },
];

export const lpnTypes = ["yellow", "white", "orange", "grey", "Store Barcode"];
export const lpnPayload = [
  { type: "yellow", code: "1", value: "receiving" },
  { type: "white", code: "2", value: "bulk_picking" },
  { type: "orange", code: "3", value: "nft_picking" },
  { type: "grey", code: "4", value: "loose_picking" },
  { type: "Store Barcode", code: "", value: "receiving" },
];
export const processFlowTypes = ["GRN", "Picking", "Unload", "Dispatch"];

// printlpn
export const license_owner = process.env.REACT_APP_URL_LICENSE_OWNER;
export const license_key = process.env.REACT_APP_URL_LICENSE_KEY;

// lambda urls
export const LOGIN_URL = process.env.REACT_APP_URL_LOGIN;
export const GRN_LIST_URL = process.env.REACT_APP_URL_GRN_LIST;
export const GRN_WEB_URL = process.env.REACT_APP_URL_GRN_WEB;
export const RG_CHECK_URL = process.env.REACT_APP_URL_RG_CHECK;
export const GRN_UAT_URL = process.env.REACT_APP_URL_GRN_UAT;
export const GRN_DASHBOARD_URL = process.env.REACT_APP_URL_GRN_DASHBOARD;
export const GDN_UAT_URL = process.env.REACT_APP_URL_GDN_UAT;
export const PO_REFRESH_URL = process.env.REACT_APP_URL_PO_REFRESH;
export const SOFT_ALLOCATE_URL = process.env.REACT_APP_URL_SOFT_ALLOCATE;
export const TO_GRN_URL = process.env.REACT_APP_URL_TO_GRN;
export const RG_TOT_URL = process.env.REACT_APP_URL_RG_TOT;
export const GDN_STAGE_INSERT_URL = process.env.REACT_APP_URL_GDN_STAGE_INSERT;
export const TEXTRACT_URL = process.env.REACT_APP_URL_TEXTRACT;
export const PRINT_LPN_URL = process.env.REACT_APP_URL_PRINT_LPN;
export const LPN_SUMMARY_URL = process.env.REACT_APP_URL_LPN_SUMMARY;
export const DOC_VERIFICATION_URL = process.env.REACT_APP_URL_DOC_VERIFICATION;
export const MKT_SUMMARY_URL = process.env.REACT_APP_URL_MKT_SUMMARY;
export const MKT_DOWNLOAD_TEMPLATE_URL =
  process.env.REACT_APP_URL_MKT_DOWNLOAD_TEMPLATE;
export const UPLOAD_RTV_URL = process.env.REACT_APP_URL_UPLOAD_RTV;
export const UPLOAD_WRITEOFF_URL = process.env.REACT_APP_URL_UPLOAD_WRITEOFF;
export const UPLOAD_GOOD_BUCKET = process.env.REACT_APP_URL_UPLOAD_GOOD_BUCKET;
export const STORE_MAPPING_SUMMARY_URL =
  process.env.REACT_APP_URL_STORE_MAPPING_SUMMARY;
export const STORE_MAPPING_UPDATE_URL =
  process.env.REACT_APP_URL_STORE_MAPPING_UPDATE;
export const BAY_LIST_URL = process.env.REACT_APP_URL_BAY_LIST;
export const ADMIN_UAT_URL = process.env.REACT_APP_URL_ADMIN_UAT;
export const PENDING_UPLOAD_INVOICE_URL =
  process.env.REACT_APP_URL_PENDING_UPLOAD_INVOICE;
export const GRN_SUBMITTED_REPORT_URL =
  process.env.REACT_APP_URL_GRN_SUBMITTED_REPORT;
export const GDN_SUBMITTED_REPORT_URL =
  process.env.REACT_APP_URL_GDN_SUBMITTED_REPORT;
export const OPEN_ORDERS_REPORT_URL =
  process.env.REACT_APP_URL_OPEN_ORDERS_REPORT;
export const SHIPPING_PENDENCY_URL =
  process.env.REACT_APP_URL_SHIPPING_PENDENCY_REPORT;
export const ACK_COPY_UPLOAD_URL = process.env.REACT_APP_URL_ACK_COPY_UPLOAD
export const COMMON_REPORT_URL = process.env.REACT_APP_URL_COMMON_REPORT_URL;
export const COMMON_EXTRACT_URL = process.env.REACT_APP_URL_COMMON_EXTRACT_URL;
export const BIN_DETAILS_URL = process.env.REACT_APP_URL_BIN_DETAILS_URL;
export const GENERATE_QR_CODES_URL =
  process.env.REACT_APP_URL_GENERATE_QR_CODES_URL;
export const COMMON_CHECK_IN_URL =
  process.env.REACT_APP_URL_COMMON_CHECK_IN_URL;
export const LPN_AUDIT_URL = process.env.REACT_APP_URL_LPN_AUDIT_URL;
export const APK_UPLOAD = process.env.REACT_APP_URL_APK_UPLOAD;
export const FORGOT_PASSWORD = process.env.REACT_APP_FORGOT_PASSWORD;
