import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { grnListChange, sendMailChange } from "../../api";
import Header from "../../containers/content_header";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import ErrorModal from "../../containers/error_modal";
import PoDeatils from "../../containers/submit_form";
import "./rg.css";

const RgPortal = ({ ...props }) => {
  const { loc, locationName, widthIsSmall, userId } = props;

  const [grnList, setgrnList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [poSelected, setPoSelected] = useState("");
  const [itemFetched, setItemFetched] = useState(false);
  const [itemList, setitemList] = useState([]);
  const [isProceedLoading, setIsProceedLoading] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const openErrRef = useRef();
  const closeErrRef = useRef();

  useEffect(() => {
    getGrnList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGrnList = async () => {
    try {
      setIsListLoading(true);
      let payload = {
        loc: loc,
      };
      const grnListData = await grnListChange(payload);

      if (grnListData) {
        if (grnListData?.status === "error") {
          setLambdaError(grnListData?.error_message);
          openErrRef.current.click();
          setIsListLoading(false);
        } else {
          if (Object.keys(grnListData?.body)) {
            setgrnList(grnListData?.body);
            setIsListLoading(false);
          }
        }
      } else {
        setIsListLoading(false);
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
      }
    } catch (error) {
      console.log(error);
      setIsListLoading(false);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const onUserSelectPo = (checked, values) => {
    if (checked) {
      setPoSelected(values);
    } else {
      setPoSelected("");
    }
  };

  const onUserSelectGrn = async () => {
    try {
      setIsProceedLoading(true);

      let payload = {
        order_no: poSelected?.order_no,
        invoice_no: poSelected?.invoice_no,
        user_id: userId,
        rg_portal: true,
      };

      const poSelectedDetils = await sendMailChange(payload);
      if (poSelectedDetils) {
        if (poSelectedDetils?.status === "error") {
          setLambdaError(poSelectedDetils?.error_message);
          openErrRef.current.click();
          setIsProceedLoading(false);
        } else {
          if (Object.keys(poSelectedDetils?.body)) {
            setItemFetched(true);
            setitemList(poSelectedDetils?.body);
            setIsProceedLoading(false);
          }
        }
      } else {
        setIsProceedLoading(false);
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="rg-hm d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header text={`${loc} - ${locationName}`} headerText={"RG Portal"} />
        <div className="">
          {itemFetched ? (
            <div>
              <PoDeatils
                supplier={"NA"}
                invoiceAmount={"NA"}
                invoiceDate={"NA"}
                po={poSelected?.order_no}
                invoiceNo={poSelected?.invoice_no}
                widthIsSmall={widthIsSmall}
                backButton={true}
                onBackClick={() => {
                  setItemFetched(false);
                  setPoSelected("");
                  setitemList([]);
                  setgrnList([]);
                  getGrnList();
                }}
              />
              <div
                className="rg-table"
                style={{ padding: widthIsSmall ? "25px" : "40px" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>receipt id</th>
                      <th>loc</th>
                      <th>order no</th>
                      <th>received date</th>
                      <th>invoice no</th>
                      <th>invoice date</th>
                      <th>item</th>
                      <th>rcv qty</th>
                      <th>mrp</th>
                      <th>unit cost</th>
                      <th>unit tax amount</th>
                      <th>tax value</th>
                      <th>invoice amount</th>
                      <th>final value</th>
                      <th>email</th>
                      <th>invoice img link</th>
                      <th>value</th>
                      <th>mfg date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList?.map((item, i) => (
                      <tr key={i}>
                        <td>{item?.receipt_id || "NA"}</td>
                        <td>{item?.loc || "NA"}</td>
                        <td>{item?.order_no || "NA"}</td>
                        <td>{item?.received_date || "NA"}</td>
                        <td>{item?.invoice_no || "NA"}</td>
                        <td>{item?.invoice_date || "NA"}</td>
                        <td>{item?.item || "NA"}</td>
                        <td>{item?.rcv_qty || "NA"}</td>
                        <td>{item?.mrp || "NA"}</td>
                        <td>{item?.unit_cost || "NA"}</td>
                        <td>{item?.unit_tax_amount || "NA"}</td>
                        <td>{item?.tax_value || "NA"}</td>
                        <td>{item?.invoice_amount || "NA"}</td>
                        <td>{item?.final_value || "NA"}</td>
                        <td>{item?.email || "NA"}</td>
                        <td>{item?.invoice_img_link || "NA"}</td>
                        <td>{item?.value || "NA"}</td>
                        <td>{item?.mfg_date || "NA"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div
              className="rg-table d-flex flex-column"
              style={{ padding: widthIsSmall ? "25px" : "40px" }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>Order No.</th>
                    <th>Invoice/DC No.</th>
                    <th>Select PO</th>
                  </tr>
                </thead>
                <tbody>
                  {grnList?.map((grn, i) => (
                    <tr key={i}>
                      <td>{grn?.order_no || "-"}</td>
                      <td>{grn?.invoice_no || "-"}</td>
                      <td>
                        <input
                          type={"checkbox"}
                          onChange={(e) => {
                            onUserSelectPo(e.target.checked, {
                              order_no: grn?.order_no,
                              invoice_no: grn?.invoice_no,
                            });
                          }}
                          checked={
                            poSelected?.order_no === grn?.order_no &&
                            poSelected?.invoice_no === grn?.invoice_no
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isListLoading && (
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "5%" }}
                >
                  <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
              <div className="d-flex  justify-content-center">
                <button
                  className="btn btn-preview mt-3 d-flex align-items-center"
                  onClick={onUserSelectGrn}
                  disabled={isProceedLoading || !poSelected}
                >
                  <div>Proceed</div>
                  {isProceedLoading && <div className="spinner ms-2"></div>}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <ErrorModal
        id={"errorModal"}
        openRef={openErrRef}
        closeRef={closeErrRef}
        headerColor={"red"}
        msg={lambdaError}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    lpnData: state?.grn?.lpnData,
    widthIsSmall: state?.auth?.widthIsSmall,
    userId: state?.auth?.user?.userId,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RgPortal);
