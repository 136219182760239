import * as XLSX from "xlsx";
import "./extract_excel.css";

const ExtractExcel = ({
  buttonName,
  width = "100%",
  onSubmit,
  isLoading,
  inputRef,
  extractType,
}) => {
  const readUploadFile = (e) => {
    e.preventDefault();
    let type = e.target.files[0].type;
    if (
      type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      onSubmit({}, type, extractType);
    }
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        onSubmit(json, type, extractType);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  return (
    <div className="position-relative d-flex">
      <button
        className="btn btn-extract d-flex align-items-center justify-content-center"
        // style={{ width: isLoading ? `${width + 10}px` : `${width}px` }}
        style={{ width: width }}
        disabled={isLoading}
      >
        <div>{buttonName}</div>
        {isLoading && <div className="spinner ms-2"></div>}
      </button>
      <input
        type="file"
        className="upload-mkt"
        style={{ width: width }}
        onChange={readUploadFile}
        disabled={isLoading}
        ref={inputRef}
      />
    </div>
  );
};
export default ExtractExcel;
