import { useEffect } from "react";
import { useForm } from "react-hook-form";

const NftBinMap = ({ onSubmitQrData, isLoading, resetFields }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (resetFields) {
      clearfields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFields]);

  const clearfields = () => {
    reset({
      assetId: "",
      shelves: "",
      qr_qty: "",
    });
  };

  return (
    <>
      <form
        className="d-flex flex-column up-box"
        onSubmit={handleSubmit(onSubmitQrData)}
      >
        <div className="d-flex flex-column mb-4 mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">Asset Id</div>
            <input
              type={"text"}
              className={[
                "form-control w-50",
                (errors.assetId?.type === "required" ||
                  errors?.assetId?.type === "minLength") &&
                  "border-red",
              ].join(" ")}
              {...register("assetId", {
                required: true,
                minLength: 1,
              })}
              placeholder="Enter Asset Id"
            />
          </div>
          {errors?.assetId?.type === "required" && (
            <div className="error-text ms-auto">Asset Id is required</div>
          )}
          {errors?.assetId?.type === "minLength" && (
            <div className="error-text ms-auto">
              Asset Id require a minimum of 1 character
            </div>
          )}
        </div>

        <div className="d-flex flex-column mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">No of Shelves</div>
            <input
              className={[
                "form-control w-50",
                (errors.shelves?.type === "required" ||
                  errors?.shelves?.type === "minLength" ||
                  errors?.shelves?.type === "min") &&
                  "border-red",
              ].join(" ")}
              type="number"
              {...register("shelves", {
                required: true,
                minLength: 1,
                min: 1,
              })}
              placeholder="Enter no of shelves"
            />
          </div>
          {errors?.shelves?.type === "required" && (
            <div className="error-text ms-auto">No of shelves is required</div>
          )}
          {errors?.shelves?.type === "minLength" && (
            <div className="error-text ms-auto">
              No of shelves require a minimum of 1 digit
            </div>
          )}
          {errors?.shelves?.type === "min" && (
            <div className="error-text ms-auto">
              No of shelves require a minimum value of 1
            </div>
          )}
        </div>

        <div className="d-flex flex-column mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">QR Code Qty</div>
            <input
              className={[
                "form-control w-50",
                (errors.qr_qty?.type ||
                  errors?.qr_qty?.type === "minLength" ||
                  errors?.qr_qty?.type === "min") === "required" &&
                  "border-red",
              ].join(" ")}
              type="number"
              {...register("qr_qty", {
                required: true,
                minLength: 1,
                min: 1,
              })}
              placeholder="Enter QR code quantity"
            />
          </div>
          {errors?.qr_qty?.type === "required" && (
            <div className="error-text ms-auto">
              QR code quantity is required
            </div>
          )}
          {errors?.qr_qty?.type === "minLength" && (
            <div className="error-text ms-auto">
              QR code quantity require a minimum of 1 digit
            </div>
          )}
          {errors?.qr_qty?.type === "min" && (
            <div className="error-text ms-auto">
              QR code quantity require a minimum value of 1
            </div>
          )}
        </div>

        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary mt-4 d-flex align-items-center"
            type="submit"
            disabled={isLoading}
          >
            <div>Generate NFT-BIN QR Codes</div>
          </button>
        </div>
      </form>
    </>
  );
};
export default NftBinMap;
