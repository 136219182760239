import { useForm } from "react-hook-form";
import { useEffect } from "react";

const NftBinDuplicate = ({ onSubmitQrData, isLoading, resetFields }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (resetFields) {
      clearfields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFields]);

  const clearfields = () => {
    reset({
      qr_no: "",
    });
  };

  return (
    <>
      <form
        className="d-flex flex-column up-box"
        onSubmit={handleSubmit(onSubmitQrData)}
      >
        <div className="d-flex flex-column mb-4 mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">QR Code No.</div>
            <input
              type={"text"}
              className={[
                "form-control w-50",
                (errors.qr_no?.type === "required" ||
                  errors?.qr_no?.type === "minLength") &&
                  "border-red",
              ].join(" ")}
              {...register("qr_no", {
                required: true,
                minLength: 1,
              })}
              placeholder="Enter QR Code No"
            />
          </div>
          {errors?.qr_no?.type === "required" && (
            <div className="error-text ms-auto">QR Code No is required</div>
          )}
          {errors?.qr_no?.type === "minLength" && (
            <div className="error-text ms-auto">
              QR Code No require a minimum of 1 character
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary mt-4 d-flex align-items-center"
            type="submit"
            disabled={isLoading}
          >
            <div>Generate Duplicate</div>
            {isLoading && <div className="spinner ms-2"></div>}
          </button>
        </div>
      </form>
    </>
  );
};
export default NftBinDuplicate;
