import DownloadExcel from "../../containers/download_excel";
import ExtractExcel from "../../containers/extract_excel";
import moment from "moment";
import "./mapping_contents.css";

const StoreMappingContents = ({
  loc,
  data,
  isContentLoading,
  type,
  uploadData,
  uploadFileRef,
  widthIsSmall,
  baySelected,
  bayList,
  setBaySelected,
}) => {
  return (
    <div>
      <div className="store-table d-flex flex-column">
        <table className="table">
          <thead>
            <tr>
              <th>loc</th>
              <th>store loc</th>
              {type === "tub" && <th>bay</th>}
              <th>seq</th>
              <th>store</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => (
              <tr key={i}>
                <td>{item?.loc}</td>
                <td>{item?.store_loc}</td>
                {type === "tub" && <td>{item?.bay}</td>}
                <td>{item?.seq}</td>
                <td>{item?.store}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {isContentLoading && (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "5%" }}
          >
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {!data?.length && !isContentLoading && (
          <div className="d-flex justify-content-center no-records">
            No records found
          </div>
        )}
      </div>
      <div className="d-flex flex-column flex-md-row flex-xl-row flex-lg-row justify-content-center mb-5 mkt-btn">
        <DownloadExcel
          data={data}
          fileName={`Store_mapping_${type}_summary_ ${loc}_${moment(
            new Date()
          ).format("YYYY-MM-DD")}`}
          buttonName={`Export ${
            type === "tub" ? "Tub" : "Store Bay Area"
          } Summary`}
          margin={"0px 40px 20px"}
        />
        <div className={widthIsSmall ? "sp-mx-40" : "mkt-middle"}>
          <ExtractExcel
            buttonName={"Upload Store Mapping"}
            width={widthIsSmall ? "100%" : "200px"}
            onSubmit={(data, fileType, type) => {
              uploadData(data, fileType, type);
            }}
            extractType={type}
            inputRef={uploadFileRef}
          />
        </div>
      </div>
    </div>
  );
};
export default StoreMappingContents;
