import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "./multi.css";

const Multidropdown = ({
  options,
  optionLabel,
  placeholder,
  onChangeValues,
  error = "",
}) => {
  const [selectedValues, setSelectedValues] = useState(null);

  useEffect(() => {
    if (selectedValues?.length) {
      onChangeValues(selectedValues);
    } else {
      onChangeValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  return (
    <div className="card flex justify-content-center">
      <MultiSelect
        value={selectedValues}
        onChange={(e) => setSelectedValues(e.value)}
        options={options}
        optionLabel={optionLabel}
        display="chip"
        placeholder={placeholder}
        className={["w-100", error === "required" && "border-red"].join(" ")}
        showClear={true}
        showSelectAll={true}
        filter={true}
      />
    </div>
  );
};
export default Multidropdown;
