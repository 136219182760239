import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import UnauthorizedIcon from "../../assets/images/unauthorized.png";
import "./unauthorized.css";

const Unauthorized = () => {
  return (
    <>
      <MainHeader />
      <Navbar />
      <div className="unauthorized d-flex flex-column align-items-center">
        <img className="unauthorized-img" src={UnauthorizedIcon} alt="" />
        <span className="unauthorized-text">
          OOPS! You are unauthorised to view this content.
        </span>
      </div>
    </>
  );
};
export default Unauthorized;
