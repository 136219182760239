import { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { getPresignedUrl, uploadFile } from "../../api";
import CommonModal from "../../containers/common_modal";
import MultiDropDown from "../../containers/multi_select_dropdown";
import "./upload_apk.css";

const UploadApk = ({ locs, stores, onAppNameChange }) => {
  const [file, setFile] = useState("");
  const [isError, setIsError] = useState(false);
  const [hmList, setHmList] = useState([]);
  const [smList, setSmList] = useState([]);
  const [dcList, setDcList] = useState([]);

  const [appName, setAppName] = useState("")
  const [isUploading, setIsUploading] = useState(false);
  const successOpenRef = useRef();
  const successCloseRef = useRef();
  const fileRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      appId: "",
      version: "",
      notes: "",
      locs: [],
      hms: [],
      sms: [],
      dcs: []
    },
  });

  useEffect(() => {
    let hm = []
    let sm = []
    let dc = []

    stores?.forEach((item) => {
      if (item?.format === "HM") {
        hm.push({ loc: item.loc })
      }
    })
    stores?.forEach((item) => {
      if (item?.format === "SM") {
        sm.push({ loc: item.loc })
      }
    })
    stores?.forEach((item) => {
      if (item?.format === "DC") {
        dc.push({ loc: item.loc })
      }
    })
    setDcList(dc)
    setHmList(hm)
    setSmList(sm)

  }, [stores])

  const onSubmitAPK = async (data) => {
    try {
      setIsError(false);
      if (!file) {
        setIsError(true);
        return;
      }
      setIsUploading(true);

      let hm = data?.hms || []
      let sm = data?.sms || []
      let dc = data?.dcs || []
      let loc = data?.locs || []
      let mainList = [...hm, ...sm, ...dc, ...loc]

      let payload = {
        app_id: data?.appId,
        version: data?.version,
        release_notes: data?.notes,
        loc: mainList?.map((loc) => loc.loc).join(",") || ""
      };
      console.log("network", payload)
      const presignedUrl = await getPresignedUrl(payload);
      if (presignedUrl?.data?.url) {
        await onUploadApk(presignedUrl);
      }
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  const onUploadApk = async (APIresponse) => {
    try {
      const fileUploadData = new FormData();
      Object.keys(APIresponse.data["fields"]).forEach((key) => {
        fileUploadData.append(key, APIresponse.data["fields"][key]);
      });
      fileUploadData.append("file", file);

      const fileUploaded = await uploadFile(
        APIresponse.data["url"],
        fileUploadData
      );
      if (fileUploaded) {
        successOpenRef.current.click();
        setIsUploading(false);
        resetFields();
      }
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  const resetFields = () => {
    reset();
    setAppName("")
    fileRef.current.value = "";
  };

  const UploadSuccess = () => (
    <div className="d-flex flex-column align-items-center py-4">
      <div className="au-modtext">File Uploaded successfully</div>
      <button
        className="btn btn-success mt-4 d-flex align-items-center"
        onClick={() => {
          successCloseRef.current.click();
          resetFields()
        }}
      >
        <div> OK</div>
      </button>
    </div>
  );

  return (
    <>
      <form
        className="d-flex flex-column justify-content-center new-box"
        onSubmit={handleSubmit(onSubmitAPK)}
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mt-3">
            <div className="d-flex align-items-baseline justify-content-between">
              <div className="sf-label">
                Select App name
              </div>
              <select
                {...register("appName", { required: true })}
                onChange={(e) => {
                  setAppName(e.target.value)
                  onAppNameChange(e.target.value)
                  reset()
                }}
                value={appName}
                className={[
                  "form-select role-select form-select-md w-50",
                  errors.appName?.type === "required" && "border-red",
                ].join(" ")}
              >
                <option disabled value={""}>
                  Select App
                </option>
                <option value={"wms"}>WMS+</option>
                <option value={"simplus"}>SIM+</option>
                <option value={"rnm"}>RNM technician</option>
              </select>
            </div>
            {/* {appName ==  && (
              <div className="error-text ms-auto">Please select the App</div>
            )} */}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-4">
          <div className="sf-label">App ID</div>
          <input
            type={"text"}
            className={[
              "form-control upload-form w-50",
              errors.appId?.type === "required" && "border-red",
            ].join(" ")}
            placeholder="Enter App ID"
            {...register("appId", { required: true })}
          />
        </div>
        {errors.appId?.type === "required" && (
          <div className="error-text ms-auto">App ID is required</div>
        )}
        {appName === "wms" &&
          <>
            <div className="d-flex flex-column mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="sf-label">Loc</div>
                <div className="w-50">
                  <Controller
                    name="locs"
                    control={control}
                    rules={{ required: "Value is required." }}
                    render={({ field }) => {
                      return (
                        <MultiDropDown
                          options={locs}
                          optionLabel={"loc"}
                          placeholder="Select Locs"
                          onChangeValues={(data) => {
                            field.onChange(data);
                          }}
                          error={errors.locs?.type}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {errors.locs?.type === "required" && (
                <div className="error-text ms-auto">Loc is required</div>
              )}
            </div>
          </>
        }
        {(appName === "simplus" || appName === "rnm") &&
          <>

            <div className="d-flex flex-column mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="sf-label">Super Markets</div>
                <div className="w-50">
                  <Controller
                    name="sms"
                    control={control}
                    // rules={{ required: "Value is required." }}
                    render={({ field }) => {
                      return (
                        <MultiDropDown
                          options={smList}
                          optionLabel={"loc"}
                          placeholder="Select Super Markets"
                          onChangeValues={(data) => {
                            field.onChange(data);
                          }}
                          error={errors.sms?.type}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {/* {errors.loc?.type === "required" && (
                <div className="error-text ms-auto">Required</div>
              )} */}
            </div>
          </>
        }
        {(appName === "simplus" || appName === "rnm") &&
          <>
            <div className="d-flex flex-column mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="sf-label">Hyper Markets</div>
                <div className="w-50">
                  <Controller
                    name="hms"
                    control={control}
                    // rules={{ required: "Value is required." }}
                    render={({ field }) => {
                      return (
                        <MultiDropDown
                          options={hmList}
                          optionLabel={"loc"}
                          placeholder="Select Hyper Markets"
                          onChangeValues={(data) => {
                            field.onChange(data);
                          }}
                          error={errors.hms?.type}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {/* {errors.loc?.type === "required" && (
                <div className="error-text ms-auto">Required</div>
              )} */}
            </div>
          </>}
        {(appName === "simplus" || appName === "rnm") &&
          <>
            <div className="d-flex flex-column mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="sf-label">DC</div>
                <div className="w-50">
                  <Controller
                    name="dcs"
                    control={control}
                    // rules={{ required: "Value is required." }}
                    render={({ field }) => {
                      return (
                        <MultiDropDown
                          options={dcList}
                          optionLabel={"loc"}
                          placeholder="Select DCs"
                          onChangeValues={(data) => {
                            field.onChange(data);
                          }}
                          value={field.value}
                        // error={errors.dcs?.type}

                        />
                      );
                    }}
                  />
                </div>
              </div>
              {/* {errors.loc?.type === "required" && (
                <div className="error-text ms-auto">Required</div>
              )} */}
            </div>
          </>}



        {/* <div className="d-flex flex-column mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">Loc</div>
            <div className="w-50">
              <Controller
                name="locs"
                control={control}
                rules={{ required: "Value is required." }}
                render={({ field }) => {
                  return (
                    <MultiDropDown
                      options={locs}
                      optionLabel={"loc"}
                      placeholder="Select Locs"
                      onChangeValues={(data) => {
                        field.onChange(data);
                      }}
                      error={errors.loc?.type}
                    />
                  );
                }}
              />
            </div>
          </div>
          {errors.loc?.type === "required" && (
            <div className="error-text ms-auto">Loc is required</div>
          )}
        </div> */}

        <div className="d-flex flex-column mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">Version</div>
            <input
              type={"text"}
              className={[
                "form-control upload-form w-50",
                errors.version?.type === "required" && "border-red",
              ].join(" ")}
              placeholder="Enter Version"
              {...register("version", { required: true })}
            />
          </div>
          {errors.version?.type === "required" && (
            <div className="error-text ms-auto">Version is required</div>
          )}
        </div>

        <div className="d-flex flex-column mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">Release Notes</div>
            <textarea
              className={[
                "form-control upload-form w-50",
                errors.notes?.type === "required" && "border-red",
              ].join(" ")}
              placeholder="Enter Release Notes"
              {...register("notes", { required: true })}
            />
          </div>
          {errors.notes?.type === "required" && (
            <div className="error-text ms-auto">Release Notes is required</div>
          )}
        </div>

        <div className="d-flex flex-column mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="sf-label">Select File</div>
            <input
              type={"file"}
              className={[
                "form-control w-50",
                isError && !file && "border-red",
              ].join(" ")}
              onChange={(e) => setFile(e.target.files[0])}
              ref={fileRef}
            />
          </div>
          {isError && !file && (
            <div className="error-text ms-auto">
              Please select file to upload
            </div>
          )}
        </div>

        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary mt-4 d-flex align-items-center"
            type="submit"
            disabled={isUploading}
          >
            <div>{isUploading ? "Uploading..." : "Upload APK"}</div>
            {isUploading && <div className="spinner ms-2"></div>}
          </button>
        </div>
      </form>
      <CommonModal
        modalOpenRef={successOpenRef}
        modalCloseRef={successCloseRef}
        id={"successModal"}
        content={<UploadSuccess />}
        modalSize={"md"}
        onModalClose={() => successCloseRef.current.click()}
      />
    </>
  );
};
export default UploadApk;
