import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { shippingPendenciesChange } from "../../../api";
import Navbar from "../../../containers/navbar";
import MainHeader from "../../../containers/header";
import Header from "../../../containers/content_header";
import ReportTemplate from "../../../containers/report_template";
import ErrorModal from "../../../containers/error_modal";

const ShippingPendency = ({ ...props }) => {
  const { loc, locationName, widthIsSmall } = props;
  const [shippingPendencyList, setShippingPendencyList] = useState([]);
  const [allShippingPendencyList, setAllShippingPendencyList] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [itemKeys, setItemKeys] = useState([]);
  const [lambdaError, setLambdaError] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const [isdownloadLoading, setIsdownloadLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const tableHeads = [
    "Loc",
    "Store",
    "Order No.",
    "Item",
    "Item Desc",
    "Yellow Lpn",
    "Lpn",
    "Lpn Type",
    "Lpn Qty",
    "WH Loc",
    "Create Date",
    "user ID"
  ];

  useEffect(() => {
    getShippingPendencySummary();
  }, []);

  useEffect(() => {
    getShippingPendencySummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText]);

  const getShippingPendencySummary = async () => {
    try {
      setShippingPendencyList([]);
      setIsListLoading(true);
      let payload = {
        loc: loc,
        start: currentPage * 10,
        length: 10,
        search_key: searchText,
        file: false,
      };
      const resData = await shippingPendenciesChange(payload);

      if (resData) {
        if (resData?.status === "error") {
          setLambdaError(resData?.error_message);
          openErrRef.current.click();
        } else {
          if (Object.keys(resData?.body?.records)?.length) {
            let formattedData = resData?.body?.records?.map((item) => {
              return {
                loc: item?.loc,
                store: item?.store,
                order_no: item?.order_no,
                item: item?.item,
                item_desc: item?.item_desc,
                yellow_lpn: item?.yellow_lpn,
                lpn: item?.lpn,
                lpn_type: item?.lpn_type,
                lpn_qty: item?.lpn_qty,
                wh_loc: item?.wh_loc,
                create_date: item?.create_date,
                user_id: item?.user_id,
              };
            });
            setShippingPendencyList(formattedData);
            setAllShippingPendencyList(formattedData);
            setfilteredData(formattedData);
            setItemKeys(Object.keys(formattedData[0]));
            setTotalRecords(resData?.body?.totalRecords);
          } else {
            setTotalRecords(resData?.body?.totalRecords);
          }
        }
        setIsListLoading(false);
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsListLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsListLoading(false);
    }
  };

  const onDownLoadS3Url = async () => {
    try {
      setIsdownloadLoading(true);
      let payload = {
        loc: loc,
        start: 0,
        length: 0,
        search_key: "",
        file: true,
      };
      const resData = await shippingPendenciesChange(payload);

      if (resData) {
        if (resData?.status === "error") {
          setLambdaError(resData?.error_message);
          openErrRef.current.click();
        } else {
          if (resData?.body?.file_url) {
            window.open(resData?.body?.file_url);
          }
        }
      } 
      // else {
      //   setLambdaError("Something went wrong");
      //   openErrRef.current.click();
      // }
      setIsdownloadLoading(false);
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsdownloadLoading(false);
    }
  };

  const addPageData = (pageData) => {
    setShippingPendencyList(pageData);
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="lpn-hm d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          headerText={"Shipping Pendency"}
        />
        <ReportTemplate
          widthIsSmall={widthIsSmall}
          tableHeads={tableHeads}
          itemKeys={itemKeys}
          data={shippingPendencyList}
          isTableLoading={isListLoading}
          loc={loc}
          buttonName={"Download Shipping Pendency List"}
          filePrefix={"shipping_pendency"}
          searchText={(text) => setSearchText(text)}
          allData={allShippingPendencyList}
          addPageData={addPageData}
          pageCount={10}
          filteredData={filteredData}
          totalRecords={totalRecords}
          type={"paginationFromDb"}
          changePage={(pageNo) => setCurrentPage(pageNo)}
          onDownloadUrl={onDownLoadS3Url}
          isdownloadLoading={isdownloadLoading}
        />
        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingPendency);
