import { useState } from "react";
import { useForm } from "react-hook-form";
import { downloadAPK, latestVersion } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from 'primereact/dropdown';


const LatestApk = ({ locs, stores, onAppNameChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedAppID, setFetchedAppID] = useState("");
  const [versionFound, setVersionFound] = useState(false);
  const [versionNotFound, setVersionNotFound] = useState(false);
  const [appName, setAppName] = useState("")
  const [version, setVersion] = useState("");
  const [selectedLoc, setSelectedLoc] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      appId: "",
      loc: "",
    },
  });

  const onSubmitAppId = async (data) => {
    try {
      console.log(data);
      setIsLoading(true);
      setVersionFound(false);
      setVersionNotFound(false);

      const versionDetails = await latestVersion(data?.appId, selectedLoc?.loc);
      if (versionDetails?.data?.status === "error") {
        setVersionNotFound(true);
        setFetchedAppID("");
        setVersion("");
        setVersionFound(false);
      } else {
        setVersionFound(true);
        setFetchedAppID(versionDetails?.data?.message?.app_id);
        setVersion(versionDetails?.data?.message?.version);
      }
      setIsLoading(false);
    } catch (error) { }
  };

  const onDownload = async (appId, version) => {
    try {
      const downloadUrl = await downloadAPK(appId, version, selectedLoc?.loc);
      if (downloadUrl?.data?.url) {
        window.open(downloadUrl.data?.url);
      } else {
        invokeToast(downloadUrl?.data?.message, downloadUrl?.data?.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invokeToast = (msg, type) => {
    type === "success"
      ? toast.success(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      : toast.error(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
  };

  return (
    <>
      <form
        className="d-flex flex-column align-items-center justify-content-center"
        onSubmit={handleSubmit(onSubmitAppId)}
      >
        <div className="d-flex flex-column justify-content-between mt-2 w-25">
          <div className="d-flex align-items-baseline justify-content-between ">
            <div className="header-text text-nowrap ">
              Select App &nbsp;:
            </div>
            <select
              {...register("appName", { required: true })}
              onChange={(e) => {
                setAppName(e.target.value)
                onAppNameChange(e.target.value)
              }}
              value={appName}
              className={[
                "form-select role-select form-select-md w-50",
                errors.appName?.type === "required" && "border-red",
              ].join(" ")}
            >
              <option
                defaultValue={null} disabled value={""}>
                Select App
              </option>
              <option value={"wms"}>WMS+</option>
              <option value={"simplus"}>SIM+</option>
              <option value={"rnm"}>RNM technician</option>
            </select>
          </div>
          {errors.loc?.type === "required" && (
            <div className="error-text ms-auto">Loc is required</div>
          )}
        </div>

        <div className="d-flex flex-row justify-content-between mt-4 w-25">
          <div className="header-text me-4 w-25">App ID &nbsp;:&nbsp;</div>
          <input
            type={"text"}
            className={[
              "form-control w-50",
              errors.appId?.type === "required" && "border-red",
            ].join(" ")}
            placeholder="Enter App ID"
            {...register("appId", { required: true })}
          />
        </div>
        {errors.appId?.type === "required" && (
          <div className="error-text">App ID is required</div>
        )}

        {(appName === "simplus" || appName === "rnm") &&
          <div className="d-flex flex-column justify-content-between mt-4 w-25">
            <div className="d-flex align-items-baseline justify-content-between">
              <div className="header-text ">
                Stores :
              </div>
              <Dropdown
                value={selectedLoc}
                options={stores}
                onChange={(e) => setSelectedLoc(e.value)}
                optionLabel="loc"
                // placeholder="Select a Location"
                filter
                showClear
                className="w-50"
              />
            </div>
          </div>
        }

        {appName === "wms" &&
          <div className="d-flex flex-column justify-content-between mt-4 w-25">
            <div className="d-flex align-items-baseline justify-content-between">
              <div className="header-text ">
                Loc :
              </div>
              <Dropdown
                value={selectedLoc}
                options={locs}
                onChange={(e) => setSelectedLoc(e.value)}
                optionLabel="loc"
                // placeholder="Select a Location"
                filter
                showClear
                className="w-50"
              />
            </div>
          </div>

        }

        {/* {appName === "simplus" &&
          <div className="d-flex flex-column mt-3">
            <div className="d-flex align-items-baseline">
              <div className="header-text text-nowrap">
                Select Store Location &nbsp;:&nbsp;
              </div>
              <select
                {...register("loc", { required: true })}
                className={[
                  "form-select role-select form-select-md",
                  errors.loc?.type === "required" && "border-red",
                ].join(" ")}
              >
                <option disabled value={""}>
                  Select Loc
                </option>
                {stores?.map((loc, i) => (
                  <option key={i} value={loc?.loc}>
                    {loc?.loc}
                  </option>
                ))}
              </select>
            </div>
            {errors.loc?.type === "required" && (
              <div className="error-text ms-auto">Loc is required</div>
            )}
          </div>} */}
        <button
          className="btn btn-primary mt-4 d-flex align-items-center"
          type="submit"
          disabled={isLoading}
        >
          <div>Check Latest Version</div>
          {isLoading && <div className="spinner ms-2"></div>}
        </button>
      </form>
      {versionFound && (
        <div className="d-flex flex-column mt-5 align-items-center">
          <div className="au-subtext mb-2">Latest Version Details</div>
          <div className="au-text mb-2">App ID : {fetchedAppID}</div>
          <div className="au-text mb-2">Version : {version}</div>
          <button
            className="btn btn-success mt-3"
            onClick={() => onDownload(fetchedAppID, version)}
          >
            Download
          </button>
        </div>
      )}
      {versionNotFound && (
        <div className="au-text mt-5 text-red">No Versions found!!!</div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default LatestApk;
