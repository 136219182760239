import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  downloadMktTemplateChange,
  mktSummaryChange,
  uploadGoodBucketChange,
  uploadRtvChange,
  uploadWriteoffChange,
} from "../../api";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import DownloadExcel from "../../containers/download_excel";
import ExtractExcel from "../../containers/extract_excel";
import ErrorModal from "../../containers/error_modal";
import CommonModal from "../../containers/common_modal";
import moment from "moment";
import "./mkt_summary.css";

const MktSummary = ({ ...props }) => {
  const { loc, locationName, widthIsSmall } = props;
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const uploadOpenRef = useRef();
  const uploadCloseRef = useRef();
  const uploadConfirmOpenRef = useRef();
  const uploadConfirmCloseRef = useRef();
  const uploadWriteoffConfirmOpenRef = useRef();
  const uploadWriteoffConfirmCloseRef = useRef();
  const uploadGoodBucketConfirmOpenRef = useRef();
  const uploadGoodBucketConfirmCloseRef = useRef();
  const responseErrorWriteoffOpenRef = useRef();
  const responseErrorWriteoffCloseRef = useRef();
  const responseErrorRtvOpenRef = useRef();
  const responseErrorRtvCloseRef = useRef();
  const responseErrorGoodBucketOpenRef = useRef();
  const responseErrorGoodBucketCloseRef = useRef();
  const rtvFileRef = useRef();
  const writeoffFileRef = useRef();

  const [isMktLoading, setIsMktLoading] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [isRtvLoading, setIsRtvLoading] = useState(false);
  const [isWriteoffLoading, setIsWriteoffLoading] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [allmktList, setAllmktList] = useState([]);

  useEffect(() => {
    getMktSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMktSummary = async () => {
    try {
      setData([]);
      setExportData([]);
      setIsMktLoading(true);
      let payload = { loc: loc };
      const mktData = await mktSummaryChange(payload);

      if (mktData) {
        if (mktData?.status === "error") {
          setLambdaError(mktData?.error_message);
          openErrRef.current.click();
        } else {
          if (Object.keys(mktData?.body)?.length) {
            setData(mktData?.body);
            let formattedData =
              mktData?.body?.length &&
              mktData?.body?.map((item) => {
                return {
                  loc: item?.loc,
                  item: item?.item,
                  lpn: item?.lpn,
                  mkt_qty: item?.mkt_qty,
                  rej_rsn_code: item?.rej_rsn_code,
                  rsn_desc: item?.rsn_desc,
                  value: item?.value,
                  create_date: item?.create_date,
                };
              });
            setExportData(formattedData);
            setAllmktList(formattedData);
          }
        }
        setIsMktLoading(false);
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsMktLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsMktLoading(false);
    }
  };

  const downloadTemplate = async () => {
    try {
      setIsTemplateLoading(true);
      let payload = { report_type: "template" };
      const mktData = await downloadMktTemplateChange(payload);

      if (mktData) {
        if (mktData?.status === "error") {
          setLambdaError(mktData?.error_message);
          openErrRef.current.click();
        } else {
          if (Object.keys(mktData?.body)?.length) {
            window.open(mktData?.body);
          }
        }
        setIsTemplateLoading(false);
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsTemplateLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsTemplateLoading(false);
    }
  };

  const onUploadRtv = async () => {
    try {
      setIsRtvLoading(true);

      let payload = uploadData
        .map((item) => {
          if (item?.loc && item?.item && item?.qty && item?.reason_desc) {
            return {
              loc: parseInt(item?.loc),
              item: String(item?.item),
              qty: parseInt(item?.qty),
              rej_rsn_desc: String(item?.reason_desc),
              action: "RTV",
              user_loc: loc,
            };
          }
        })
        .filter((item) => item);

      const mktData = await uploadRtvChange(payload);

      if (mktData) {
        rtvFileRef.current.value = "";
        if (mktData?.status === "error") {
          if (mktData?.error_message) {
            uploadConfirmCloseRef.current.click();
            setLambdaError(
              mktData?.error_message === "Please contact administrator"
                ? "Please contact administrator"
                : "Please contact IT support"
            );
            openErrRef.current.click();
          } else {
            uploadConfirmCloseRef.current.click();
            setResponseData((mktData?.body?.length && mktData?.body) || []);
            responseErrorRtvOpenRef.current.click();
          }
        } else if (mktData?.status === "success") {
          uploadConfirmCloseRef.current.click();
          uploadOpenRef.current.click();
        }
        setIsRtvLoading(false);
      } else {
        uploadConfirmCloseRef.current.click();
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsRtvLoading(false);
      }
    } catch (error) {
      console.log(error);
      uploadConfirmCloseRef.current.click();
      setLambdaError(error);
      openErrRef.current.click();
      setIsRtvLoading(false);
    }
  };

  const showValidationError = (err) => {
    uploadConfirmCloseRef.current.click();
    setLambdaError(err);
    openErrRef.current.click();
  };

  const onUploadWriteoff = async () => {
    try {
      setIsWriteoffLoading(true);

      let payloadItems = uploadData
        .map((item) => {
          return {
            item: String(item?.item),
            qty: parseInt(item?.qty),
            rej_rsn_desc: item?.reason_desc,
            action: "writeoff",
          };
        })
        .filter((item) => item);

      let payload = {
        inv_adj_id: null,
        user_id: "inventory_controller",
        loc: loc,
        source: "WMS",
        details: payloadItems,
        reason: 88,
        // from_disposition: "ATS", 
        // to_disposition: "",
      };

      const mktData = await uploadWriteoffChange(payload);

      if (mktData) {
        writeoffFileRef.current.value = "";
        if (mktData?.status === "error") {
          if (mktData?.error_message) {
            uploadWriteoffConfirmCloseRef.current.click();
            setLambdaError(
              mktData?.error_message === "Please contact administrator"
                ? "Please contact administrator"
                : "Please contact IT support"
            );
            openErrRef.current.click();
          } else {
            setResponseData((mktData?.body?.length && mktData?.body) || []);
            uploadWriteoffConfirmCloseRef.current.click();
            responseErrorWriteoffOpenRef.current.click();
          }
        } else if (mktData?.status === "success") {
          uploadWriteoffConfirmCloseRef.current.click();
          uploadOpenRef.current.click();
        } else {
          uploadWriteoffConfirmCloseRef.current.click();
          setLambdaError("Please contact IT support");
          openErrRef.current.click();
        }
        setIsWriteoffLoading(false);
      } else {
        uploadWriteoffConfirmCloseRef.current.click();
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsWriteoffLoading(false);
      }
    } catch (error) {
      console.log(error);
      uploadWriteoffConfirmCloseRef.current.click();
      setLambdaError(error);
      openErrRef.current.click();
      setIsWriteoffLoading(false);
    }
  };

  const onUploadGoodBucket = async () => {
    try {
      setIsWriteoffLoading(true);

      let payload = uploadData
        .map((item) => {
          return {
            loc: parseInt(item?.loc),
            item: String(item?.item),
            qty: parseInt(item?.qty),
            rej_rsn_desc: String(item?.reason_desc),
            action: "goodbucket",
            user_loc: loc,
          };
        })
        .filter((item) => item);

      const mktData = await uploadGoodBucketChange(payload);

      if (mktData) {
        writeoffFileRef.current.value = "";
        if (mktData?.status === "error") {
          if (mktData?.error_message) {
            uploadGoodBucketConfirmCloseRef.current.click();

            setLambdaError(
              mktData?.error_message === "Please contact administrator"
                ? "Please contact administrator"
                : "Please contact IT support"
            );
            openErrRef.current.click();
          } else {
            setResponseData((mktData?.body?.length && mktData?.body) || []);
            uploadGoodBucketConfirmCloseRef.current.click();
            responseErrorGoodBucketOpenRef.current.click();
          }
        } else if (mktData?.status === "success") {
          uploadGoodBucketConfirmCloseRef.current.click();
          uploadOpenRef.current.click();
        } else {
          uploadGoodBucketConfirmCloseRef.current.click();
          setLambdaError("Please contact IT support");
          openErrRef.current.click();
        }
        setIsWriteoffLoading(false);
      } else {
        uploadGoodBucketConfirmCloseRef.current.click();
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsWriteoffLoading(false);
      }
    } catch (error) {
      console.log(error);
      uploadGoodBucketConfirmCloseRef.current.click();
      setLambdaError(error);
      openErrRef.current.click();
      setIsWriteoffLoading(false);
    }
  };

  const confirmFileUpload = (data, fileType, type) => {
    setIsContentError(false);
    rtvFileRef.current.value = "";
    writeoffFileRef.current.value = "";

    if (
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      showValidationError(
        "Invalid file format. Please reupload a proper file format or refer the template file."
      );
      return;
    }

    if (!data?.length) {
      showValidationError(
        "Blank file. Please insert data in file and reupload."
      );
      return;
    }

    const formattedUploadData = data
      .map((item) => {
        if (
          !item?.loc &&
          !item?.item &&
          !item?.qty &&
          !item?.action &&
          !item?.reason_desc
        ) {
          return null;
        } else {
          return item;
        }
      })
      .filter((item) => item);

    setUploadData(formattedUploadData);

    const allKeysNotPresent = formattedUploadData?.find(
      (item) =>
        !item?.loc ||
        !item?.item ||
        !item?.qty ||
        !item?.reason_desc ||
        !item?.action ||
        (type === "rtv" && item?.action !== "RTV") ||
        (type === "writeoff" && item?.action !== "Writeoff") ||
        (type === "goodbucket" && item?.action !== "Move to Good Bucket") ||
        item?.qty < 1 ||
        item.loc !== loc
    );

    if (allKeysNotPresent) {
      setIsContentError(true);
    }
    if (type === "rtv") uploadConfirmOpenRef.current.click();
    if (type === "writeoff") uploadWriteoffConfirmOpenRef.current.click();
    if (type === "goodbucket") uploadGoodBucketConfirmOpenRef.current.click();
  };

  const UploadSuccess = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Data Uploaded Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <button
          className="btn btn-preview mt-3"
          onClick={() => {
            uploadCloseRef.current.click();
            setData();
            getMktSummary();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );

  const UploadConfirm = ({ type }) => (
    <div
      className={["d-flex flex-column", !widthIsSmall && "up-confirm"].join(
        " "
      )}
    >
      <div
        className={["up-head-text mb-3", widthIsSmall && "text-wrap"].join(" ")}
      >
        Please confirm before submitting{" "}
        {type === "rtv"
          ? "RTV"
          : type === "writeoff"
          ? "Writeoff"
          : "Good Bucket"}
        &nbsp; data
      </div>
      <div className="mkt-table p-0 m-0">
        <table className="table">
          <thead>
            <tr>
              <th>loc</th>
              <th>item</th>
              <th>qty</th>
              <th>reason desc</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {uploadData?.map((item, i) => (
              <tr key={i}>
                <td>{item?.loc || "-"}</td>
                <td>{item?.item || "-"}</td>
                <td>{item?.qty || 0}</td>
                <td>{item?.reason_desc || "-"}</td>
                <td>{item?.action || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-column mt-auto">
        {isContentError && (
          <div
            className={["up-error my-3", widthIsSmall && "text-wrap"].join(" ")}
          >
            Missing content in file or wrong data. Please check the file and
            reupload !!!
          </div>
        )}
        <div className={"d-flex ms-auto mt-3"}>
          <button
            className="btn btn-guide me-5"
            onClick={() => {
              if (type === "rtv") {
                uploadConfirmCloseRef.current.click();
                setIsRtvLoading(false);
              } else if (type === "writeoff") {
                uploadWriteoffConfirmCloseRef.current.click();
                setIsWriteoffLoading(false);
              } else {
                uploadGoodBucketConfirmCloseRef.current.click();
                setIsWriteoffLoading(false);
              }
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-preview d-flex align-items-center"
            onClick={() =>
              type === "rtv"
                ? onUploadRtv()
                : type === "writeoff"
                ? onUploadWriteoff()
                : type === "goodbucket"
                ? onUploadGoodBucket()
                : null
            }
            disabled={isRtvLoading || isContentError || isWriteoffLoading}
          >
            <div>Confirm</div>
            {(isRtvLoading || isWriteoffLoading) && (
              <div className="spinner ms-2"></div>
            )}
          </button>
        </div>
      </div>
    </div>
  );

  const UploadErrorPreview = ({ type }) => (
    <div
      className={["d-flex flex-column", !widthIsSmall && "up-failed"].join(" ")}
    >
      <div
        className={[
          "up-head-text mb-3 text-red",
          widthIsSmall && "text-wrap",
        ].join(" ")}
      >
        Following data failed to upload. Please check again and upload
      </div>
      <div className="mkt-table p-0 m-0">
        <table className="table">
          <thead>
            <tr>
              <th>loc</th>
              <th>item</th>
              <th>qty</th>
              <th>reason desc</th>
              <th>status</th>
              <th>remarks</th>
            </tr>
          </thead>
          <tbody>
            {responseData?.map((item, i) => (
              <tr key={i}>
                <td>{item?.loc || "-"}</td>
                <td>{item?.item || "-"}</td>
                <td>{item?.qty || 0}</td>
                <td>{item?.rej_rsn_desc || "-"}</td>
                <td>{item?.file_status || "-"}</td>
                <td>{item?.process_remarks || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-column mt-auto">
        <div className={"d-flex ms-auto mt-3"}>
          <DownloadExcel
            data={responseData}
            fileName={`${
              type === "rtv"
                ? "RTV"
                : type === "goodbucket"
                ? "Good Bucket"
                : "Writeoff"
            }_failed_ ${loc}_${moment(new Date()).format("YYYY-MM-DD")}`}
            buttonName={"Download Error Data"}
          />
          <button
            className="btn btn-guide ms-4"
            onClick={() => {
              if (type === "rtv") {
                responseErrorRtvCloseRef.current.click();
              } else if (type === "writeoff") {
                responseErrorWriteoffCloseRef.current.click();
              } else {
                responseErrorGoodBucketCloseRef.current.click();
              }
              setData();
              getMktSummary();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );

  const searchReport = (data) => {
    const pendingData = [...allmktList];
    const filteredData =
      data?.length &&
      pendingData.filter((item) => {
        return (
          String(item?.item).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.loc).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.item).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.lpn).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.mkt_qty).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.rej_rsn_code)
            .toLowerCase()
            .includes(data.toLowerCase()) ||
          String(item?.rsn_desc).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.value).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.create_date).toLowerCase().includes(data.toLowerCase())
        );
      });
    if (filteredData && filteredData.length) {
      setData(filteredData);
    }
    if (data === "") {
      setData(allmktList);
    }
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="d-flex flex-column mkt"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          headerText={"Markout Summary"}
        />
        <div
          className="mkt-table"
          style={{ padding: widthIsSmall ? "25px" : "40px" }}
        >
          <div className="d-flex align-items-center ms-auto mb-3 w-50 justify-content-end">
            <input
              type={"text"}
              placeholder={"Search"}
              className={"form-control ms-3 w-50"}
              onChange={(e) => searchReport(e.target.value)}
            />
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>loc</th>
                <th>item</th>
                <th>lpn</th>
                <th>qty</th>
                <th>reason code</th>
                <th>reason desc</th>
                <th>create date</th>
                <th>Value Excl Tax</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => (
                <tr key={i}>
                  <td>{item?.loc}</td>
                  <td>{item?.item}</td>
                  <td>{item?.lpn}</td>
                  <td>{item?.mkt_qty}</td>
                  <td>{item?.rej_rsn_code}</td>
                  <td>{item?.rsn_desc}</td>
                  <td>{item?.create_date}</td>
                  <td>{item?.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {isMktLoading && (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "5%" }}
            >
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {!data?.length && !isMktLoading && (
            <div className="d-flex justify-content-center no-records">
              No records found
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row justify-content-center mkt-btn mb-5">
          <DownloadExcel
            data={exportData}
            fileName={`MKT_summary_ ${loc}_${moment(new Date()).format(
              "YYYY-MM-DD"
            )}`}
            buttonName={"Export Mkt Summary"}
            margin={widthIsSmall ? "0px 40px 16px" : "0px 0px 16px 40px"}
          />
          <div className={widthIsSmall ? "sp-mx-40 mb-3" : "mkt-left"}>
            <button
              className={[
                "btn btn-download-lpn d-flex align-items-center justify-content-center",
                widthIsSmall && "w-100",
              ].join(" ")}
              onClick={() => downloadTemplate()}
              disabled={isTemplateLoading}
            >
              <div>Download Template</div>
              {isTemplateLoading && <div className="spinner ms-2"></div>}
            </button>
          </div>
          <div className={widthIsSmall ? "sp-mx-40 mb-3" : "mkt-middle"}>
            <ExtractExcel
              buttonName={"Upload for RTV"}
              width={widthIsSmall ? "100%" : "150px"}
              onSubmit={(data, fileType, type) =>
                confirmFileUpload(data, fileType, type)
              }
              inputRef={rtvFileRef}
              extractType={"rtv"}
            />
          </div>
          <div className={widthIsSmall ? "sp-mx-40 mb-3" : ""}>
            <ExtractExcel
              buttonName={"Write Off"}
              width={widthIsSmall ? "100%" : "125px"}
              inputRef={writeoffFileRef}
              onSubmit={(data, fileType, type) =>
                confirmFileUpload(data, fileType, type)
              }
              extractType={"writeoff"}
            />
          </div>
          <div className={widthIsSmall ? "sp-mx-40 mb-3" : "mkt-left"}>
            <ExtractExcel
              buttonName={"Move to Good Bucket"}
              width={widthIsSmall ? "100%" : "175px"}
              inputRef={writeoffFileRef}
              onSubmit={(data, fileType, type) =>
                confirmFileUpload(data, fileType, type)
              }
              extractType={"goodbucket"}
            />
          </div>
        </div>
      </div>
      <CommonModal
        modalCloseRef={uploadCloseRef}
        modalOpenRef={uploadOpenRef}
        id={"uploadSuccessModal"}
        content={<UploadSuccess />}
        header={true}
        headerBorder={false}
        onModalClose={() => getMktSummary()}
      />

      <CommonModal
        modalCloseRef={uploadConfirmCloseRef}
        modalOpenRef={uploadConfirmOpenRef}
        id={"uploadModal"}
        content={<UploadConfirm type={"rtv"} />}
        modalSize={"lg"}
      />
      <CommonModal
        modalCloseRef={uploadWriteoffConfirmCloseRef}
        modalOpenRef={uploadWriteoffConfirmOpenRef}
        id={"uploadWriteoffModal"}
        content={<UploadConfirm type={"writeoff"} />}
        modalSize={"lg"}
      />
      <CommonModal
        modalCloseRef={uploadGoodBucketConfirmCloseRef}
        modalOpenRef={uploadGoodBucketConfirmOpenRef}
        id={"uploadGoodBucketModal"}
        content={<UploadConfirm type={"goodbucket"} />}
        modalSize={"lg"}
      />
      <CommonModal
        modalCloseRef={responseErrorWriteoffCloseRef}
        modalOpenRef={responseErrorWriteoffOpenRef}
        id={"uploadWriteoffResponseModal"}
        content={<UploadErrorPreview type={"writeoff"} />}
        modalSize={"lg"}
        header={true}
        headerBorder={false}
      />
      <CommonModal
        modalCloseRef={responseErrorRtvCloseRef}
        modalOpenRef={responseErrorRtvOpenRef}
        id={"uploadRtvResponseModal"}
        content={<UploadErrorPreview type={"rtv"} />}
        modalSize={"lg"}
        header={true}
        headerBorder={false}
      />
      <CommonModal
        modalCloseRef={responseErrorGoodBucketCloseRef}
        modalOpenRef={responseErrorGoodBucketOpenRef}
        id={"uploadGoodBucketResponseModal"}
        content={<UploadErrorPreview type={"goodbucket"} />}
        modalSize={"lg"}
        header={true}
        headerBorder={false}
      />

      <ErrorModal
        id={"errorModal"}
        openRef={openErrRef}
        closeRef={closeErrRef}
        headerColor={"red"}
        msg={lambdaError}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MktSummary);
