import { useRef, useState } from "react";
import { connect } from "react-redux";
import { generateQrCodesChange, getBinDetailsChange } from "../../api";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import NftBinMap from "../../containers/nft_bin_map";
import NftBinDuplicate from "../../containers/nft_bin_duplicate";
import CommonModal from "../../containers/common_modal";
import ErrorModal from "../../containers/error_modal";
import "./mapping.css";

const NftBinMapping = ({ ...props }) => {
  const { loc } = props;
  const [currentScreen, setCurrentScreen] = useState("mapping");
  const [isLoading, setIsLoading] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const [formsData, setFormsData] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [qrQty, setQrQty] = useState(0);
  const submitPreviewOpenRef = useRef();
  const submitPreviewCloseRef = useRef();
  const submitPreviewSuccessOpenRef = useRef();
  const submitPreviewSuccessCloseRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();

  const generateBins = (assetId, numOfShelves, numOfQrCodes) => {
    const bins = [];
    for (let row = 1; row <= numOfShelves; ++row) {
      for (let col = 1; col <= Math.ceil(numOfQrCodes / numOfShelves); ++col) {
        const bin = `${assetId}R${row}C${col}`;
        bins.push(bin);
      }
    }
    return bins;
  };

  const onSubmitQrData = async () => {
    try {
      setResetFields(false);
      setIsLoading(true);
      const numOfQrCodes =
        Math.ceil(formsData.qr_qty / formsData.shelves) * formsData.shelves;
      let bins = generateBins(
        formsData.assetId,
        formsData.shelves,
        numOfQrCodes
      );

      const payload = {
        loc: loc,
        bins: bins,
      };

      const qrCodeGenerated = await generateQrCodesChange(payload);

      if (qrCodeGenerated) {
        if (qrCodeGenerated.status === "error") {
          setLambdaError("Something went wrong");
          openErrRef.current.click();
        } else {
          setResetFields(true);
          submitPreviewCloseRef.current.click();
          submitPreviewSuccessOpenRef.current.click();
          qrCodeGenerated?.body?.url &&
            setDownloadUrl(qrCodeGenerated?.body?.url);
        }
      } else {
        setIsLoading(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSubmitDuplicateQrCode = async (data) => {
    try {
      setResetFields(false);
      setIsLoading(true);

      let payload = {
        loc: loc,
        bin: data?.qr_no,
      };

      const binDetails = await getBinDetailsChange(payload);

      if (binDetails) {
        if (binDetails.status === "error") {
          setLambdaError("Something went wrong");
          openErrRef.current.click();
          setIsLoading(false);
          return;
        } else if (!binDetails?.body?.length) {
          setLambdaError("No such bin exists!");
          openErrRef.current.click();
          setIsLoading(false);
          return;
        }
      } else {
        setIsLoading(true);
      }

      const qrPayload = {
        loc: loc,
        bins: [data?.qr_no],
      };

      const qrCodeGenerated = await generateQrCodesChange(qrPayload);

      if (qrCodeGenerated) {
        if (qrCodeGenerated.status === "error") {
          setLambdaError("Something went wrong");
          openErrRef.current.click();
        } else {
          setResetFields(true);
          submitPreviewSuccessOpenRef.current.click();
          qrCodeGenerated?.body?.url &&
            setDownloadUrl(qrCodeGenerated?.body?.url);
        }
      } else {
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const SubmitPreview = () => (
    <div className="d-flex flex-column align-items-center pb-3">
      <div className="sp-head-text">No. of QR Codes to be printed: {qrQty}</div>
      <div className="d-flex">
        <button
          className="btn btn-secondary"
          onClick={() => submitPreviewCloseRef.current.click()}
        >
          Cancel
        </button>
        <button
          className="btn btn-submit ms-3 d-flex align-items-center"
          onClick={() => onSubmitQrData()}
          disabled={isLoading}
        >
          <div>Submit</div>
          {isLoading && <div className="spinner ms-2"></div>}
        </button>
      </div>
    </div>
  );

  const SubmitPreviewSuccess = () => (
    <div className="d-flex flex-column align-items-center pb-3">
      <div className="sp-head-text">QR Code generated successfully</div>
      <button className="btn btn-save" onClick={() => window.open(downloadUrl)}>
        DOWNLOAD PDF
      </button>
      <button
        className="btn btn-secondary ms-auto"
        onClick={() => submitPreviewSuccessCloseRef.current.click()}
      >
        OK
      </button>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div className="mp-screen d-flex">
        <div className="mp-tabs d-flex flex-column">
          <ul className="nav nav-tabs d-flex justify-content-center">
            <li className="nav-item w-50">
              <button
                className={[
                  "nav-link mp-nav w-100",
                  currentScreen === "mapping" && "active",
                ].join(" ")}
                onClick={() => setCurrentScreen("mapping")}
              >
                NFT-BIN Mapping
              </button>
            </li>
            <li className="nav-item w-50">
              <button
                className={[
                  "nav-link mp-nav w-100",
                  currentScreen === "duplicate" && "active",
                ].join(" ")}
                onClick={() => setCurrentScreen("duplicate")}
              >
                NFT-BIN Duplicate
              </button>
            </li>
          </ul>
          <div className="d-flex justify-content-center mt-5">
            {currentScreen === "mapping" && (
              <NftBinMap
                onSubmitQrData={(data) => {
                  setFormsData({
                    assetId: data.assetId.toUpperCase(),
                    shelves: data.shelves,
                    qr_qty: data.qr_qty,
                  });
                  setQrQty(
                    Math.ceil(data.qr_qty / data.shelves) * data.shelves
                  );
                  submitPreviewOpenRef.current.click();
                }}
                isLoading={isLoading}
                resetFields={resetFields}
              />
            )}
            {currentScreen === "duplicate" && (
              <NftBinDuplicate
                onSubmitQrData={onSubmitDuplicateQrCode}
                isLoading={isLoading}
                resetFields={resetFields}
              />
            )}
          </div>
        </div>
      </div>

      <CommonModal
        modalCloseRef={submitPreviewCloseRef}
        modalOpenRef={submitPreviewOpenRef}
        id={"submitPreviewModal"}
        content={<SubmitPreview />}
        header={true}
        headerBorder={false}
      />
      <CommonModal
        modalCloseRef={submitPreviewSuccessCloseRef}
        modalOpenRef={submitPreviewSuccessOpenRef}
        id={"submitPreviewSuccessModal"}
        content={<SubmitPreviewSuccess />}
        header={true}
        headerBorder={false}
      />

      <ErrorModal
        id={"errorModal"}
        openRef={openErrRef}
        closeRef={closeErrRef}
        headerColor={"red"}
        msg={lambdaError}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NftBinMapping);
