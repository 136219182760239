import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  ackCopyUpload,
  filePresignedUrlChange,
  uploadFile,
} from "../../api";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import ErrorModal from "../../containers/error_modal";
import CommonModal from "../../containers/common_modal";
import "react-datepicker/dist/react-datepicker.css";
import "./ack.css";

const AckUpload = ({ ...props }) => {
  const { loc, locationName, widthIsSmall, username, } = props;

  const invoiceUploadOpenRef = useRef();
  const invoiceUploadCloseRef = useRef();

  const invoiceUploadPreviewOpenRef = useRef();
  const invoiceUploadPreviewCloseRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const openFileErrRef = useRef();
  const closeFileErrRef = useRef();
  const toSaveSuccessOpenRef = useRef();
  const toSaveSuccessCloseRef = useRef();

  const [po, setPo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [extractRemark, setExtractRemark] = useState("");
  const [supplier, setSupplier] = useState("");
  const [isPoVerifying, setIsPoVerifying] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isToSaveLoading, setIsToSaveLoading] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [poVerified, setPoVerified] = useState(false);
  const [isInvoiceError, setIsInvoiceError] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [textractInvoiceAmount, setTextractInvoiceAmount] = useState(0);
  const [previewFileUrl, setPreviewFileUrl] = useState("");
  const [orderInd, setOrderInd] = useState("");



  //new
  const [selectedOption, setSelectedOption] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDocNumberChange = (event) => {
    setDocNumber(event.target.value);
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };


  const handleUpload = async () => {
    console.log("file file", file)
    if (!file.type == "application/pdf") {
      setLambdaError("Invalid file format");
      setLambdaError("Invalid file format");
      openFileErrRef.current.click();
    }
    else {
      const renamedFile = new File([file], `${docNumber}_ack.pdf`, { type: file.type });
      console.log("Selected Option:", selectedOption);
      console.log("Document Number:", docNumber);
      console.log("File:", renamedFile);

      let payload = {
        ack_type: selectedOption,
        doc_no: docNumber,
        filename: renamedFile.name,
        user_id: String(username),
      }
      console.log("payload", payload)

      setIsLoading(true)
      let result = await ackCopyUpload(payload);
      console.log("ressult", result)
      console.log("result", result?.body?.url)
      if (result?.body?.url) {
        let fileUploaded = await uploadFileWithPresignedUrl(
          renamedFile,
          result?.body
        );

        console.log("fileUploaded,fileUploaded", fileUploaded)
        if (fileUploaded) {
          setIsLoading(false)

          invoiceUploadOpenRef.current.click();
        }
      }
      else {
        console.log("elseeee")
        setIsLoading(false)
        setLambdaError(result?.error_message)
        openErrRef.current.click()
      }
    }
  };



  const uploadFileWithPresignedUrl = async (filetoUpload, generatedUrl) => {
    try {
      const fileUploadData = new FormData();
      Object.keys(generatedUrl.fields).forEach((key) => {
        fileUploadData.append(key, generatedUrl.fields[key]);
      });
      fileUploadData.append("file", filetoUpload);

      const fileUploaded = await uploadFile(generatedUrl.url, fileUploadData);
      if (fileUploaded) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setIsUploadLoading(false);
      setLambdaError("Failed to upload file");
      openErrRef.current.click();
    }
  };


  const clearFields = () => {

    setDocNumber("")
    setFile(null)
    setSelectedOption("")
    fileInputRef.current.value = null;
  };


  const InvoiceUploaded = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Ack copy PDF Uploaded Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <div
          className="btn btn-preview mt-3"
          onClick={() => {
            invoiceUploadCloseRef.current.click();
            clearFields();
          }}
        >
          OK
        </div>
      </div>
    </div>
  );

  const ToSavedSuccess = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Data Saved Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <div
          className="btn btn-preview mt-3"
          onClick={() => {
            toSaveSuccessCloseRef.current.click();
            clearFields();
          }}
        >
          OK
        </div>
      </div>
    </div>
  );

  const ErrorModalContent = () => (
    <div className="d-flex flex-column mb-4">
      <div className="d-flex justify-content-center mb-4 mt-3">
        <button
          className="btn btn-preview"
          onClick={() => {
            closeErrRef.current.click();
            clearFields();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="up-inv d-flex flex-column w-100"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          progressBar={false}
          headerText={"Ack Copy Upload"}
        />
        <div className="up-inv-form">
          <div className={[widthIsSmall ? "" : "form w-50"]}>

            <div className="d-flex flex-column form-field">
              <div className="d-flex justify-content-between align-items-center">
                <label className="sf-label">Select Type </label>
                <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="form-control w-50">
                  <option disabled value={""}>Select type</option>
                  <option value="gdn">GDN Ack</option>
                  <option value="rtv">RTV Ack</option>
                </select>
              </div>
            </div>

            <div className="d-flex flex-column form-field">
              <div className="d-flex justify-content-between align-items-center">
                <label className="sf-label">Enter Doc No.</label>
                <input
                  placeholder="Doc number"
                  onChange={handleDocNumberChange}
                  className="form-control w-50"
                  type="text"
                  value={docNumber}
                />
              </div>
            </div>

            <div className="d-flex flex-column form-field">
              <div className="d-flex justify-content-between align-items-center">
                <label className="sf-label">Upload File:</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="form-control w-50"
                  accept="application/pdf"
                  ref={fileInputRef}
                />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-baseline flex-column flex-lg-row flex-xl-row flex-md-row">
            <button
              onClick={handleUpload}
              disabled={isLoading || docNumber === "" || file == null || selectedOption === ""}
              className="btn btn-primary"
            >

              {isLoading ? <i className="spinner ms-3"></i> : 'Upload'}

            </button>
            <div
              className="position-relative mt-xl-5 mt-lg-5 mt-md-5 ms-xl-5 ms-lg-5 ms-md-5"
              style={{
                width: widthIsSmall ? '100%' : 'auto',
              }}
            ></div>
          </div>
        </div>
      </div>


      <CommonModal
        modalCloseRef={invoiceUploadCloseRef}
        modalOpenRef={invoiceUploadOpenRef}
        id={"invoiceUploadModal"}
        content={<InvoiceUploaded />}
        header={true}
        headerBorder={false}
      />

      <CommonModal
        modalCloseRef={toSaveSuccessCloseRef}
        modalOpenRef={toSaveSuccessOpenRef}
        id={"toSaveSuccessModal"}
        content={<ToSavedSuccess />}
        header={true}
        headerBorder={false}
      />

      <ErrorModal
        id={"errorModal"}
        openRef={openErrRef}
        closeRef={closeErrRef}
        headerColor={"red"}
        msg={lambdaError}
        contents={<ErrorModalContent />}
        pb={false}
        backDrop={true}
      // closeModal={clearFields}
      />

      <ErrorModal
        id={"fileErrorModal"}
        openRef={openFileErrRef}
        closeRef={closeFileErrRef}
        headerColor={"red"}
        msg={lambdaError}
        backDrop={true}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AckUpload);
