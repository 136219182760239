import { useEffect, useRef, useState } from "react";
import { reasonCodes } from "../../constants/constants";
import AddIcon from "../../assets/images/add.png";
import RemoveIcon from "../../assets/images/remove.png";
import CommonModal from "../../containers/common_modal";
import "./gdn_content_view.css";

const GdnContentView = ({
  gdnData,
  saveGdn,
  isSaveLoading,
  onSubmitGdn,
  isSubmitLoading,
  onGdnUpdate,
  isError,
  gdnNo,
  enableGdnSubmit,
  resetSubmit,
  errorReasonItem,
  isManualGDN,
}) => {
  const reasonModalCloseRef = useRef();
  const reasonModalOpenRef = useRef();
  const [itemSelected, setItemSelected] = useState(null);
  const [itemIndex, setitemIndex] = useState("");
  const [addReasonShow, setAddReasonShow] = useState(false);
  const [newReasonNeeded, setNewReasonNeeded] = useState(false);
  const [isReasonsError, setIsReasonsError] = useState(false);
  const [gdnItemsPresent, setGdnItemsPresent] = useState(gdnData);

  const onChangeReason = (value, index, type) => {
    resetSubmit();
    let items = [...gdnData];
    let gdnItems = items
      .map((gdn, i) => {
        let arrObj = { ...gdn };

        if (type === "reason" && i === index) {
          arrObj.rej_rsn_code = value || 0;
          let reasonDesc = reasonCodes.find(
            (rsn) => String(rsn.code) === String(value)
          );
          arrObj.rej_rsn_desc = reasonDesc?.desc || "";
        } else if (type === "inv_qty" && i === index) {
          arrObj.invoice_qty = Number(value);
          arrObj.total_rejected_qty = arrObj?.invoice_qty
            ? Math.abs((arrObj?.invoice_qty || 0) - (arrObj?.received || 0))
            : 0;
        } else if (type === "gkm_flag" && i === index) {
          arrObj.gkm_flag = value;
        } else if (type === "inv_amt" && i === index) {
          arrObj.item_inv_amt = value;
        }
        return arrObj;
      })
      .filter((item) => item);
    onGdnUpdate(gdnItems);
  };

  useEffect(() => {
    if (itemIndex !== "") {
      let updatedGdnItems = gdnItemsPresent.map((item, i) => {
        if (i === itemIndex) {
          return itemSelected;
        }
        return item;
      });
      setGdnItemsPresent(updatedGdnItems);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSelected]);

  useEffect(() => {
    if (
      itemSelected?.rej_rsn_code?.length > 1 &&
      addReasonShow &&
      !itemSelected?.itemIsAdded
    ) {
      setNewReasonNeeded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReasonShow]);

  const findReasonDesc = (value) => {
    return reasonCodes.find((rsn) => String(rsn.code) === String(value))?.desc;
  };

  const onAddItemReasons = (value, reasonIndex) => {
    let reasonCode, reasonDesc;
    if (reasonIndex === 0) {
      reasonCode = itemSelected?.rej_rsn_code[1]
        ? [parseInt(value), itemSelected?.rej_rsn_code[1]]
        : [parseInt(value)];
      reasonDesc = itemSelected?.rej_rsn_desc[1]
        ? [findReasonDesc(value), itemSelected?.rej_rsn_desc[1]]
        : [findReasonDesc(value)];
    } else {
      reasonCode = itemSelected?.rej_rsn_code[0]
        ? [itemSelected?.rej_rsn_code[0], parseInt(value)]
        : [parseInt(value)];
      reasonDesc = itemSelected?.rej_rsn_desc[0]
        ? [itemSelected?.rej_rsn_desc[0], findReasonDesc(value)]
        : [findReasonDesc(value)];
    }

    setItemSelected({
      ...itemSelected,
      rej_rsn_code: reasonCode,
      rej_rsn_desc: reasonDesc,
    });
    resetSubmit();
  };

  const onSaveReasons = () => {
    setIsReasonsError(false);
    setAddReasonShow(false);
    const validateReasons = validateReasonSubmit();
    if (validateReasons) {
      setIsReasonsError(true);
      return;
    }
    reasonModalCloseRef.current.click();
    setNewReasonNeeded(false);
    onGdnUpdate(gdnItemsPresent);
  };

  const validateReasonSubmit = () => {
    const errorExist =
      (itemSelected?.rej_rsn_code[0] &&
        itemSelected?.rej_rsn_code[1] &&
        parseInt(itemSelected?.rej_rsn_code[0]) ===
          parseInt(itemSelected?.rej_rsn_code[1])) ||
      !itemSelected?.rej_rsn_code[0] ||
      (!itemSelected?.rej_rsn_code[1] && newReasonNeeded);

    if (errorExist) return true;
    else return false;
  };

  const removeReasons = () => {
    setItemSelected({
      ...itemSelected,
      rejected_qty: [itemSelected?.rejected_qty[0]],
      rej_rsn_code: [itemSelected?.rej_rsn_code[0]],
      rej_rsn_desc: [findReasonDesc(itemSelected?.rej_rsn_code[0])],
    });
  };

  const AddReasonPreview = (data) => (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        <div className="reason-text">Add Reasons</div>
        {!itemSelected?.itemIsAdded && (
          <img
            className="add-icon"
            src={
              newReasonNeeded || data?.rej_rsn_code?.length > 1
                ? RemoveIcon
                : AddIcon
            }
            alt="add"
            onClick={() => {
              setNewReasonNeeded(!newReasonNeeded);
              if (newReasonNeeded || data?.rej_rsn_code?.length > 1)
                removeReasons();
            }}
          />
        )}
      </div>

      <div className="d-flex flex-column">
        <div className="align-items-center d-flex mt-4">
          <div className="text-nowrap">Rejection 1 Qty</div>
          <input
            type="number"
            className={[
              "form-control ms-5 w-75",
              !itemSelected?.rejected_qty[0] && isReasonsError && "border-red",
            ].join(" ")}
            placeholder={`Rejection 1 Qty`}
            value={itemSelected?.rejected_qty[0]}
            onChange={(e) => {
              setItemSelected({
                ...itemSelected,
                rejected_qty:
                  itemSelected?.rejected_qty[1] > -1
                    ? [
                        parseInt(e.target.value) || 0,
                        itemSelected?.rejected_qty[1],
                      ]
                    : [parseInt(e.target.value) || 0],
              });
              resetSubmit();
            }}
          />
        </div>
        <div className="align-items-center d-flex mt-4">
          <div className="text-nowrap">Reason 1</div>
          <ReasonDropdown
            reasonIndex={0}
            value={itemSelected?.rej_rsn_code[0]}
          />
        </div>
      </div>
      {newReasonNeeded && (
        <div className="d-flex flex-column">
          <div className="align-items-center d-flex mt-4">
            <div className="text-nowrap">Rejection 2 Qty</div>
            <input
              type="number"
              className={[
                "form-control ms-5 w-75",
                !itemSelected?.rejected_qty[1] &&
                  isReasonsError &&
                  "border-red",
              ].join(" ")}
              placeholder={`Rejection 2 Qty`}
              value={itemSelected?.rejected_qty[1]}
              onChange={(e) => {
                setItemSelected({
                  ...itemSelected,
                  rejected_qty:
                    itemSelected?.rejected_qty[0] > -1
                      ? [
                          itemSelected?.rejected_qty[0],
                          parseInt(e.target.value) || 0,
                        ]
                      : [parseInt(e.target.value) || 0],
                });
                resetSubmit();
              }}
            />
          </div>
          <div className="align-items-center d-flex mt-4">
            <div className="text-nowrap">Reason 2</div>
            <ReasonDropdown
              reasonIndex={1}
              value={itemSelected?.rej_rsn_code[1]}
            />
          </div>
        </div>
      )}
      {isReasonsError && (
        <div
          className={["up-error mt-3", isReasonsError && "text-wrap"].join(" ")}
        >
          Missing content in fields or duplicate reason selected .Please check
          entered data!!!
        </div>
      )}
      <div className="d-flex justify-content-center">
        <button className="btn btn-warning mt-4" onClick={onSaveReasons}>
          Save
        </button>
      </div>
    </div>
  );

  const ReasonDropdown = ({ reasonIndex, value }) => (
    <select
      className={[
        "table-drop form-control w-75 ms-88",
        ((reasonIndex === 0 && !itemSelected?.rej_rsn_code[0]) ||
          (reasonIndex === 1 && !itemSelected?.rej_rsn_code[1]) ||
          itemSelected?.rej_rsn_code[0] === itemSelected?.rej_rsn_code[1]) &&
          isReasonsError &&
          "border-red",
      ].join(" ")}
      name="reasons"
      value={value}
      placeholder={`Select reason ${reasonIndex}`}
      onChange={(e) => onAddItemReasons(e.target.value, reasonIndex)}
    >
      <option selected value="">
        Select reason
      </option>
      {reasonCodes?.map((reason, index) => (
        <option key={index} value={reason.code}>
          {`${reason.code} - ${reason.desc}`}
        </option>
      ))}
    </select>
  );

  return (
    <>
      <div className="cv-table cv d-flex flex-column">
        <div className="content-table">
          <table className="table">
            <thead>
              <tr>
                <th>Item Id</th>
                <th>Item Description</th>
                <th>UOM</th>
                <th>Pack Size</th>
                <th className="th-input">Reason</th>
                <th>Expected Qty</th>
                <th className="th-input">Invoice Qty</th>
                {!isManualGDN && <th>GKM Issue</th>}
                <th className="th-input">Item InvAmt</th>
                <th>MRP</th>
                <th>Received</th>
                <th>Rejected</th>
                <th>Mfg Date</th>
              </tr>
            </thead>
            <tbody>
              {gdnData?.map((gdn, i) => (
                <tr key={i}>
                  <td>{gdn?.item || "-"}</td>
                  <td>{gdn?.item_desc || "-"}</td>
                  <td>{gdn?.uom || "-"}</td>
                  <td>{gdn?.sps || 0}</td>
                  <td>
                    <button
                      className={[
                        "btn btn-proceed",
                        errorReasonItem?.item === gdn?.item && "bg-red",
                      ].join(" ")}
                      onClick={() => {
                        setItemSelected(gdn);
                        setitemIndex(i);
                        reasonModalOpenRef.current.click();
                        setAddReasonShow(true);
                      }}
                    >
                      Add reason
                    </button>
                  </td>
                  <td>{gdn?.expected || 0}</td>
                  <td>
                    <input
                      className={[
                        "table-input form-control",
                        isError && gdn?.invoice_qty < 0 && "border-red",
                        errorReasonItem?.item === gdn?.item && "border-red",
                      ].join(" ")}
                      onChange={(e) =>
                        onChangeReason(e.target.value, i, "inv_qty")
                      }
                      type="number"
                      placeholder="Enter invoice qty"
                      value={gdn?.invoice_qty}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Please enter value greater than zero and invoice should be greater than rejected qty"
                    />
                  </td>
                  {!isManualGDN && (
                    <td>
                      <div className="d-flex justify-content-start">
                        <input
                          type="checkbox"
                          className="mt-2"
                          onChange={(e) =>
                            onChangeReason(e.target.checked, i, "gkm_flag")
                          }
                          disabled={gdn?.itemIsAdded && !gdn?.itemAlreadyInPO}
                          checked={gdn?.gkm_flag}
                        />
                      </div>
                    </td>
                  )}
                  <td>
                    {(gdn?.gkm_flag ||
                      (gdn?.itemIsAdded && !gdn?.itemAlreadyInPO)) &&
                    !isManualGDN ? (
                      <input
                        type={"number"}
                        value={gdn?.item_inv_amt}
                        className="form-control"
                        onChange={(e) =>
                          onChangeReason(e.target.value, i, "inv_amt")
                        }
                      />
                    ) : (
                      Number(gdn?.item_inv_amt) || 0
                    )}
                  </td>
                  <td>{gdn?.mrp || 0}</td>
                  <td>{gdn?.received || 0}</td>
                  <td>
                    {(gdn?.invoice_qty &&
                      Math.abs(
                        (gdn?.invoice_qty || 0) - (gdn?.received || 0)
                      )) ||
                      0}
                  </td>
                  <td>{gdn?.mfg_date || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!gdnData?.length && (
            <div className="d-flex justify-content-center no-records">
              No GDN items found
            </div>
          )}
        </div>
        <div className="d-flex mt-auto mb-3">
          <div className="d-flex ms-auto">
            <button
              className="btn btn-save me-3 d-flex align-items-center"
              onClick={saveGdn}
              disabled={isSaveLoading || !gdnData?.length}
            >
              <div>Save</div>
              {isSaveLoading && <div className="spinner ms-2"></div>}
            </button>
            <button
              className="btn btn-submit"
              onClick={onSubmitGdn}
              disabled={!enableGdnSubmit}
            >
              Submit GDN
            </button>
          </div>
        </div>
        <CommonModal
          modalCloseRef={reasonModalCloseRef}
          modalOpenRef={reasonModalOpenRef}
          id={"reasonmodal"}
          content={AddReasonPreview(itemSelected)}
          modalSize={"md"}
          header={true}
          headerBorder={false}
          onModalClose={() => {
            setNewReasonNeeded(false);
            setAddReasonShow(false);
            setIsReasonsError(false);
          }}
        />
      </div>
    </>
  );
};
export default GdnContentView;
