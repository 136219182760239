import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getNftBinSummaryChange } from "../../api";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import DownloadExcel from "../../containers/download_excel";
import ErrorModal from "../../containers/error_modal";
import moment from "moment";
import "./summary.css";

const NftBinSummary = ({ ...props }) => {
  const { loc, locationName, widthIsSmall } = props;
  const [nftBinSummaryList, setNftBinSummaryList] = useState([]);
  const [lambdaError, setLambdaError] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const openErrRef = useRef();
  const closeErrRef = useRef();

  useEffect(() => {
    getNftBinSummaryData();
  }, []);

  const getNftBinSummaryData = async () => {
    try {
      setNftBinSummaryList([]);
      setIsListLoading(true);
      let payload = {
        loc: loc,
      };
      const resData = await getNftBinSummaryChange(payload);

      if (resData) {
        if (resData?.status === "error") {
          setLambdaError(resData?.error_message);
          openErrRef.current.click();
        } else {
          if (Object.keys(resData?.body)?.length) {
            let formattedData = resData?.body?.map((bin) => {
              return {
                bin: bin?.bin,
                zone: bin?.zone,
                item: bin?.item,
                item_desc: bin?.item_desc,
                bin_soh: bin?.soh,
                loc_soh: bin?.loc_soh,
                update_date: bin?.update_date,
              };
            });
            setNftBinSummaryList(formattedData);
          }
        }
        setIsListLoading(false);
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsListLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsListLoading(false);
    }
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="nbs-summary d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          headerText={"NFT-BIN Summary"}
        />
        <div className="store-table d-flex flex-column">
          <table className="table">
            <thead>
              <tr>
                <th>Bin</th>
                <th>Zone</th>
                <th>Item</th>
                <th>Item Description</th>
                <th>BIN SOH</th>
                <th>LOC SOH</th>
                <th>Update Date</th>
              </tr>
            </thead>
            <tbody>
              {nftBinSummaryList?.map((bin, i) => (
                <tr key={i}>
                  <td>{bin?.bin}</td>
                  <td>{bin?.zone}</td>
                  <td>{bin?.item}</td>
                  <td>{bin?.item_desc}</td>
                  <td>{bin?.bin_soh || "-"}</td>
                  <td>{bin?.loc_soh || "-"}</td>
                  <td>{bin?.update_date || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {isListLoading && (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "5%" }}
            >
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {!nftBinSummaryList?.length && !isListLoading && (
            <div className="d-flex justify-content-center no-records">
              No records found
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center">
          <DownloadExcel
            data={nftBinSummaryList}
            fileName={`nft-bin_summary_ ${loc}_${moment(new Date()).format(
              "YYYY-MM-DD"
            )}`}
            buttonName={`Export NFT-BIN Summary`}
            margin={"0px 30px 20px"}
          />
        </div>
        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NftBinSummary);
