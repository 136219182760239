import axios from "axios";
import Package from "../../package.json";
import { post } from "./networkUtils";
import {
  ADMIN_UAT_URL,
  BAY_LIST_URL,
  DOC_VERIFICATION_URL,
  GDN_STAGE_INSERT_URL,
  GDN_SUBMITTED_REPORT_URL,
  GDN_UAT_URL,
  GRN_LIST_URL,
  GRN_SUBMITTED_REPORT_URL,
  GRN_UAT_URL,
  GRN_WEB_URL,
  LOGIN_URL,
  MKT_DOWNLOAD_TEMPLATE_URL,
  MKT_SUMMARY_URL,
  OPEN_ORDERS_REPORT_URL,
  PENDING_UPLOAD_INVOICE_URL,
  PO_REFRESH_URL,
  RG_CHECK_URL,
  RG_TOT_URL,
  SHIPPING_PENDENCY_URL,
  COMMON_REPORT_URL,
  STORE_MAPPING_SUMMARY_URL,
  STORE_MAPPING_UPDATE_URL,
  TEXTRACT_URL,
  TO_GRN_URL,
  UPLOAD_RTV_URL,
  UPLOAD_WRITEOFF_URL,
  UPLOAD_GOOD_BUCKET,
  LPN_SUMMARY_URL,
  COMMON_EXTRACT_URL,
  BIN_DETAILS_URL,
  GENERATE_QR_CODES_URL,
  COMMON_CHECK_IN_URL,
  GRN_DASHBOARD_URL,
  APK_UPLOAD,
  LPN_AUDIT_URL,
  ACK_COPY_UPLOAD_URL,
} from "../constants/constants";
const preUrl = "https://";
export const versionString = `Version: ${Package.version} ${process.env.REACT_APP_STAGE}`;

/**
 * Auth Lambdas
 **/
export const loginChange = async (payload) => {
  const result = await post(preUrl + LOGIN_URL, payload, false, false);
  return result;
};

/**
 * GRN Lambdas
 **/
export const grnListChange = async (payload) => {
  const result = await post(
    preUrl + GRN_LIST_URL + "/grn_list",
    payload,
    true,
    false
  );
  return result;
};

export const grnWebChange = async (payload) => {
  const result = await post(
    preUrl + GRN_WEB_URL + "/grn_web",
    payload,
    true,
    false
  );
  return result;
};

export const rgCheckChange = async (payload) => {
  const result = await post(
    preUrl + RG_CHECK_URL + "/rg_check",
    payload,
    true,
    false
  );
  return result;
};

export const grnUatChange = async (payload) => {
  const result = await post(preUrl + GRN_UAT_URL + "/", payload, true, false);
  return result;
};

export const gdnUatChange = async (payload) => {
  const result = await post(preUrl + GDN_UAT_URL + "/", payload, true, false);
  return result;
};

export const amendMrpSubmitChange = async (payload) => {
  const result = await post(
    preUrl + PO_REFRESH_URL + "/",
    payload,
    true,
    false
  );
  return result;
};

export const toGrnChange = async (payload) => {
  const result = await post(preUrl + TO_GRN_URL + "/", payload, true, false);
  return result;
};

export const sendMailChange = async (payload) => {
  const result = await post(
    preUrl + RG_TOT_URL + "/rg_tot",
    payload,
    true,
    false
  );
  return result;
};

/**
 * GDN Lambdas
 **/
export const saveGdnChange = async (payload) => {
  const result = await post(
    preUrl + GDN_STAGE_INSERT_URL + "/gdn_stage_insert",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Dashboard Lambdas
 **/

export const grnDashboardChange = async (payload) => {
  const result = await post(
    preUrl + GRN_DASHBOARD_URL + "/grn_dashboard",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Upload Invoice Lambdas
 **/

export const docVerificationChange = async (payload) => {
  const result = await post(
    preUrl + DOC_VERIFICATION_URL + "/doc_verification",
    payload,
    true,
    false
  );
  return result;
};

export const filePresignedUrlChange = async (payload) => {
  const result = await post(
    preUrl + TEXTRACT_URL + "/get_pre_url",
    payload,
    true,
    true
  );
  return result;
};

export const ackCopyUpload = async (payload) => {
  console.log("payload at@@", payload)

  const result = await post(
    preUrl + ACK_COPY_UPLOAD_URL + "/ack_copy_upload",
    payload,
    true,
    false
  );
  return result;
}

export const textractChange = async (payload) => {
  const result = await post(
    preUrl + TEXTRACT_URL + "/textract",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Store Mapping Lambdas
 **/
export const getLocBayListChange = async (payload) => {
  const result = await post(
    preUrl + BAY_LIST_URL + "/bay_list",
    payload,
    true,
    false
  );
  return result;
};

export const storeMappingSummaryChange = async (payload) => {
  const result = await post(
    preUrl + STORE_MAPPING_SUMMARY_URL + "/store_mapping_summary",
    payload,
    true,
    false
  );
  return result;
};

export const uploadStoreMappingChange = async (payload) => {
  const result = await post(
    preUrl + STORE_MAPPING_UPDATE_URL + "/store_mapping_update",
    payload,
    true,
    false
  );
  return result;
};

/**
 * LPN Summary Lambdas
 **/

export const unloadLpnSummaryChange = async (payload) => {
  const result = await post(
    preUrl + LPN_SUMMARY_URL + "/unloaded_lpn_summary",
    payload,
    true,
    false
  );
  return result;
};

/**
 * NFT Bin Lambdas
 **/

export const generateQrCodesChange = async (payload) => {
  const result = await post(
    preUrl + GENERATE_QR_CODES_URL + "/generate_qrcodes",
    payload,
    true,
    false
  );
  return result;
};

export const getBinDetailsChange = async (payload) => {
  const result = await post(
    preUrl + BIN_DETAILS_URL + "/get_bin_details",
    payload,
    true,
    false
  );
  return result;
};

export const getNftBinSummaryChange = async (payload) => {
  const result = await post(
    preUrl + BIN_DETAILS_URL + "/get_summary",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Extraction Lambdas
 **/
export const pendingToListChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_EXTRACT_URL + "/pending_to",
    payload,
    true,
    false
  );
  return result;
};

export const extractListChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_EXTRACT_URL + "/extract",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Merge TO Lambdas
 **/
export const childToListChange = async (payload) => {
  const result = await post(
    preUrl + BIN_DETAILS_URL + "/child_to",
    payload,
    true,
    false
  );
  return result;
};

export const mergeToListChange = async (payload) => {
  const result = await post(
    preUrl + BIN_DETAILS_URL + "/merge_to",
    payload,
    true,
    false
  );
  return result;
};

export const getMergeToSummaryChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_REPORT_URL + "/merge_to_report",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Markout Lambdas
 **/

export const downloadMktTemplateChange = async (payload) => {
  const result = await post(
    preUrl + MKT_DOWNLOAD_TEMPLATE_URL + "/download_template",
    payload,
    true,
    false
  );
  return result;
};

export const mktSummaryChange = async (payload) => {
  const result = await post(
    preUrl + MKT_SUMMARY_URL + "/mkt_summary",
    payload,
    true,
    false
  );
  return result;
};

export const uploadRtvChange = async (payload) => {
  const result = await post(
    preUrl + UPLOAD_RTV_URL + "/rtv_update",
    payload,
    true,
    false
  );
  return result;
};

export const uploadWriteoffChange = async (payload) => {
  const result = await post(
    preUrl + UPLOAD_WRITEOFF_URL + "/",
    payload,
    true,
    false
  );
  return result;
};

export const uploadGoodBucketChange = async (payload) => {
  const result = await post(
    preUrl + UPLOAD_GOOD_BUCKET + "/move_to_good_bucket",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Admin console Lambdas
 **/

export const adminConsoleChange = async (payload) => {
  const result = await post(
    preUrl + ADMIN_UAT_URL + "/grn",
    payload,
    true,
    false
  );
  return result;
};

/**
 * Reports Lambdas
 **/

export const pendingUploadSummaryChange = async (payload) => {
  const result = await post(
    preUrl + PENDING_UPLOAD_INVOICE_URL + "/pending_upload_invoice",
    payload,
    true,
    false
  );
  return result;
};

export const submittedGrnSummaryChange = async (payload) => {
  const result = await post(
    preUrl + GRN_SUBMITTED_REPORT_URL + "/grn_submitted_report",
    payload,
    true,
    false
  );
  return result;
};

export const submittedGdnSummaryChange = async (payload) => {
  const result = await post(
    preUrl + GDN_SUBMITTED_REPORT_URL + "/gdn_submitted_report",
    payload,
    true,
    false
  );
  return result;
};

export const openPoSummaryChange = async (payload) => {
  const result = await post(
    preUrl + OPEN_ORDERS_REPORT_URL + "/open_orders_report",
    payload,
    true,
    false
  );
  return result;
};

export const pickingPendenciesChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_REPORT_URL + "/picking_pendency_report",
    payload,
    true,
    false
  );
  return result;
};

export const nftL1PickingPendencyChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_REPORT_URL + "/nft_l1_pendency_report",
    payload,
    true,
    false
  );
  return result;
};

export const pickingCompletedChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_REPORT_URL + "/picking_completed_report",
    payload,
    true,
    false
  );
  return result;
};

export const nftL1PickingCompletedChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_REPORT_URL + "/nft_l1_complete_report",
    payload,
    true,
    false
  );
  return result;
};

export const shippingPendenciesChange = async (payload) => {
  const result = await post(
    preUrl + SHIPPING_PENDENCY_URL + "/shipping_pendency_report",
    payload,
    true,
    false
  );
  return result;
};

export const unloadPendenciesChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_REPORT_URL + "/unload_pendency_report",
    payload,
    true,
    false
  );
  return result;
};

export const checkInPendencyChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_CHECK_IN_URL + "/checkin_pendency_report",
    payload,
    true,
    false
  );
  return result;
};

export const checkInCompletedChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_CHECK_IN_URL + "/checkin_completed_report",
    payload,
    true,
    false
  );
  return result;
};

export const extractionSummaryChange = async (payload) => {
  const result = await post(
    preUrl + COMMON_CHECK_IN_URL + "/extraction_summary_report",
    payload,
    true,
    false
  );
  return result;
};

/**
 * APK uploader Lambdas
 **/

export const getPresignedUrl = async (payload) => {
  return axios.post(APK_UPLOAD + "v1/upload_version", "", {
    params: payload,
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const uploadFile = async (URL, data) => {
  return axios.post(URL, data);
};

export const listAppIds = async () => {
  return axios.get(APK_UPLOAD + "v1/list_app_ids");
};

export const listAppVersions = async (payload) => {
  return axios.get(APK_UPLOAD + "v1/list_all_versions", {
    params: payload,
  });
};

export const downloadAPK = async (appId, version, loc) => {
  return axios.get(APK_UPLOAD + `v1/download/${appId}/${version}/${loc}`);
};

export const latestVersion = async (appID, loc) => {
  return axios.get(
    APK_UPLOAD + `v1/get_latest_version?app_id=${appID}&loc=${loc}`
  );
};

export const storeList = async (payload) => {
  const result = await post(
    preUrl + LOGIN_URL + "/get_store_codes",
    payload,
    true,
    false
  );
  return result;
};

export const locList = async (payload) => {
  const result = await post(
    preUrl + LOGIN_URL + "/get_dc_codes",
    payload,
    true,
    false
  );
  return result;
};
/**
 * LPN Audit Lambdas
 */
export const getLpnAuditList = async (payload) => {
  const result = await post(
    preUrl + LPN_AUDIT_URL + "/sample_lpn",
    payload,
    true,
    false
  );
  return result;
};

export const uploadLpnAuditList = async (payload) => {
  const result = await post(
    preUrl + LPN_AUDIT_URL + "/lpn_audit",
    payload,
    true,
    false
  );
  return result;
};