import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../store/auth";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { loginChange } from "../../api";
import { FORGOT_PASSWORD, userRoles } from "../../constants/constants";
import { checkValidTime } from "../../utils/checkTime";
import { ToastContainer, toast } from "react-toastify";
import LogoIcon from "../../assets/images/logo.png";
import HideIcon from "../../assets/icons/hide.svg";
import ShowIcon from "../../assets/icons/show.svg";
import "./login.css";

const Login = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [viewPassWord, setViewPassWord] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { dispatchLogin, widthIsSmall } = props;

  const onLogin = async (data) => {
    try {
      setIsLoading(true);
      setIsError("");

      const userLogin = await loginChange({
        username: data?.username?.toLowerCase(),
        password: data?.password,
      });

      if (userLogin?.status === "success") {
        const checkIfInvalidTime = checkValidTime();
        const primaryRoleExist = userLogin?.body?.roles?.find(
          (role) => role.type === "primary"
        );

        if (
          checkIfInvalidTime &&
          primaryRoleExist &&
          primaryRoleExist?.role === "inward"
        ) {
          toast.error(
            "Receiving Screens for PO and TO will be unavailable everyday from 3.00 AM to 3.30 AM",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          setIsLoading(false);
          return;
        }

        const roleExist = userRoles.find(
          (user) => user.role === primaryRoleExist?.role
        );

        if (!roleExist) {
          setIsError("Unable to login. Invalid user credentials");
          setIsLoading(false);
        } else {
          setTimeout(() => {
            navigate("/home");
            setIsLoading(false);
          }, 10);
          dispatchLogin({
            loginResponse: userLogin,
            inputName: data?.username,
          });
        }
      } else {
        setIsError(userLogin?.error_message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsError("Something went wrong");
      setIsLoading(false);
    }
  };

  return (
    <div className="login">
      <div
        className="align-items-center container container-fluid d-flex flex-column login-content p-4 p-lg-0 p-md-0 p-sm-0 p-xl-0"
        style={{ width: widthIsSmall ? "100%" : "30%", marginTop: "10%" }}
      >
        <img src={LogoIcon} className="lg-logo" alt="logo" />
        <div className="lg-head">WMS+ Portal</div>
        <div className="lg-subhead">
          Please enter your details to login to your account
        </div>
        <form onSubmit={handleSubmit(onLogin)} className="lg-form">
          <div className="d-flex align-items-center">
            <input
              className={[
                "form-control log-input",
                (errors.username?.type === "required" ||
                  errors.username?.type === "minLength") &&
                "bd-red",
              ].join(" ")}
              type="text"
              placeholder="Username"
              {...register("username", { required: true, minLength: 4 })}
            />
          </div>
          <div className="error d-flex flex-column">
            {errors.username?.type === "required" && (
              <div className="ms-auto">Username is required</div>
            )}
            {errors.username?.type === "minLength" && (
              <div className="ms-auto">
                Name require a minimum of 4 characters
              </div>
            )}
          </div>
          <div className="d-flex align-items-center form-tp-group position-relative">
            <input
              className={[
                "form-control log-input",
                (errors.password?.type === "required" ||
                  errors.password?.type === "minLength") &&
                "bd-red",
              ].join(" ")}
              type={viewPassWord ? "text" : "password"}
              placeholder="Password"
              {...register("password", { required: true, minLength: 8 })}
            />
            {viewPassWord ? (
              <img
                src={ShowIcon}
                alt="hide"
                className="hide-icon"
                onClick={() => setViewPassWord(false)}
              />
            ) : (
              <img
                src={HideIcon}
                alt="hide"
                className="hide-icon"
                onClick={() => setViewPassWord(true)}
              />
            )}
          </div>
          <div className="error d-flex flex-column">
            {errors.password?.type === "required" && (
              <div className="ms-auto">Password is required</div>
            )}
            {errors.password?.type === "minLength" && (
              <div className="ms-auto">
                Password require a minimum of 8 characters
              </div>
            )}
          </div>
          {isError && (
            <div className="error-text d-flex justify-content-center mt-2 mb-2">
              {isError}
            </div>
          )}
          <button type="button" className="btn-fp" onClick={() => {
            window.open(FORGOT_PASSWORD)
          }}>
            Forgot Password?
          </button>
          <div
            className={[
              "d-flex w-100 justify-content-center",
              !isError ? "lg-btn-grp" : "mt-3",
            ].join(" ")}
          >
            <button
              className="btn btn-signin d-flex align-items-center"
              type="submit"
              disabled={isLoading}
            >
              <div>Sign In</div>
              {isLoading && <div className="spinner ms-2"></div>}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state?.auth?.isLoggedIn,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogin: (data) => dispatch(authActions.login(data)),
    checkAuthState: () => dispatch(authActions.checkauth()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
