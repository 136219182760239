import { useEffect, useState } from "react";
import RightArrow from "../../assets/icons/right-arrow.svg";
import LeftArrow from "../../assets/icons/left-arrow.svg";
import { usePagination, DOTS } from "./paginate";
import "./pagination.css";

const Pagination = ({
  data,
  dataCount,
  pageSelected,
  currentPage,
  type,
  totalRecords = 0,
  siblingCount = 1,
}) => {
  const [pageNumbers, setPageNumbers] = useState([1]);
  const paginationRange = usePagination({
    currentPage,
    totalCount: type === "paginationFromDb" ? totalRecords : data?.length,
    siblingCount,
    pageSize: 10,
  });

  useEffect(() => {
    if (data?.length || (type === "paginationFromDb" && totalRecords > 0)) {
      addPageNumbers(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, totalRecords]);

  const addPageNumbers = (data) => {
    let dataLength = 0;
    if (type === "paginationFromDb") {
      dataLength = totalRecords;
    } else {
      dataLength = data?.length;
    }
    const calculatePageCount = Math.ceil(dataLength / dataCount);
    let pageNumArr = [];
    for (let i = 0; i < calculatePageCount; i++) {
      pageNumArr.push(i);
    }
    setPageNumbers(pageNumArr);
  };

  return (
    <div className="d-flex justify-content-center">
      <button
        className="page-btn"
        onClick={() => pageSelected(currentPage - 1)}
        disabled={currentPage === 0}
      >
        <img className="page-img" src={LeftArrow} alt="left" />
      </button>
      {paginationRange?.map((page, i) => {
        if (page === DOTS) {
          return (
            <div key={i} className="pagination-item dots ms-2">
              &#8230;
            </div>
          );
        }
        return (
          <div
            key={i}
            className={[
              "ms-2 page-no",
              currentPage === (pageNumbers?.length < 8 ? page - 1 : page) &&
                "active",
            ].join(" ")}
            onClick={() => {
              pageSelected(pageNumbers?.length < 8 ? page - 1 : page);
            }}
          >
            {page}
          </div>
        );
      })}
      <button
        className="ms-2 page-btn"
        onClick={() => pageSelected(currentPage + 1)}
        disabled={currentPage === pageNumbers[pageNumbers?.length - 1]}
      >
        <img className="page-img" src={RightArrow} alt="left" />
      </button>
    </div>
  );
};
export default Pagination;
