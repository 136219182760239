import "./common_modal.css";

const CommonModal = ({
  id,
  content,
  modalCloseRef,
  modalOpenRef,
  header = false,
  footer = false,
  headerText,
  onModalClose,
  modalSize,
  headerBorder = true,
}) => {
  return (
    <>
      <div
        data-bs-toggle="modal"
        data-bs-target={`#${id}`}
        ref={modalOpenRef}
      ></div>
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          className={[
            "modal-dialog modal-dialog-centered",
            modalSize === "xl"
              ? "modal-xl"
              : modalSize === "lg"
              ? "modal-lg"
              : modalSize === "sm"
              ? "modal-sm"
              : "",
          ].join(" ")}
        >
          <div className="modal-content">
            <div
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={modalCloseRef}
            ></div>
            {header && (
              <div
                className={[
                  "modal-header pb-0 pe-3 pt-4",
                  !headerBorder && "head-border-none",
                ].join(" ")}
              >
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerText}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => onModalClose()}
                ></button>
              </div>
            )}
            <div className="modal-body">{content}</div>
            {footer && (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonModal;
