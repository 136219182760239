import { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { gdnUatChange, saveGdnChange } from "../../api";
import { grnActions } from "../../store/grn";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "../../containers/content_header";
import GdnDetails from "../../containers/gdn_details";
import GdnContentView from "../../containers/gdn_content_view";
import CommonModal from "../../containers/common_modal";
import DoubletickIcon from "../../assets/icons/doubletick.svg";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import ErrorModal from "../../containers/error_modal";
import "./gdn.css";

const Gdn = ({ ...props }) => {
  const {
    gdnData,
    supplier,
    invoiceAmount,
    invoiceDate,
    po,
    invoiceNo,
    receiptId,
    loc,
    updateGdnData,
    widthIsSmall,
    updateGdnNo,
    locationName,
    gdnNo,
    filterGdnData,
    markGdnSaveComplete,
    enableGdnSubmit,
    rgCheckDone,
    isManualGDN,
    username
  } = props;
  const navigate = useNavigate();

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isGdnError, setIsGdnError] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const [errorReasonItem, setErrorReasonItem] = useState(null);
  const [searchText, setSearchText] = useState("");

  const confirmGdnModalOpenRef = useRef();
  const confirmGdnModalCloseRef = useRef();
  const successGdnModalOpenRef = useRef();
  const successGdnModalCloseRef = useRef();
  const gdnSavedSuccessOpenRef = useRef();
  const gdnSavedSuccessCloseRef = useRef();
  const gdnClearFilterOpenRef = useRef();
  const gdnClearFilterCloseRef = useRef();
  const noGdnItemModalCloseRef = useRef();
  const noGdnItemModalOpenRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();

  useEffect(() => {
    if (!po || !invoiceNo || isManualGDN) {
      navigate("/grnlist");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [po, invoiceNo]);

  useEffect(() => {
    if (!rgCheckDone) {
      navigate("/grn");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rgCheckDone]);

  const onSaveGdn = async () => {
    try {
      if (searchText) {
        gdnClearFilterOpenRef.current.click();
        return;
      }

      setIsSaveLoading(true);
      setIsGdnError(false);
      setErrorReasonItem(null);

      const errorExist = gdnData.find((gdn) => {
        return (
          (gdn?.invoice_qty > 0 &&
            gdn?.total_rejected_qty > 0 &&
            gdn?.invoice_qty !== gdn?.received &&
            (!gdn?.rej_rsn_code?.length ||
              !gdn?.rejected_qty?.length ||
              gdn?.invoice_qty < 0)) ||
          ((gdn?.invoice_qty > 0 || gdn?.total_rejected_qty > 0) &&
            gdn?.invoice_qty !== gdn?.received &&
            (!gdn?.rej_rsn_code?.length ||
              !gdn?.rejected_qty?.length ||
              gdn?.invoice_qty < 0))
        );
      });

      console.log("IWI in GDN :", errorExist);

      if (errorExist) {
        setIsGdnError(true);
        setIsSaveLoading(false);
        setLambdaError(`Validation error : Please enter required fields`);
        openErrRef.current.click();
        return;
      }

      const rejectedQtyMismatch = gdnData.find(
        (gdn) =>
          gdn?.total_rejected_qty !==
          gdn?.rejected_qty?.reduce((a, b) => a + b, 0)
      );

      if (rejectedQtyMismatch) {
        setErrorReasonItem(rejectedQtyMismatch);
        setIsGdnError(true);
        setIsSaveLoading(false);
        setLambdaError(
          `Validation error : The added rejected qty is not matching the total rejected qty`
        );
        openErrRef.current.click();
        return;
      }

      const gdnItems = gdnData
        .map((item) => {
          return (
            (item?.total_rejected_qty > 0 ||
              (item?.total_rejected_qty === 0 &&
                (item?.rej_rsn_code[0] === 10 ||
                  item?.rej_rsn_code[1] === 10))) && {
              item: item?.item,
              rejected_qty: item?.rejected_qty || [0],
              rej_rsn_code: item?.rej_rsn_code || [0],
              item_inv_amt:
                item?.total_rejected_qty > 0
                  ? parseFloat(item?.item_inv_amt || 0)
                  : 0,
              gkm_flag: item?.gkm_flag,
            }
          );
        })
        .filter((item) => item);

      if (!gdnItems?.length) {
        confirmGdnModalCloseRef.current.click();
        noGdnItemModalOpenRef.current.click();
        setIsSaveLoading(false);
        return;
      }

      const submitGdnDetails = await saveGdnChange({
        receipt_id: receiptId,
        loc: loc,
        details: gdnItems,
        user_id: String(username),
      });

      if (submitGdnDetails?.status === "error") {
        setLambdaError(`GDN save : ${submitGdnDetails.error_message}`);
        openErrRef.current.click();
        setIsSaveLoading(false);
      } else {
        if (
          Object.keys(submitGdnDetails?.body)?.length &&
          submitGdnDetails?.body?.gdn_no
        ) {
          updateGdnNo(submitGdnDetails?.body?.gdn_no);
          setIsSaveLoading(false);
          gdnSavedSuccessOpenRef.current.click();
          markGdnSaveComplete(true);
        }
      }
    } catch (error) {
      setIsSaveLoading(false);
      console.log(error);
    }
  };

  const onGdnSubmit = async () => {
    try {
      if (searchText) {
        gdnClearFilterOpenRef.current.click();
        return;
      }

      setIsSubmitLoading(true);
      let itemDetails = gdnData
        .map((item) => {
          return (
            item?.total_rejected_qty > 0 && {
              item: item?.item,
              grn_qty: item?.received,
              rejected_qty: item?.rejected_qty || [],
              invoice_qty: item?.invoice_qty || 0,
              order_qty: item?.expected,
              order_cost: item?.unit_cost,
              order_mrp: item?.amended_mrp ? item?.amended_mrp : item?.mrp,
              invoice_mrp: 0.0,
              invoice_cost: 0.0,
              rej_rsn_code: item?.rej_rsn_code || [],
              rej_rsn_desc: item?.rej_rsn_desc || [],
            }
          );
        })
        .filter((item) => item);

      if (!itemDetails?.length) {
        confirmGdnModalCloseRef.current.click();
        noGdnItemModalOpenRef.current.click();
        setIsSubmitLoading(false);
        return;
      }

      let payload = {
        source: "wmsplus",
        gdn_no: parseInt(gdnNo) || 0,
        order_no: parseInt(po),
        invoice_no: invoiceNo,
        receipt_id: receiptId,
        loc: loc,
        supplier: parseInt(supplier),
        gdn_date: new Date(),
        create_id: "wmsplus_gdn_lambda",
        details: itemDetails,
        user_id: String(username),
      };

      const submitGdnData = await gdnUatChange(payload);
      if (submitGdnData) {
        if (submitGdnData?.status === "error") {
          setLambdaError("GDN UAT : " + submitGdnData?.error_message);
          openErrRef.current.click();
          setIsSubmitLoading(false);
        } else {
          if (Object.keys(submitGdnData?.body)?.length) {
            setIsSubmitLoading(false);
            confirmGdnModalCloseRef.current.click();
            successGdnModalOpenRef.current.click();
          }
        }
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsSubmitLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsSubmitLoading(false);
    }
  };

  const SubmitGdn = () => (
    <div className="d-flex flex-column mx-4 mt-5 align-items-center">
      <div className="submit-gdn-text">
        Are you sure you want to submit GDN?
      </div>
      <div
        className={[
          "d-flex justify-content-around w-75 mb-4",
          isError ? "mb-3" : "mb-5",
        ].join(" ")}
      >
        <button
          className="btn btn-guide mt-5"
          onClick={() => {
            confirmGdnModalCloseRef.current.click();
            setIsError(false);
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-preview mt-5 d-flex align-items-center"
          onClick={() => {
            onGdnSubmit();
          }}
          disabled={isSubmitLoading}
        >
          <div>Confirm</div>
          {isSubmitLoading && <div className="ms-2 spinner"></div>}
        </button>
      </div>
      {isError && (
        <div className="text-danger mb-2">Failed to submit GDN/GRN !!!</div>
      )}
    </div>
  );

  const GdnSubmitSuccess = () => (
    <div className="d-flex flex-column align-items-center mx-5 mt-5">
      <div className="d-flex align-items-center">
        <div className="submit-gdn-text">GDN Submitted Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <img className="tick-icon" src={DoubletickIcon} alt="tick" />
      </div>
      <button
        className="btn btn-proceed-grn my-5"
        onClick={() => {
          successGdnModalCloseRef.current.click();
          navigate("../grn");
        }}
      >
        Proceed for RG Check
      </button>
    </div>
  );

  const NoGdnItem = () => (
    <div className="d-flex flex-column align-items-center p-2">
      <div className="d-flex align-items-center">
        <div className="no-gdn-item-text">
          No GDN items to submit. Please check and resubmit again.
        </div>
      </div>
      <button
        className="btn btn-preview mt-3"
        onClick={() => {
          noGdnItemModalCloseRef.current.click();
        }}
      >
        OK
      </button>
    </div>
  );

  const ClearFilterBeforeProceed = () => (
    <div className="d-flex flex-column align-items-center p-2">
      <div className="d-flex align-items-center">
        <div className="gdn-success-text">
          Please clear search and filters before save or submit.
        </div>
      </div>
      <button
        className="btn btn-preview mt-3"
        onClick={() => {
          gdnClearFilterCloseRef.current.click();
        }}
      >
        OK
      </button>
    </div>
  );

  const GdnSavedSuccess = () => (
    <div className="align-items-center d-flex flex-column justify-content-center">
      <div className="d-flex flex-column align-items-center my-3">
        <div className="gdn-success-text">GDN details saved successfully</div>
        &nbsp;&nbsp;&nbsp;
      </div>
      <button
        className="btn btn-preview mb-3"
        onClick={() => gdnSavedSuccessCloseRef.current.click()}
      >
        OK
      </button>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="home d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header text={`${loc} - ${locationName}`} headerText={"GDN Summary"} />
        <GdnDetails
          supplier={supplier}
          invoiceAmount={invoiceAmount}
          invoiceDate={invoiceDate}
          po={po}
          invoiceNo={invoiceNo}
          receiptId={receiptId}
          filterGdnData={filterGdnData}
          widthIsSmall={widthIsSmall}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <GdnContentView
          gdnData={gdnData}
          saveGdn={onSaveGdn}
          isSaveLoading={isSaveLoading}
          onSubmitGdn={() => confirmGdnModalOpenRef.current.click()}
          isSubmitLoading={isSubmitLoading}
          onGdnUpdate={(data) => updateGdnData(data)}
          isError={isGdnError}
          gdnNo={gdnNo}
          enableGdnSubmit={enableGdnSubmit}
          resetSubmit={() => markGdnSaveComplete(false)}
          errorReasonItem={errorReasonItem}
          isManualGDN={isManualGDN}
        />

        <CommonModal
          modalCloseRef={confirmGdnModalCloseRef}
          modalOpenRef={confirmGdnModalOpenRef}
          id={"confirmGdn"}
          content={<SubmitGdn />}
        />

        <CommonModal
          modalCloseRef={successGdnModalCloseRef}
          modalOpenRef={successGdnModalOpenRef}
          id={"successGdnSubmit"}
          content={<GdnSubmitSuccess />}
        />
        <CommonModal
          modalCloseRef={noGdnItemModalCloseRef}
          modalOpenRef={noGdnItemModalOpenRef}
          id={"noGdnItemModal"}
          content={<NoGdnItem />}
          modalSize={"md"}
          header={true}
          headerBorder={false}
        />

        <CommonModal
          modalCloseRef={gdnSavedSuccessCloseRef}
          modalOpenRef={gdnSavedSuccessOpenRef}
          id={"gdnSavedSuccessModal"}
          content={<GdnSavedSuccess />}
          header={true}
          headerBorder={false}
        />

        <CommonModal
          modalCloseRef={gdnClearFilterCloseRef}
          modalOpenRef={gdnClearFilterOpenRef}
          id={"gdnClearFilterModal"}
          content={<ClearFilterBeforeProceed />}
          header={true}
          headerBorder={false}
        />

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    gdnData: state?.grn?.gdnData,
    supplier: state?.grn?.supplier,
    invoiceAmount: state?.grn?.invoiceAmount,
    invoiceDate: state?.grn?.invoiceDate,
    po: state?.grn?.po,
    invoiceNo: state?.grn?.invoiceNo,
    receiptId: state?.grn?.receiptId,
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    rgCheck: state?.grn?.rgCheck,
    gdnCheck: state?.grn?.gdnCheck,
    softallocCheck: state?.grn?.softallocCheck,
    gdnNo: state?.grn?.gdnNo,
    enableGdnSubmit: state?.grn?.enableGdnSubmit,
    widthIsSmall: state?.auth?.widthIsSmall,
    rgCheckDone: state?.grn?.rgCheckDone,
    isManualGDN: state?.grn?.isManualGDN,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGdnData: (data) => dispatch(grnActions.updateGdnData(data)),
    updateGdnNo: (data) => dispatch(grnActions.updateGdnNo(data)),
    filterGdnData: (data) => dispatch(grnActions.filterGdnData(data)),
    markGdnSaveComplete: (data) =>
      dispatch(grnActions.markGdnSaveComplete(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gdn);
