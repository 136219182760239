import "./submit_form.css";
import BackIcon from "../../assets/icons/back-arrow.svg";

const SubmitForm = ({
  supplier,
  invoiceAmount,
  invoiceDate,
  po,
  invoiceNo,
  widthIsSmall,
  amendMrpItems,
  grnData,
  backButton = false,
  onBackClick,
  skuCount,
}) => {
  const getReceivedCount = (data) => {
    return data?.filter((item) => item?.lpn).length;
  };

  return (
    <div className="d-flex flex-column">
      {(backButton && (
        <button
          className="btn btn-proceed me-auto d-flex align-items-center mb-2 ms-3"
          onClick={() => {
            onBackClick();
          }}
        >
          <img className="back-icon me-2" src={BackIcon} alt="back" />
          <div>Back</div>
        </button>
      )) ||
        ""}
      {widthIsSmall ? (
        <div className="submit-form d-flex flex-column">
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Order No</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{po || "NA"}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Invoice/DC No</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{invoiceNo || "NA"}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Supplier</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{supplier || "NA"}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Invoice Date</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{invoiceDate || "NA"}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Invoice Amount</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{invoiceAmount || 0}
            </div>
          </div>
          <div className="d-flex flex-column mt-4 ms-auto">
            {(amendMrpItems?.length && (
              <div className="text-nowrap amend-text">{`(Amended MRP ${amendMrpItems?.length}/${grnData?.length})`}</div>
            )) ||
              ""}
            <div className="text-nowrap amend-text text-primary">{`Items Received : ${
              getReceivedCount(grnData) || 0
            }/${grnData?.length || 0}`}</div>
          </div>
        </div>
      ) : (
        <div className="submit-form d-flex">
          <div className="d-flex w-25">
            <div className="d-flex flex-column me-5">
              <label className="sf-response-label mb-4">Order No</label>
              <label className="sf-response-label mb-4">Invoice/DC No</label>
            </div>
            <div className="d-flex flex-column">
              <div className="sf-response-data mb-4">
                :&nbsp;&nbsp;{po || "NA"}
              </div>
              <div className="sf-response-data mb-4">
                :&nbsp;&nbsp;{invoiceNo || "NA"}
              </div>
            </div>
          </div>
          <div className="d-flex w-75 ms-5">
            <div className="d-flex flex-column me-5">
              <label className="sf-response-label mb-4">Supplier</label>
              <label className="sf-response-label mb-4">Invoice Date</label>
              <label className="sf-response-label">Invoice Amount</label>
            </div>
            <div className="d-flex flex-column">
              <div className="sf-response-data mb-4">
                :&nbsp;&nbsp;{supplier || "NA"}
              </div>
              <div className="sf-response-data mb-4">
                :&nbsp;&nbsp;{invoiceDate || "NA"}
              </div>
              <div className="sf-response-data">
                :&nbsp;&nbsp;{invoiceAmount || 0}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-auto">
            {(amendMrpItems?.length && (
              <div className="text-nowrap amend-text mb-2">{`(Amended MRP ${
                amendMrpItems?.length || 0
              }/${grnData?.length || 0})`}</div>
            )) ||
              ""}
            <div className="text-nowrap amend-text text-primary">{`Items Received : ${
              getReceivedCount(grnData) || 0
            }/${grnData?.length || 0}`}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SubmitForm;
