import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { authActions } from "../store/auth";
import Login from "../components/login";
import HomePage from "../components/homepage";
import Grn from "../components/grn";
import GrnList from "../components/grn_list";
import GDN from "../components/gdn";
import GrnDashboard from "../components/grn_dashboard";
import AddItems from "../containers/add_items";
import LPN from "../components/lpn";
// import PrintLpn from "../components/print/print_lpn";
// import PrintBarcodes from "../components/print_barcodes";
// import PrintConnect from "../components/print_connect";
import UploadInvoice from "../components/upload_invoice";
import MktSummary from "../components/mkt_summary";
import AdminConsole from "../components/admin_console";
import StoreMappingTub from "../components/store_mapping_tub";
import StoreMappingBayArea from "../components/store_mapping_bay_area";
import PendingUploads from "../components/reports/pending_uploads";
import GrnSubmiited from "../components/reports/grn_submitted";
import GdnSubmiited from "../components/reports/gdn_submitted";
import OpenPo from "../components/reports/open_po";
import ShippingPendency from "../components/reports/shipping_pendency";
import FTPickingPendency from "../components/reports/picking_pendency/ft_picking";
import PutawayPickingPendency from "../components/reports/picking_pendency/putaway_picking";
import OrangeLPNPickingPendency from "../components/reports/picking_pendency/orange_lpn_picking";
import NftL1PickingPendency from "../components/reports/picking_pendency/nft_l1_picking";
import UnloadPendency from "../components/reports/unload_pendency";
import FTPickingCompleted from "../components/reports/picking_completed/ft_picking_completed";
import PutawayPickingCompleted from "../components/reports/picking_completed/putaway_picking_completed";
import OrangeLPNPickingCompleted from "../components/reports/picking_completed/orange_lpn_picking_completed";
import NftL1PickingCompleted from "../components/reports/picking_completed/nft_l1_picking_completed";
import CheckInCompleted from "../components/reports/check_in_completed";
import CheckInPendency from "../components/reports/check_in_pendency";
import ExtractionSummary from "../components/reports/extraction_summary";
import ManualGDN from "../components/manual_gdn";
import RgPortal from "../components/rg_portal";
import APKUploader from "../components/apk_uploader";
import Extract from "../components/extract";
import NftBinMapping from "../components/nft_bin_mapping";
import NftBinSummary from "../components/nft_bin_summary";
import PageNotFound from "../containers/page_not_found";
import PrivateRoute from "./private_route";
import Profile from "../components/profile";
import MergeTO from "../components/merge_to";
import MergeTOSummary from "../components/merge_to_summary";
import LpnAudit from "../components/lpn_audit";
import AckUpload from "../components/ack_upload";

const RootRoute = ({ ...props }) => {
  const { isLoggedIn, checkAuthState, updateScreenWidth, loc } = props;

  useEffect(() => {
    if (!isLoggedIn) {
      checkAuthState();
    }
    findScreenWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        updateScreenWidth(true);
      } else {
        updateScreenWidth(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const findScreenWidth = () => {
    if (window.innerWidth < 768) {
      updateScreenWidth(true);
    } else {
      updateScreenWidth(false);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/not-found" element={<PageNotFound />} />
            <Route
              path="/lpn-audit"
              element={
                <PrivateRoute>
                  <LpnAudit type={"lpn-audit"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/nft-bin-summary"
              element={
                <PrivateRoute>
                  <NftBinSummary type={"nft-bin"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/nft-bin-mapping"
              element={
                <PrivateRoute>
                  <NftBinMapping type={"nft-bin"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/merge-to"
              element={
                <PrivateRoute>
                  <MergeTO type={"merge-to"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/merge-to-summary"
              element={
                <PrivateRoute>
                  <MergeTOSummary type={"merge-to"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/extract"
              element={
                <PrivateRoute>
                  <Extract type={"extract"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/manual-gdn"
              element={
                <PrivateRoute>
                  <ManualGDN type={"grn"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/rg-portal"
              element={
                <PrivateRoute>
                  <RgPortal type={"rg"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ack-upload"
              element={
                <PrivateRoute>
                  <AckUpload type={"ack"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/apk-upload"
              element={
                <PrivateRoute>
                  <APKUploader type={"wms_internal"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/data-purge"
              element={
                <PrivateRoute>
                  <AdminConsole type={"admin"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/mkt"
              element={
                <PrivateRoute>
                  <MktSummary type={"mkt"} />
                </PrivateRoute>
              }
            />

            {/* <Route path="/print-connect" element={<PrintConnect />} /> */}
            {/* <Route path="/print-lpn" element={<PrintBarcodes />} /> */}
            {/* <Route path="/print-lpn" element={<PrintLpn />} /> */}

            <Route
              path="/report-extraction-summary"
              element={
                <PrivateRoute>
                  <ExtractionSummary type={"reports-outbound"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-checkincomplete"
              element={
                <PrivateRoute>
                  <CheckInCompleted type={"reports-outbound"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-checkinpendency"
              element={
                <PrivateRoute>
                  <CheckInPendency type={"reports-outbound"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-unload"
              element={
                <PrivateRoute>
                  <UnloadPendency type={"reports-outbound"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-shipping"
              element={
                <PrivateRoute>
                  <ShippingPendency type={"reports-outbound"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/pc-report-nftl1"
              element={
                <PrivateRoute>
                  <NftL1PickingCompleted type={"reports-multi"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/pc-report-orangelpn"
              element={
                <PrivateRoute>
                  <OrangeLPNPickingCompleted type={"reports-multi"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/pc-report-putaway"
              element={
                <PrivateRoute>
                  <PutawayPickingCompleted type={"reports-multi"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/pc-report-ftpickcomplete"
              element={
                <PrivateRoute>
                  <FTPickingCompleted type={"reports-multi"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/pp-report-nftl1"
              element={
                <PrivateRoute>
                  <NftL1PickingPendency type={"reports-multi"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/pp-report-orangelpn"
              element={
                <PrivateRoute>
                  <OrangeLPNPickingPendency type={"reports-multi"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/pp-report-putaway"
              element={
                <PrivateRoute>
                  <PutawayPickingPendency type={"reports-multi"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/pp-report-ftpicking"
              element={
                <PrivateRoute>
                  <FTPickingPendency type={"reports-multi"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/report-po"
              element={
                <PrivateRoute>
                  <OpenPo type={"reports-inbound"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-gdn"
              element={
                <PrivateRoute>
                  <GdnSubmiited type={"reports-inbound"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-grn"
              element={
                <PrivateRoute>
                  <GrnSubmiited type={"reports-inbound"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-upload"
              element={
                <PrivateRoute>
                  <PendingUploads type={"reports-inbound"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/map-bayarea"
              element={
                <PrivateRoute>
                  <StoreMappingBayArea type={"store"} />
                </PrivateRoute>
              }
            />
            <Route
              path="/map-tub"
              type={"store"}
              element={
                <PrivateRoute>
                  <StoreMappingTub type={"store"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/upload"
              element={
                <PrivateRoute>
                  <UploadInvoice type={"grn"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/lpn"
              element={
                <PrivateRoute>
                  <LPN type={"lpn"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/add-items"
              element={
                <PrivateRoute>
                  <AddItems type={"grn"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/grndash"
              element={
                <PrivateRoute>
                  <GrnDashboard type={"dashboard"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/grnList"
              element={
                <PrivateRoute>
                  <GrnList type={"grn"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/gdn"
              element={
                <PrivateRoute>
                  <GDN type={"grn"} />
                </PrivateRoute>
              }
            />

            <Route
              path="/grn"
              element={
                <PrivateRoute>
                  <Grn type={"grn"} />
                </PrivateRoute>
              }
            />

            <Route path="/profile" element={<Profile />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="*" element={<Navigate to="/home" />} />
            {/* <Route path="*" element={<Navigate to="/not-found" />} /> */}
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state?.auth?.isLoggedIn,
    loc: state?.auth?.user?.loc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthState: () => dispatch(authActions.checkauth()),
    updateScreenWidth: (data) => dispatch(authActions.updateScreenWidth(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootRoute);
