import Header from "../../containers/header";
import Navbar from "../../containers/navbar";
import HomeIcon from "../../assets/images/homeimg.png";
import { connect } from "react-redux";
import "./homepage.css";

const HomePage = ({ ...props }) => {
  const { widthIsSmall } = props;

  return (
    <div className="d-flex flex-column homepage">
      <Header />
      <Navbar />
      <div className="d-flex flex-column align-items-center">
        <div className="align-items-center d-flex flex-column flex-lg-row flex-md-row flex-xl-row wms-header">
          <div className="homepage-text">Welcome to</div>
          <div className="wms-text">&nbsp;RogueOne</div>
        </div>
        <img
          className="home-icon"
          src={HomeIcon}
          alt="homeimage"
          style={{ width: widthIsSmall ? "75%" : "50%" }}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
