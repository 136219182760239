import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { storeMappingSummaryChange, uploadStoreMappingChange } from "../../api";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import Navbar from "../../containers/navbar";
import StoreMappingContents from "../../containers/store_mapping_contents";
import ErrorModal from "../../containers/error_modal";
import CommonModal from "../../containers/common_modal";
import DownloadExcel from "../../containers/download_excel";
import moment from "moment";
import "./bayarea.css";

const StoreMappingBayArea = ({ ...props }) => {
  const { loc, locationName, widthIsSmall, username } = props;
  const [data, setData] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  // const [isCountError, setIsCountError] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const uploadConfirmOpenRef = useRef();
  const uploadConfirmCloseRef = useRef();
  const uploadFileRef = useRef();
  const uploadSuccessOpenRef = useRef();
  const uploadSuccessCloseRef = useRef();
  const responseErrorOpenRef = useRef();
  const responseErrorCloseRef = useRef();

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSummary = async () => {
    try {
      setData([]);
      setIsSummaryLoading(true);
      let payload = { loc: loc, store_loc: "Store_Bay_Area", bay: "" };
      const summary = await storeMappingSummaryChange(payload);

      if (summary) {
        if (summary?.status === "error") {
          setLambdaError(summary?.error_message);
          openErrRef.current.click();
        } else {
          if (Object.keys(summary?.body)?.length) {
            let formattedData = summary?.body
              ?.map((item) => {
                delete item?.bay;
                return item;
              })
              .filter((item) => item);

            setData(formattedData);
          }
        }
        setIsSummaryLoading(false);
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsSummaryLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsSummaryLoading(false);
    }
  };

  const onUploadBayArea = async () => {
    try {
      setIsUploadLoading(true);
      let payload = uploadData
        .map((item) => {
          return {
            loc: parseInt(item?.loc),
            store: parseInt(item?.store),
            store_loc: "Store_Bay_Area",
            seq: parseInt(item?.seq),
            bay: "",
            user_id: String(username),
          };
        })
        .filter((item) => item);

      const storeData = await uploadStoreMappingChange(payload);

      if (storeData) {
        uploadFileRef.current.value = "";
        if (storeData?.status === "error") {
          if (storeData?.error_message) {
            uploadConfirmCloseRef.current.click();
            setLambdaError(
              storeData?.error_message === "Please contact administrator"
                ? "Please contact administrator"
                : "Please contact IT support"
            );
            openErrRef.current.click();
          } else {
            setResponseData((storeData?.body?.length && storeData?.body) || []);
            responseErrorOpenRef.current.click();
          }
        } else if (storeData?.status === "success") {
          uploadConfirmCloseRef.current.click();
          uploadSuccessOpenRef.current.click();
        }
        setIsUploadLoading(false);
      } else {
        uploadConfirmCloseRef.current.click();
        setLambdaError("Something went wrong");
        openErrRef.current.click();
        setIsUploadLoading(false);
      }
    } catch (error) {
      console.log(error);
      uploadConfirmCloseRef.current.click();
      setLambdaError(error);
      openErrRef.current.click();
      setIsUploadLoading(false);
    }
  };

  const showValidationError = (err) => {
    uploadConfirmCloseRef.current.click();
    setLambdaError(err);
    openErrRef.current.click();
  };

  const confirmFileUpload = (extractData, fileType, type) => {
    setIsContentError(false);
    // setIsCountError(false);
    uploadFileRef.current.value = "";

    if (
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      showValidationError(
        "Invalid file format. Please reupload a proper file format or refer the template file."
      );
      return;
    }

    if (!extractData?.length) {
      showValidationError(
        "Blank file. Please insert data in file and reupload."
      );
      return;
    }

    const allKeysPresent = extractData?.find(
      (item) =>
        !item?.loc ||
        !item?.store_loc ||
        !item?.seq ||
        !item?.store ||
        item?.store_loc.toLowerCase() !== "store_bay_area"
    );

    if (allKeysPresent) {
      setIsContentError(true);
    }

    const uniqueSeqValues = new Set(extractData.map((item) => item.seq));
    const uniqueStoreValues = new Set(extractData.map((item) => item.store));
    if (
      uniqueSeqValues.size !== extractData.length ||
      uniqueStoreValues.size !== extractData.length
    ) {
      setIsContentError(true);
    }

    // if (extractData?.length !== data?.length) {
    //   setIsCountError(true);
    // }

    const formattedUploadData = extractData
      .map((item) => {
        if (!item?.loc && !item?.store_loc && !item?.seq && !item?.store) {
          return null;
        } else {
          return {
            loc: item?.loc,
            store_loc: item?.store_loc,
            seq: item?.seq,
            store: item?.store,
          };
        }
      })
      .filter((item) => item);

    setUploadData(formattedUploadData);

    uploadConfirmOpenRef.current.click();
  };

  const UploadSuccess = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Data Uploaded Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <button
          className="btn btn-preview mt-3"
          onClick={() => {
            uploadSuccessCloseRef.current.click();
            setData([]);
            getSummary();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );

  const UploadConfirm = () => (
    <div
      className={["d-flex flex-column up-confirm", widthIsSmall && "p-0"].join(
        " "
      )}
    >
      <div
        className={["up-head-text mb-3", widthIsSmall && "text-wrap"].join(" ")}
      >
        Please confirm before submitting Store Bay Area data
      </div>
      <div className="mkt-table p-0 m-0">
        <table className="table">
          <thead>
            <tr>
              <th>Loc</th>
              <th>Store Loc</th>
              <th>Seq</th>
              <th>Store</th>
            </tr>
          </thead>
          <tbody>
            {uploadData?.map((item, i) => (
              <tr key={i}>
                <td>{item?.loc || "-"}</td>
                <td>{item?.store_loc || "-"}</td>
                <td>{item?.seq || "-"}</td>
                <td>{item?.store || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-column mt-auto">
        {isContentError && (
          <div
            className={["up-error my-3", widthIsSmall && "text-wrap"].join(" ")}
          >
            Missing content in file or wrong data or duplicate data found.
            Please check the file and reupload !!!
          </div>
        )}
        {/* {isCountError && (
          <div
            className={["up-error my-3", widthIsSmall && "text-wrap"].join(" ")}
          >
            Mismatch in store count in the file. Please check the file and
            reupload !!!
          </div>
        )} */}
        <div className="d-flex ms-auto mt-3">
          <button
            className="btn btn-guide me-5"
            onClick={() => uploadConfirmCloseRef.current.click()}
          >
            Cancel
          </button>
          <button
            className="btn btn-preview d-flex align-items-center"
            onClick={() => onUploadBayArea()}
            disabled={
              isUploadLoading || isContentError
              // || isCountError
            }
          >
            <div>Confirm</div>
            {isUploadLoading && <div className="spinner ms-2"></div>}
          </button>
        </div>
      </div>
    </div>
  );

  const UploadErrorPreview = () => (
    <div
      className={["d-flex flex-column", !widthIsSmall && "up-failed"].join(" ")}
    >
      <div
        className={[
          "up-head-text mb-3 text-red",
          widthIsSmall && "text-wrap",
        ].join(" ")}
      >
        Following data failed to upload. Please check again and upload
      </div>
      <div className="mkt-table p-0 m-0">
        <table className="table">
          <thead>
            <tr>
              <th>loc</th>
              <th>store loc</th>
              <th>sequence</th>
              <th>store</th>
              <th>remarks</th>
            </tr>
          </thead>
          <tbody>
            {responseData?.map((item, i) => (
              <tr key={i}>
                <td>{item?.loc || "-"}</td>
                <td>{item?.store_loc || "-"}</td>
                <td>{item?.seq || "-"}</td>
                <td>{item?.store || "-"}</td>
                <td>{item?.process_remarks || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-column mt-auto">
        <div className={"d-flex ms-auto mt-3"}>
          <DownloadExcel
            data={responseData}
            fileName={`tub_failed_ ${loc}_${moment(new Date()).format(
              "YYYY-MM-DD"
            )}`}
            buttonName={"Download Error Data"}
          />
          <button
            className="btn btn-guide ms-4"
            onClick={() => {
              responseErrorCloseRef.current.click();
              setData([]);
              getSummary();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="d-flex flex-column bay-area"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          headerText={"Store Bay Area Store Mapping"}
        />
        <div>
          <StoreMappingContents
            data={data}
            type={"bay_area"}
            loc={loc}
            uploadData={confirmFileUpload}
            uploadFileRef={uploadFileRef}
            isContentLoading={isSummaryLoading}
            widthIsSmall={widthIsSmall}
          />
        </div>
      </div>
      <CommonModal
        modalCloseRef={uploadConfirmCloseRef}
        modalOpenRef={uploadConfirmOpenRef}
        id={"uploadModal"}
        content={<UploadConfirm />}
        modalSize={"lg"}
      />
      <ErrorModal
        id={"errorModal"}
        openRef={openErrRef}
        closeRef={closeErrRef}
        headerColor={"red"}
        msg={lambdaError}
      />
      <CommonModal
        modalCloseRef={uploadSuccessCloseRef}
        modalOpenRef={uploadSuccessOpenRef}
        id={"uploadSuccessModal"}
        content={<UploadSuccess />}
        header={true}
        headerBorder={false}
        onModalClose={() => getSummary()}
      />
      <CommonModal
        modalCloseRef={responseErrorCloseRef}
        modalOpenRef={responseErrorOpenRef}
        id={"uploadErrorResponseModal"}
        content={<UploadErrorPreview />}
        modalSize={"lg"}
        header={true}
        headerBorder={false}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreMappingBayArea);
