import { useNavigate } from "react-router-dom";
import NotFoundIcon from "../../assets/images/not-found.png";
import BackArrow from "../../assets/icons/back-arrow.svg";
import "./not_found.css";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center pnf-container">
        <img
          src={NotFoundIcon}
          alt="Page Not Found"
          className="not-found-img"
        />
        <div className="not-found-text">Uh Oh! Page not found!</div>
        <button
          className="btn btn-preview mt-3 d-flex align-items-center"
          onClick={() => navigate("/")}
        >
          <img src={BackArrow} alt="back-arrow" className="arrow-icon" />
          <div className="return-text ms-2">{"Go Back"}</div>
        </button>
      </div>
    </>
  );
};
export default PageNotFound;
