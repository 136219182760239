import axios from "axios";
import store from "../store";
import { authActions } from "../store/auth";
import { grnActions } from "../store/grn";

const DEFAULT_CONFIG = {
  headers: {
    "content-type": "application/json",
  },
};

const setUpConfig = (isAuthenticated, token = "") => {
  if (isAuthenticated) {
    return {
      headers: {
        "content-type": "application/json",
        authorization: token ? token : localStorage.getItem("user_token"),
      },
    };
  } else {
    return DEFAULT_CONFIG;
  }
};

const get = async (URL, isAuthenticated = false, getFullResult = false) => {
  try {
    const CONFIG = setUpConfig(isAuthenticated);
    const result = await axios.get(URL, CONFIG);
    if (getFullResult) {
      return result?.data?.body;
    } else {
      return result.data;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

const post = async (
  URL,
  PAYLOAD = {},
  isAuthenticated = false,
  getFullResult = false
) => {
  try {
    store.dispatch(grnActions.updateLambdaErr(false));
    const TOKEN = store.getState()?.auth?.token;
    const CONFIG = setUpConfig(isAuthenticated, TOKEN);
    const result = await axios.post(URL, PAYLOAD, CONFIG);
    if (result?.data?.status) {
      const statusResposneInvalid = tokenExpiry(result?.data?.status);
      if (!statusResposneInvalid) {
        if (getFullResult) {
          return result.data?.body;
        } else {
          return result?.data;
        }
      } else {
        store.dispatch(authActions.clearTokenData());
        localStorage.removeItem("user_token");
        return false;
      }
    } else {
      store.dispatch(grnActions.updateLambdaErr(true));
      return false;
    }
  } catch (e) {
    console.log(e);
    store.dispatch(grnActions.updateLambdaErr(true));
    return false;
  }
};

export const tokenExpiry = (status) => {
  return !(status === "error" || status === "success" || status === "validation_error" || status === "invalid");
};


export { post, get };
