import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  isLoggedIn: false,
  user: {},
  widthIsSmall: false,
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    login(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload.loginResponse?.body;
      localStorage.setItem("user_token", action.payload?.loginResponse?.body?.token);

      const primaryRoleExist = action.payload?.loginResponse?.body?.roles?.find(
        (role) => role.type === "primary"
      );

      localStorage.setItem(
        "user",
        JSON.stringify({
          loc: Number(
            primaryRoleExist?.loc ||
            action.payload?.loginResponse?.body?.roles[0]?.loc
          ),
          locationName:
            primaryRoleExist?.loc_name ||
            action.payload?.loginResponse?.body?.roles[0]?.loc_name,
          role:
            primaryRoleExist?.role ||
            action.payload?.loginResponse?.body?.roles[0]?.role,
          roleList: action.payload?.loginResponse?.body?.roles,
          name: action.payload?.loginResponse?.body?.name,
          userId: action.payload?.loginResponse?.body?.user_id,
          loginName: action.payload?.inputName,
        })
      );
      state.user = {
        loc: Number(
          primaryRoleExist?.loc || action.payload?.loginResponse?.body?.roles[0]?.loc
        ),
        locationName:
          primaryRoleExist?.loc_name ||
          action.payload?.loginResponse?.body?.roles[0]?.loc_name,
        role:
          primaryRoleExist?.role ||
          action.payload?.loginResponse?.body?.roles[0]?.role,
        roleList: action.payload?.loginResponse?.body?.roles,
        name: action.payload?.loginResponse?.body?.name,
        userId: action.payload?.loginResponse?.body?.user_id,
        loginName: action.payload?.inputName,
      };
      state.token = localStorage.getItem("user_token");
    },
    changeRole(state, action) {
      state.isLoggedIn = true;
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    logout(state) {
      state.isLoggedIn = false;
      localStorage.clear();
    },
    checkauth(state, action) {
      const access_token = localStorage.getItem("user_token");
      state.token = access_token ? access_token : "";
      if (access_token !== null) {
        state.isLoggedIn = true;
        let user = JSON.parse(localStorage.getItem("user"));
        state.user = user;
      } else {
        state.isLoggedIn = false;
        state.user = {};
      }
    },
    clearAuth(state) {
      state.isLoggedIn = false;
    },
    updateScreenWidth(state, action) {
      state.widthIsSmall = action.payload;
    },
    clearTokenData(state) {
      state.token = "";
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
