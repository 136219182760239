import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { grnActions } from "../../store/grn";
import { grnListChange } from "../../api";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../containers/error_modal";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import "./grn_list.css";

const GrnList = ({ ...props }) => {
  const [poSelected, setPoSelected] = useState();
  const [isListLoading, setIsListLoading] = useState(false);
  const [allGrnList, setAllGrnList] = useState([]);
  const [lambdaError, setLambdaError] = useState("");
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const navigate = useNavigate();

  const {
    grnList,
    addGrnList,
    updatePO,
    updateInvoiceNo,
    loc,
    locationName,
    clearGrnData,
    widthIsSmall,
  } = props;

  useEffect(() => {
    clearGrnData();
    getGrnList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUserSelectPo = (checked, values) => {
    if (checked) {
      setPoSelected(values);
      updatePO(values?.order_no);
      updateInvoiceNo(values?.invoice_no);
    } else {
      setPoSelected("");
    }
  };

  const getGrnList = async () => {
    try {
      setIsListLoading(true);
      let payload = {
        loc: loc,
      };

      const grnListData = await grnListChange(payload);

      if (grnListData) {
        if (grnListData?.status === "error") {
          setLambdaError(grnListData?.error_message);
          openErrRef.current.click();
          setIsListLoading(false);
        } else {
          if (Object.keys(grnListData?.body)) {
            addGrnList(grnListData?.body);
            setAllGrnList(grnListData?.body);
            setIsListLoading(false);
          }
        }
      } else {
        setIsListLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsListLoading(false);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const searchReport = (data) => {
    const pendingData = [...allGrnList];
    const filteredData =
      data?.length &&
      pendingData.filter((item) => {
        return (
          String(item?.order_no).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.invoice_no).toLowerCase().includes(data.toLowerCase())
        );
      });
    if (filteredData && filteredData.length) {
      addGrnList(filteredData);
    }
    if (data === "") {
      addGrnList(allGrnList);
    }
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="ls-grn"
        style={{
          padding: widthIsSmall ? "8px" : "8px 24px",
        }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          progressBar={false}
          headerText={"GRN List"}
        />
        <div className="grn-list d-flex flex-column align-items-center position-relative">
          <div className="d-flex align-items-center ms-auto mb-3 w-50 justify-content-end">
            <div className="text-nowrap list-text-key">Search Orders :</div>
            <input
              type={"text"}
              placeholder={"Search Order No. or Invoice No."}
              className={"form-control ms-3 w-50"}
              onChange={(e) => searchReport(e.target.value)}
            />
          </div>
          {!isListLoading && (
            <div className="list-items w-100 d-flex flex-column align-items-center">
              {grnList?.map((grn, i) => {
                return (
                  <div
                    key={i}
                    className="d-flex flex-row align-items-center card list-card mb-3 p-3"
                    style={{ width: widthIsSmall ? "100%" : "50%" }}
                  >
                    <input
                      type={"checkbox"}
                      onChange={(e) => {
                        onUserSelectPo(e.target.checked, {
                          order_no: grn?.order_no,
                          invoice_no: grn?.invoice_no,
                        });
                      }}
                      checked={
                        poSelected?.order_no === grn?.order_no &&
                        poSelected?.invoice_no === grn?.invoice_no
                      }
                    />
                    <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row align-items-center">
                      <div className="d-flex ms-3">
                        <div className="text-nowrap list-text-key">
                          Order No
                        </div>
                        <div className="list-text-value">
                          :&nbsp;&nbsp;&nbsp;{grn?.order_no}
                        </div>
                      </div>
                      <div className="d-flex ms-3">
                        <div className="text-nowrap list-text-key">
                          Invoice/DC No
                        </div>
                        <div className="list-text-value">
                          :&nbsp;&nbsp;&nbsp;{grn?.invoice_no}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {isListLoading && (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "10%" }}
            >
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {!grnList?.length && !isListLoading && (
            <div className="no-records" style={{ marginBottom: "10%" }}>
              No records found
            </div>
          )}

          <button
            className="btn btn-proceed btn-list-proceed d-flex align-items-center"
            onClick={() => navigate("/grn")}
            disabled={!poSelected || isListLoading}
          >
            Proceed
          </button>
          <ErrorModal
            id={"errorModal"}
            openRef={openErrRef}
            closeRef={closeErrRef}
            headerColor={"red"}
            msg={lambdaError}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    grnList: state?.grn?.grnList,
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGrnList: (data) => dispatch(grnActions.addGrnList(data)),
    updatePO: (data) => dispatch(grnActions.updatePO(data)),
    updateInvoiceNo: (data) => dispatch(grnActions.updateInvoiceNo(data)),
    clearGrnData: (data) => dispatch(grnActions.clearGrnData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GrnList);
