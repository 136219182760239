import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./gdn_details.css";

const GdnDetails = ({
  grnData,
  supplier,
  invoiceAmount,
  invoiceDate,
  po,
  invoiceNo,
  receiptId,
  filterGdnData,
  widthIsSmall,
  searchText,
  setSearchText,
}) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column">
      {widthIsSmall ? (
        <div className="submit-form d-flex flex-column">
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Supplier</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{supplier || "NA"}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Order No</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{po || "NA"}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Invoice/DC No</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{invoiceNo || "NA"}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">Invoice Amount</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{invoiceAmount || 0}
            </div>
          </div>
          <div className="d-flex align-items-start">
            <label className="sf-response-label w-50">GRN Number</label>
            <div className="sf-response-data w-50">
              :&nbsp;&nbsp;{receiptId || "NA"}
            </div>
          </div>
        </div>
      ) : (
        <div className="submit-form d-flex">
          <div className="d-flex flex-column">
            <label className="gdn-sf-label">Supplier</label>
            <label className="gdn-sf-label">Order No</label>
            <label className="gdn-sf-label">Invoice/DC No</label>
            <label className="gdn-sf-label">Invoice Amount</label>
            <label className="gdn-sf-label">GRN Number</label>
          </div>
          <div className="d-flex flex-column ms-5 text-black-50">
            <div className="gdn-sf-label">:&nbsp;&nbsp;&nbsp;{supplier}</div>
            <div className="gdn-sf-label">:&nbsp;&nbsp;&nbsp;{po}</div>
            <div className="gdn-sf-label">:&nbsp;&nbsp;&nbsp;{invoiceNo}</div>
            <div className="gdn-sf-label">
              :&nbsp;&nbsp;&nbsp;{invoiceAmount}
            </div>
            <div className="gdn-sf-label">:&nbsp;&nbsp;&nbsp;{receiptId}</div>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-between mt-4 mx-3">
        <button
          className="btn btn-download"
          onClick={() => navigate("../add-items")}
        >
          Add items
        </button>

        <div
          className={["input-group", widthIsSmall ? "w-50" : "w-25"].join(" ")}
        >
          <input
            type="text"
            className="form-control search-input"
            placeholder="Search"
            aria-describedby="basic-addon2"
            onChange={(e) => {
              filterGdnData(e.target.value);
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <span
            className="input-group-text"
            id="basic-addon2"
            onClick={() => {
              if (searchText) {
                filterGdnData("");
                setSearchText("");
              }
            }}
          >
            {searchText && <>X</>}
          </span>
        </div>
      </div>
    </div>
  );
};
export default GdnDetails;
