import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { uploadLpnAuditList, getLpnAuditList } from "../../api";
import ErrorModal from "../../containers/error_modal";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import DownloadExcel from "../../containers/download_excel";
import ExtractExcel from "../../containers/extract_excel";
import CommonModal from "../../containers/common_modal";
import moment from "moment";

const LpnAudit = ({ ...props }) => {
  const [sampleLpnList, setSampleLpnList] = useState([]);
  const [allSampleLpnList, setAllSampleLpnList] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [lambdaError, setLambdaError] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const uploadConfirmOpenRef = useRef();
  const uploadConfirmCloseRef = useRef();
  const uploadOpenRef = useRef();
  const uploadCloseRef = useRef();

  const { loc, locationName, widthIsSmall, username } = props;

  useEffect(() => {
    getSampleLpnList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSampleLpnList = async () => {
    try {
      setSampleLpnList([]);
      setAllSampleLpnList([]);
      setIsListLoading(true);
      let payload = {
        loc: loc,
        user_id: String(username),
      };

      const sampleLpnListData = await getLpnAuditList(payload);

      if (sampleLpnListData) {
        if (sampleLpnListData?.status === "error") {
          setLambdaError(sampleLpnListData?.error_message);
          openErrRef.current.click();
          setIsListLoading(false);
        } else {
          if (Object.keys(sampleLpnListData?.body)) {
            let formattedData = sampleLpnListData?.body?.map((item) => {
              return {
                loc: item?.loc,
                store: item?.store,
                lpn: item?.lpn,
                item: item?.item,
                item_desc: item?.item_desc,
                item_qty_user: "",
              };
            });
            setSampleLpnList(formattedData);
            setAllSampleLpnList(formattedData);
            setIsListLoading(false);
          }
        }
      } else {
        setIsListLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsListLoading(false);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const onUploadList = async () => {
    try {
      setIsLoading(true);

      let orderList = uploadData
        .map((item) => {
          return {
            loc: item?.loc,
            store: item?.store,
            lpn: item?.lpn,
            item: item?.item,
            item_qty_user: item?.item_qty_user,
          };
        })
        .filter((item) => item);

      let payload = {
        user_id: String(username),
        details: orderList,
      };

      const uploadedData = await uploadLpnAuditList(payload);

      if (uploadedData) {
        fileRef.current.value = "";
        if (uploadedData?.status === "error" || uploadedData?.error_message) {
          uploadConfirmCloseRef.current.click();
          setLambdaError(uploadedData?.error_message);
          openErrRef.current.click();
        } else if (uploadedData?.status === "success") {
          uploadConfirmCloseRef.current.click();
          uploadOpenRef.current.click();
        }
        setIsLoading(false);
      } else {
        uploadConfirmCloseRef.current.click();
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      uploadConfirmCloseRef.current.click();
      setLambdaError(error);
      openErrRef.current.click();
      setIsLoading(false);
    }
  };

  const searchReport = (data) => {
    const pendingData = [...allSampleLpnList];
    const filteredData =
      data?.length &&
      pendingData.filter((item) => {
        return (
          String(item?.loc).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.store).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.lpn).toLowerCase().includes(data.toLowerCase())
        );
      });
    if (filteredData && filteredData.length) {
      setSampleLpnList(filteredData);
    }
    if (data === "") {
      setSampleLpnList(allSampleLpnList);
    }
  };

  const showValidationError = (err) => {
    uploadConfirmCloseRef.current.click();
    setLambdaError(err);
    openErrRef.current.click();
  };

  const confirmFileUpload = (data, fileType, type) => {
    setIsContentError(false);
    fileRef.current.value = "";

    if (
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      showValidationError(
        "Invalid file format. Please reupload a proper file format or refer the template file."
      );
      return;
    }

    if (!data?.length) {
      showValidationError(
        "Blank file. Please insert data in file and reupload."
      );
      return;
    }

    const formattedUploadData = data
      .map((item) => {
        if (
          !item?.loc &&
          !item?.store &&
          !item?.lpn &&
          !item?.item &&
          !item?.item_qty_user
        ) {
          return null;
        } else {
          return item;
        }
      })
      .filter((item) => item);
    setUploadData(formattedUploadData);

    const allKeysNotPresent = formattedUploadData?.find(
      (item) =>
        !item?.loc ||
        !item?.store ||
        !item?.lpn ||
        !item?.item ||
        !item?.item_qty_user ||
        isNaN(item?.item_qty_user)
    );

    if (allKeysNotPresent) {
      setIsContentError(true);
    }
    uploadConfirmOpenRef.current.click();
  };

  const UploadConfirm = () => (
    <div
      className={["d-flex flex-column", !widthIsSmall && "up-confirm"].join(
        " "
      )}
    >
      <div
        className={["up-head-text mb-3", widthIsSmall && "text-wrap"].join(" ")}
      >
        Please confirm before submitting Lpn Audit data
      </div>
      <div className="mkt-table p-0 m-0">
        <table className="table">
          <thead>
            <tr>
              <th>Loc</th>
              <th>Store</th>
              <th>Lpn</th>
              <th>Item</th>
              <th>Item Qty User</th>
            </tr>
          </thead>
          <tbody>
            {uploadData?.map((item, i) => (
              <tr key={i}>
                <td>{item?.loc || "-"}</td>
                <td>{item?.store || "-"}</td>
                <td>{item?.lpn || "-"}</td>
                <td>{item?.item || "-"}</td>
                <td
                  style={{
                    color: isNaN(item?.item_qty_user) ? "red" : "black",
                  }}
                >
                  {item?.item_qty_user || "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-column mt-auto">
        {isContentError && (
          <div
            className={["up-error my-3", widthIsSmall && "text-wrap"].join(" ")}
          >
            Missing content in file or wrong data. Please check the file and
            reupload !!!
          </div>
        )}
        <div className={"d-flex ms-auto mt-3"}>
          <button
            className="btn btn-guide me-5"
            onClick={() => {
              uploadConfirmCloseRef.current.click();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-preview d-flex align-items-center"
            onClick={onUploadList}
            disabled={isLoading || isContentError}
          >
            <div>Confirm</div>
            {isLoading && <div className="spinner ms-2"></div>}
          </button>
        </div>
      </div>
    </div>
  );

  const UploadSuccess = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Data Uploaded Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <button
          className="btn btn-preview mt-3"
          onClick={() => {
            uploadCloseRef.current.click();
            setSampleLpnList([]);
            getSampleLpnList();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="ls-grn"
        style={{
          padding: widthIsSmall ? "8px" : "8px 24px",
        }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          progressBar={false}
          headerText={"LPN Audit"}
        />
        <div
          className="grn-list d-flex flex-column align-items-center position-relative"
          style={{ height: "80vh" }}
        >
          <div className="d-flex align-items-center ms-auto mb-3 w-50 justify-content-end">
            <input
              type={"text"}
              placeholder={"Search Lpn or Store..."}
              className={"form-control ms-3 w-50"}
              onChange={(e) => searchReport(e.target.value)}
            />
          </div>
          <div className="mkt-table w-100">
            <table className="table">
              <thead>
                <tr>
                  <th>Loc</th>
                  <th>Store</th>
                  <th>Lpn</th>
                  <th>Item</th>
                  <th>Item Desc</th>
                </tr>
              </thead>
              <tbody>
                {sampleLpnList?.map((item, i) => (
                  <tr key={i}>
                    <td>{item?.loc || "-"}</td>
                    <td>{item?.store || "-"}</td>
                    <td>{item?.lpn || "-"}</td>
                    <td>{item?.item || "-"}</td>
                    <td>{item?.item_desc || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isListLoading && (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "5%" }}
              >
                <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            {!sampleLpnList?.length && !isListLoading && (
              <div className="d-flex justify-content-center no-records">
                No records found
              </div>
            )}
          </div>

          <div className="align-items-center d-flex justify-content-center mt-4 w-100">
            <DownloadExcel
              data={allSampleLpnList}
              fileName={`Lpn_audit_report_ ${loc}_${moment(new Date()).format(
                "YYYY-MM-DD"
              )}`}
              buttonName={"Export LPN Details"}
            />
            <div className="ms-5">
              <ExtractExcel
                buttonName={"Upload For LPN Audit"}
                width={widthIsSmall ? "100%" : "175px"}
                onSubmit={(data, fileType, type) =>
                  confirmFileUpload(data, fileType, type)
                }
                inputRef={fileRef}
                extractType={"nftExtract"}
              />
            </div>
          </div>

          <CommonModal
            modalCloseRef={uploadConfirmCloseRef}
            modalOpenRef={uploadConfirmOpenRef}
            id={"uploadModal"}
            content={<UploadConfirm />}
            modalSize={"lg"}
          />

          <CommonModal
            modalCloseRef={uploadCloseRef}
            modalOpenRef={uploadOpenRef}
            id={"uploadSuccessModal"}
            content={<UploadSuccess />}
            header={true}
            headerBorder={false}
            onModalClose={() => getSampleLpnList()}
          />

          <ErrorModal
            id={"errorModal"}
            openRef={openErrRef}
            closeRef={closeErrRef}
            headerColor={"red"}
            msg={lambdaError}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    grnList: state?.grn?.grnList,
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LpnAudit);
