import "./content_view.css";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ContentView = ({ grnData, submitGrn, isSubmitLoading }) => {
  return (
    <div className="cv-table cv d-flex flex-column">
      <div className="content-table">
        <table className="table">
          <thead>
            <tr>
              <th>Item Id</th>
              <th>Item Description</th>
              <th>UOM</th>
              <th>Pack Size</th>
              <th>Expected</th>
              <th>Received</th>
              <th>Rejected</th>
              <th>Reason Desc</th>
              <th>Margin Type</th>
              <th>Unit Cost</th>
              <th>MRP</th>
              <th>Amended MRP</th>
              <th>Item Type</th>
              <th>Mfg Date</th>
              <th>Lpn</th>
            </tr>
          </thead>
          <tbody>
            {grnData?.map((grn, i) => (
              <tr key={i}>
                <td>{grn?.item || "-"}</td>
                <td>{grn?.item_desc || "-"}</td>
                <td>{grn?.uom || "-"}</td>
                <td>{grn?.sps || 0}</td>
                <td>{grn?.expected || 0}</td>
                <td>{grn?.received || 0}</td>
                <td>{grn?.rejected_qty.reduce((a, b) => a + b, 0) || 0}</td>
                <td>{grn?.rej_rsn_desc?.join(", ") || "NA"}</td>
                <td>{grn?.margin_type ? "MRP" : "COST"}</td>
                <td>{grn?.unit_cost || 0}</td>
                <td>{grn?.mrp || 0}</td>
                <td>{grn?.amended_mrp || 0}</td>
                <td>{grn?.item_type || "-"}</td>
                <td>{grn?.mfg_date || "-"}</td>
                <td>{grn?.lpn || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex mt-auto mb-3">
        <div className="d-flex justify-content-center w-100">
          <button
            className="btn btn-submit d-flex align-items-center"
            onClick={() => submitGrn()}
            disabled={isSubmitLoading}
          >
            <div>RG Check</div>
            {isSubmitLoading && <div className="spinner ms-2"></div>}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ContentView;
