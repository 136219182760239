import { connect } from "react-redux";
import "./content_header.css";

const ContentHeader = ({
  text,
  progress,
  progressBar = false,
  border = false,
  headerText = "",
  ...props
}) => {
  const { widthIsSmall } = props;
  return (
    <div
      className={[
        "align-items-xl-end align-items-lg-end align-items-md-end align-items-center d-flex header justify-content-between",
        border ? "border" : "",
      ].join(" ")}
      style={{ padding: widthIsSmall ? "8px" : "14px" }}
    >
      {headerText && (
        <div
          className="main-header-text"
          style={{ fontSize: widthIsSmall ? "16px" : "20px" }}
        >
          {headerText}
        </div>
      )}
      <div
        className="header-text ms-auto"
        style={{ fontSize: widthIsSmall ? "10px" : "16px" }}
      >
        {text}
      </div>
      {progressBar && (
        <div className="d-flex flex-column progress-sec">
          <div className="ms-auto progress-text">{progress}</div>
          <div id="progress">
            <div id="progress-bar" style={{ width: progress }}></div>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
