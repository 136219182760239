import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { grnActions } from "../../store/grn";
import {
  amendMrpSubmitChange,
  gdnUatChange,
  grnUatChange,
  grnWebChange,
  rgCheckChange,
  sendMailChange,
  toGrnChange,
} from "../../api";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DoubletickIcon from "../../assets/icons/doubletick.svg";
import Header from "../../containers/content_header";
import SubmitForm from "../../containers/submit_form";
import ContentView from "../../containers/content_view";
import CommonModal from "../../containers/common_modal";
import ErrorIcon from "../../assets/icons/exclamation.svg";
import AmendedMrp from "../../containers/amended_mrp";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import ErrorModal from "../../containers/error_modal";
import "react-toastify/dist/ReactToastify.css";
import "./grn.css";

const Grn = ({ ...props }) => {
  const {
    addGrnData,
    grnData,
    addRgCheck,
    supplier,
    invoiceAmount,
    invoiceDate,
    po,
    invoiceNo,
    receiptId,
    skuCount,
    loc,
    supplierId,
    updateRgChecks,
    locationName,
    clearGrnData,
    gdnNo,
    updateGdnNo,
    gdnDate,
    widthIsSmall,
    markRgCheckDone,
    username,
  } = props;

  const modalRef = useRef();
  const successRef = useRef();
  const poSuccessOpenRef = useRef();
  const poSuccessCloseRef = useRef();
  const invoiceMismatchModalOpenRef = useRef();
  const invoiceMismatchModalCloseRef = useRef();
  const rgCheckValidationModalOpenRef = useRef();
  const rgCheckValidationModalCloseRef = useRef();
  const amendModalConfirmOpenRef = useRef();
  const amendModalConfirmCloseRef = useRef();
  const amendModalOpenRef = useRef();
  const amendModalCloseRef = useRef();
  const mailerModalOpenRef = useRef();
  const mailerModalCloseRef = useRef();
  const mailerSuccessModalOpenRef = useRef();
  const mailerSuccessModalCloseRef = useRef();
  // const createGDNModalOpenRef = useRef();
  // const createGDNModalCloseRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const rgSuccessModalOpenRef = useRef();
  const rgSuccessModalCloseRef = useRef();
  const gdnSubmitOpenRef = useRef();
  const gdnSubmitCloseRef = useRef();
  const successGdnModalOpenRef = useRef();
  const successGdnModalCloseRef = useRef();

  const [showContent, setShowContent] = useState(false);
  const [isGrnWebLoading, setIsGrnWebLoading] = useState(false);
  const [isRgLoading, setIsRgLoading] = useState(false);
  const [isGrnLoading, setIsGrnLoading] = useState(false);
  const [isGdnLoading, setIsGdnLoading] = useState(false);
  const [isAmendSubmitLoading, setIsAmendSubmitLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const [amendMrpItems, setAmendMrpItems] = useState([]);
  const [orderInd, setOrderInd] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setShowContent(false);
    markRgCheckDone(false);
    onSubmitGrnWeb();
    if (!po || !invoiceNo) {
      navigate("/grnlist");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitGrnWeb = async () => {
    try {
      setIsGrnWebLoading(true);
      setShowContent(false);

      let payload = {
        order_no: parseInt(po),
        invoice_no: invoiceNo,
        loc: loc,
      };

      const grnSubmitted = await grnWebChange(payload);

      if (grnSubmitted) {
        if (grnSubmitted?.status === "error") {
          setLambdaError(grnSubmitted?.error_message);
          openErrRef.current.click();
          setIsGrnWebLoading(false);
        } else {
          if (Object.keys(grnSubmitted)) {
            setIsGrnWebLoading(false);
            setShowContent(true);
            addGrnData({
              grnData: grnSubmitted?.body || [],
              supplier: grnSubmitted?.supplier || "",
              invoiceAmount: grnSubmitted?.invoice_amount || "",
              invoiceDate: grnSubmitted?.invoice_date || "",
              receiptId: grnSubmitted?.receipt_id || "",
              skuCount: grnSubmitted?.item_count || "",
              supplierId: grnSubmitted?.supplier_id || "",
              gdnDate: grnSubmitted?.gdn_date || "",
            });
            const amendList = grnSubmitted?.body?.filter(
              (item) => item?.mrp !== item?.amended_mrp
            );
            setAmendMrpItems(amendList);
          }
        }
      } else {
        setIsGrnWebLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const checkAmendExist = () => {
    let amendListExist = grnData?.filter(
      (grn) => grn?.mrp !== grn?.amended_mrp && grn?.margin_type && grn?.lpn
    );
    if (amendListExist?.length) {
      amendModalConfirmOpenRef.current.click();
    } else {
      onSubmitRgCheck();
    }
  };

  const onSubmitRgCheck = async () => {
    try {
      setIsRgLoading(true);
      markRgCheckDone(true);
      setOrderInd("");

      let payload = {
        order_no: parseInt(po),
        invoice_no: String(invoiceNo),
      };

      const rgChecked = await rgCheckChange(payload);

      if (rgChecked) {
        console.log("rgChecked", rgChecked)
        if (rgChecked?.status === "invalid") {
          rgCheckValidationModalOpenRef.current.click();
          setIsRgLoading(false);
        }
        if (rgChecked?.status === "error") {
          setLambdaError(`RG check : ${rgChecked.error_message}`);
          openErrRef.current.click();
          setIsRgLoading(false);
        }
        if (rgChecked?.status === "success") {
          if (Object.keys(rgChecked?.body)?.length) {
            setOrderInd(rgChecked?.body?.order_ind);
            updateRgChecks({
              gdnCheck: rgChecked.body?.gdn_check,
              softallocCheck: rgChecked.body?.softalloc_check,
            });
            addRgCheck(rgChecked?.body);
            updateGdnNo(rgChecked?.body?.gdn_no);
            if (!rgChecked.body?.rg_status) {
              invoiceMismatchModalOpenRef.current.click();
            } else {
              if (rgChecked?.body?.order_ind === "TRANSFER") {
                rgSuccessModalOpenRef.current.click();
              } else {
                if (rgChecked?.body?.gdn_check) {
                  gdnSubmitOpenRef.current.click();
                } else {
                  rgSuccessModalOpenRef.current.click();
                }
              }
            }
            setIsRgLoading(false);
          }
        }
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsRgLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitGrnUat = async () => {
    try {
      setIsGrnLoading(true);

      /**
       * sample code to remove duplicate items from grn list
          // const uniqueItemList = grnData.filter(
          //   (value, index, self) =>
          //     index === self.findIndex((item) => item?.item === value?.item)
          // );
        
          // const itemDetails = uniqueItemList
          //   .map((item) => {
          //     return (
          //       item?.lpn && {
          //         item: item?.item,
          //         qty: item?.received,
          //         unit_cost: item?.unit_cost,
          //         mrp: item?.mrp,
          //         mfg_date: item?.mfg_date,
          //       }
          //     );
          //   })
          //   .filter((item) => item);
        
          // console.log(itemDetails, uniqueItemList);
      */

      const itemDetails = grnData
        .map((item) => {
          return (
            item?.lpn &&
            item?.received > 0 && {
              item: item?.item,
              qty: item?.received,
              unit_cost: item?.unit_cost,
              mrp: item?.mrp,
              mfg_date: item?.mfg_date,
            }
          );
        })
        .filter((item) => item);

      let payload = {
        SIMPlus_RCV_NO: parseInt(receiptId),
        details: itemDetails,
        invoice_amount: invoiceAmount,
        invoice_date: invoiceDate,
        invoice_no: invoiceNo,
        location: loc,
        order_no: parseInt(po),
        po_cre_ind: "N",
        source: "wmsplus",
        supplier: parseInt(supplierId),
        user_id: String(username),
      };

      const grnUatData = await grnUatChange(payload);
      if (grnUatData) {
        if (grnUatData?.status === "error") {
          setLambdaError("GRN UAT : " + grnUatData?.error_message);
          openErrRef.current.click();
          setIsGrnLoading(false);
        } else {
          if (Object.keys(grnUatData?.body)?.length) {
            successRef.current.click();
            setIsGrnLoading(false);
          }
        }
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsGrnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsGrnLoading(false);
    }
  };

  const onSubmitToGrn = async () => {
    try {
      setIsGrnLoading(true);
      const itemDetails = grnData
        .map((item) => {
          return (
            item?.lpn &&
            item?.received > 0 && {
              carton_id: String(item?.lpn) || "null",
              item: String(item?.item),
              distro_no: String(po),
              qty: parseInt(item?.received),
            }
          );
        })
        .filter((item) => item);

      let payload = {
        source: "wmsplus",
        shipment: String(receiptId),
        to_loc: String(loc),
        details: itemDetails,
        user_id: String(username),
      };

      const toGrnData = await toGrnChange(payload);
      if (toGrnData) {
        if (toGrnData?.status === "error") {
          setLambdaError("TO GRN : " + toGrnData?.error_message);
          openErrRef.current.click();
          setIsGrnLoading(false);
        } else {
          if (Object.keys(toGrnData?.body)?.length) {
            setIsGrnLoading(false);
            successRef.current.click();
          }
        }
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsGrnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsGrnLoading(false);
    }
  };

  const onGdnSubmit = async () => {
    try {
      setIsGdnLoading(true);
      let itemDetails = grnData
        .map((item) => {
          if (item?.total_rejected_qty > 0) {
            return {
              item: item?.item,
              grn_qty: item?.received,
              rejected_qty: item?.rejected_qty || 0,
              invoice_qty: item?.invoice_qty || 0,
              order_qty: item?.expected,
              order_cost: item?.unit_cost,
              order_mrp: item?.amended_mrp ? item?.amended_mrp : item?.mrp,
              invoice_mrp: 0.0,
              invoice_cost: 0.0,
              rej_rsn_code: item?.rej_rsn_code || 0,
              rej_rsn_desc: item?.rej_rsn_desc || "",
            };
          }
        })
        .filter((item) => item);

      let payload = {
        source: "wmsplus",
        gdn_no: parseInt(gdnNo) || 0,
        order_no: parseInt(po),
        invoice_no: invoiceNo,
        receipt_id: receiptId,
        loc: loc,
        supplier: parseInt(supplier),
        gdn_date: gdnDate,
        create_id: "wmsplus_gdn_lambda",
        details: itemDetails,
        user_id: String(username),
      };

      const submitGdnData = await gdnUatChange(payload);

      if (submitGdnData) {
        if (submitGdnData?.status === "error") {
          setLambdaError("GDN UAT : " + submitGdnData?.error_message);
          openErrRef.current.click();
          setIsGdnLoading(false);
        } else {
          if (Object.keys(submitGdnData?.body)?.length) {
            setIsGdnLoading(false);
            successGdnModalOpenRef.current.click();
          }
        }
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsGdnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsGdnLoading(false);
    }
  };

  const onAmendMrpSubmit = async () => {
    try {
      setIsAmendSubmitLoading(true);
      let itemDetails = grnData
        .map((item) => {
          return (
            item?.mrp !== item?.amended_mrp &&
            item?.margin_type &&
            item?.lpn && {
              item: String(item?.item),
              amended_mrp: parseFloat(item?.amended_mrp),
            }
          );
        })
        .filter((item) => item);

      let payload = {
        source: "wmsplus",
        adj_id: parseInt(receiptId),
        order_no: parseInt(po),
        details: itemDetails,
      };

      const submitAmendMrp = await amendMrpSubmitChange(payload);

      if (submitAmendMrp) {
        if (submitAmendMrp?.status === "error") {
          setLambdaError("Amend Mrp : " + submitAmendMrp?.error_message);
          openErrRef.current.click();
          setIsAmendSubmitLoading(false);
        } else {
          if (Object.keys(submitAmendMrp?.body)?.length) {
            setIsAmendSubmitLoading(false);
            onSubmitGrnWeb();
            poSuccessOpenRef.current.click();
          }
        }
      } else {
        setIsAmendSubmitLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const onSendEmail = async () => {
    try {
      setIsEmailLoading(true);

      let payload = {
        order_no: parseInt(po),
        invoice_no: invoiceNo,
        invoice_amount: invoiceAmount,
        grn_final_amt: invoiceAmount,
        user_id: String(username),
      };

      const emailSend = await sendMailChange(payload);

      if (emailSend) {
        if (emailSend?.status === "error") {
          setLambdaError("Auto mailer : " + emailSend?.error_message);
          openErrRef.current.click();
          setIsEmailLoading(false);
        } else {
          if (Object.keys(emailSend?.body)?.length) {
            setIsEmailLoading(false);
            mailerSuccessModalOpenRef.current.click();
          }
        }
        setIsEmailLoading(false);
      } else {
        // setIsEmailLoading(false);
        // setLambdaError("Something went wrong");
        openErrRef.current.click();
        setIsEmailLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsEmailLoading(false);
    }
  };

  const closeModal = (isAmendMrp = false) => {
    if (isAmendMrp) {
      poSuccessCloseRef.current.click();
    } else {
      modalRef.current.click();
      navigate("../grnlist");
    }
  };

  // pop up components

  const DataSubmitted = ({ isAmendMrp = false }) => (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between submit-content flex-column flex-xl-row flex-lg-row flex-md-row">
        <div className="d-flex flex-column mb-3 mb-xl-0 mb-lg-0 mb-md-0 me-4">
          <div className="modal-text">Order No</div>
          <div>{po}</div>
        </div>
        <div className="d-flex flex-column mb-3 mb-xl-0 mb-lg-0 mb-md-0 me-4">
          <div className="modal-text">Supplier</div>
          <div>{supplier}</div>
        </div>
        <div className="d-flex flex-column mb-3 mb-xl-0 mb-lg-0 mb-md-0 me-4">
          <div className="modal-text">Receipt</div>
          <div>{receiptId}</div>
        </div>
        <div className="d-flex flex-column mb-3 mb-xl-0 mb-lg-0 mb-md-0 me-4">
          <div className="modal-text">SKUs Rec</div>
          <div>{skuCount}</div>
        </div>
        <div className="d-flex flex-column">
          <div className="modal-text">Invoice Date</div>
          <div>{invoiceDate}</div>
        </div>
      </div>
      <div className="d-flex">
        {isAmendMrp ? (
          <button
            className="btn btn-guide ms-auto mt-5"
            onClick={() => closeModal(isAmendMrp)}
          >
            Proceed for RG Check
          </button>
        ) : (
          <button
            className="btn btn-preview ms-auto mt-5"
            onClick={() => {
              closeModal(isAmendMrp);
              navigate("../grndash");
            }}
          >
            GRN/GDN Dashboard
          </button>
        )}
      </div>
    </div>
  );

  const RGSuccess = () => (
    <div className="d-flex flex-column align-items-center">
      <div className="mismatch-text">RG check successful</div>
      <button
        className="btn btn-mismatch btn-proceed-grn mt-4 d-flex align-items-center"
        onClick={() => {
          if (orderInd === "TRANSFER") {
            onSubmitToGrn();
          } else {
            onSubmitGrnUat();
          }
        }}
        disabled={isGrnLoading}
      >
        <div> Proceed for GRN</div>
        {isGrnLoading && <div className="spinner ms-2"></div>}
      </button>
    </div>
  );

  const RgGdnSubmit = () => (
    <div className="d-flex flex-column ps-3">
      <div className="mismatch-text">RG check successful.</div>
      <div className="mismatch-text mt-2 text-red">
        GDN not submitted yet. Proceed to submit GDN.
      </div>
      <div className="d-flex flex-column align-items-center">
        <button
          className="btn btn-mismatch btn-proceed-grn mt-4 d-flex align-items-center"
          onClick={() => {
            onGdnSubmit();
          }}
          disabled={isGdnLoading}
        >
          <div> Proceed for GDN</div>
          {isGdnLoading && <div className="spinner ms-2"></div>}
        </button>
      </div>
    </div>
  );

  const GdnSubmitSuccess = () => (
    <div className="d-flex flex-column align-items-center mx-5 mt-5">
      <div className="d-flex align-items-center">
        <div className="submit-gdn-text">GDN submitted successfully</div>
        &nbsp;&nbsp;&nbsp;
        <img className="tick-icon" src={DoubletickIcon} alt="tick" />
      </div>
      <button
        className="btn btn-preview my-5"
        onClick={() => {
          successGdnModalCloseRef.current.click();
          onSubmitGrnWeb();
        }}
      >
        Proceed for RG Check
      </button>
    </div>
  );

  const ConfirmAmendSubmit = () => (
    <div className="d-flex flex-column px-3 py-4">
      <div className="d-flex flex-column">
        <div className="mismatch-header-text mb-3">Warning</div>
        <div className="mismatch-header-text">
          {`Item MRP was amended for ${amendMrpItems?.length}/${grnData?.length} items`}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-preview mt-4"
          onClick={() => {
            amendModalOpenRef.current.click();
          }}
          disabled={isGrnLoading}
        >
          <div> Proceed to Refresh PO</div>
          {isGrnLoading && <div className="spinner ms-2"></div>}
        </button>
      </div>
    </div>
  );

  const InvoiceAMountMismatch = () => (
    <div className="d-flex flex-column mx-3 mb-4">
      <div className="d-flex mb-3">
        <img src={ErrorIcon} alt="error" className="error-icon" />
        &nbsp;&nbsp;
        <div className="mismatch-header-text">Error</div>
      </div>
      <div className="mismatch-text">
        Invoice Amount Mismatch. Proceed with below options.
      </div>
      <div className="d-flex justify-content-center w-100">
        <button
          className="btn btn-create-gdn btn-warning mt-5 me-4"
          onClick={() => {
            invoiceMismatchModalCloseRef.current.click();
            navigate("../gdn");
            // createGDNModalOpenRef.current.click();
          }}
        >
          Create/Edit GDN
        </button>
        <button
          className="btn btn-mismatch btn-danger mt-5"
          onClick={() => {
            invoiceMismatchModalCloseRef.current.click();
            mailerModalOpenRef.current.click();
          }}
        >
          Raise Issue
        </button>
      </div>
    </div>
  );

  const RgCheckValidationModal = () => (
    <div className="d-flex flex-column mx-3 mb-4">
      <div className="d-flex mb-3">
        <img src={ErrorIcon} alt="error" className="error-icon" />
        &nbsp;&nbsp;
        <div className="mismatch-header-text">Error</div>
      </div>
      <div className="mismatch-text">
        RG Check Failed! Submit GDN details and try again.
      </div>
      <div className="d-flex justify-content-center w-100">
        <button
          className="btn btn-create-gdn btn-warning mt-5 me-4"
          onClick={() => {
            rgCheckValidationModalCloseRef.current.click();
            navigate("../gdn");
          }}
        >
          Edit GDN
        </button>
      </div>
    </div>
  );

  const SendMail = () => (
    <div className="align-items-center d-flex flex-column mt-4">
      <div className="d-flex mb-3">
        <img src={ErrorIcon} alt="error" className="error-icon" />
        &nbsp;&nbsp;
        <div className="mismatch-header-text">Invoice Amount Mismatch</div>
      </div>
      <div className="mismatch-text">
        Please proceed to send mail to Category team.
      </div>
      <div className="d-flex justify-content-end w-100">
        <button
          className="btn btn-preview mt-5"
          onClick={() => {
            mailerModalCloseRef.current.click();
            invoiceMismatchModalOpenRef.current.click();
          }}
        >
          Back
        </button>
        <button
          className="btn btn-guide mt-5 d-flex align-items-center ms-3"
          onClick={() => {
            mailerModalCloseRef.current.click();
            onSendEmail();
          }}
          disabled={isEmailLoading}
        >
          <div>Proceed</div>
          {isEmailLoading && <div className="spinner"></div>}
        </button>
      </div>
    </div>
  );

  const SendMailSuccess = () => (
    <div className="d-flex flex-column mx-4 mt-5 align-items-center">
      <div className="success-mail-text text-black">
        Mail sent with GRN details to Category Team. Please wait for their
        response.
      </div>
      <button
        className="btn btn-guide my-4"
        onClick={() => {
          mailerSuccessModalCloseRef.current.click();
          navigate("../grnlist");
          clearGrnData();
          setShowContent(false);
        }}
      >
        Back
      </button>
    </div>
  );

  // const MismatchCreateGdn = () => (
  //   <div className="d-flex flex-column mx-4 mt-5 align-items-center">
  //     <div className="d-flex mb-3">
  //       <img src={ErrorIcon} alt="error" className="error-icon" />
  //       &nbsp;&nbsp;
  //       <div className="mismatch-header-text">Error</div>
  //     </div>
  //     <div className="mismatch-text">
  //       Invoice Amount Mismatch. Create/Edit GDN?
  //     </div>
  //     <div className="d-flex justify-content-around w-75 mb-5">
  //       <button
  //         className="btn btn-warning btn-create-gdn mt-5"
  //         onClick={() => {
  //           createGDNModalCloseRef.current.click();
  //           navigate("../gdn");
  //         }}
  //       >
  //         Create/Edit GDN
  //       </button>
  //       <button
  //         className="btn btn-preview mt-5 ms-3"
  //         onClick={() => {
  //           createGDNModalCloseRef.current.click();
  //         }}
  //       >
  //         Cancel
  //       </button>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="home d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          progressBar={false}
          headerText={"GRN Summary"}
        />

        {showContent && !isGrnWebLoading && (
          <>
            <SubmitForm
              supplier={supplier}
              invoiceAmount={invoiceAmount}
              invoiceDate={invoiceDate}
              po={po}
              invoiceNo={invoiceNo}
              widthIsSmall={widthIsSmall}
              amendMrpItems={amendMrpItems}
              grnData={grnData}
              skuCount={skuCount}
            />
            <ContentView
              grnData={grnData}
              submitGrn={checkAmendExist}
              isSubmitLoading={isRgLoading}
            />
          </>
        )}
        {isGrnWebLoading && (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "10%" }}
          >
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {!showContent && !isGrnWebLoading && (
          <div
            className="d-flex flex-column align-items-center"
            style={{ marginTop: "10%" }}
          >
            <div className="mb-3 no-records p-0">No records found</div>
            <button
              className="btn btn-proceed"
              onClick={() => navigate("/grnlist")}
            >
              Go back to the list
            </button>
          </div>
        )}

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <CommonModal
          modalCloseRef={modalRef}
          modalOpenRef={successRef}
          id={"exampleModal"}
          header={true}
          headerText={"Data submitted successfully"}
          content={<DataSubmitted />}
          modalSize={"lg"}
          headerBorder={false}
        />

        <CommonModal
          modalCloseRef={poSuccessCloseRef}
          modalOpenRef={poSuccessOpenRef}
          id={"poSuccessModal"}
          header={true}
          headerText={"PO Refreshed successfully"}
          content={<DataSubmitted isAmendMrp={true} />}
          modalSize={"lg"}
          headerBorder={false}
        />

        <CommonModal
          modalOpenRef={rgSuccessModalOpenRef}
          modalCloseRef={rgSuccessModalCloseRef}
          id={"rgSuccessModal"}
          content={<RGSuccess />}
          header={true}
          headerBorder={false}
          modalSize={"sm"}
          onModalClose={() => rgSuccessModalCloseRef.current.click()}
        />

        <CommonModal
          modalOpenRef={gdnSubmitOpenRef}
          modalCloseRef={gdnSubmitCloseRef}
          id={"gdnSubmitModal"}
          content={<RgGdnSubmit />}
          header={true}
          headerBorder={false}
          modalSize={"md"}
          onModalClose={() => gdnSubmitCloseRef.current.click()}
        />

        <CommonModal
          modalOpenRef={invoiceMismatchModalOpenRef}
          modalCloseRef={invoiceMismatchModalCloseRef}
          id={"invoiceModal"}
          content={<InvoiceAMountMismatch />}
          header={true}
          headerBorder={false}
          onModalClose={() => invoiceMismatchModalCloseRef.current.click()}
        />

        <CommonModal
          modalOpenRef={rgCheckValidationModalOpenRef}
          modalCloseRef={rgCheckValidationModalCloseRef}
          id={"rgCheckInvalid"}
          content={<RgCheckValidationModal />}
          header={true}
          headerBorder={false}
          onModalClose={() => rgCheckValidationModalCloseRef.current.click()}
        />

        <CommonModal
          modalOpenRef={amendModalConfirmOpenRef}
          modalCloseRef={amendModalConfirmCloseRef}
          id={"amendConfirmModal"}
          modalSize={"md"}
          content={<ConfirmAmendSubmit />}
        />

        <CommonModal
          modalCloseRef={successGdnModalCloseRef}
          modalOpenRef={successGdnModalOpenRef}
          id={"successGdnSubmit"}
          content={<GdnSubmitSuccess />}
        />

        <CommonModal
          modalOpenRef={amendModalOpenRef}
          modalCloseRef={amendModalCloseRef}
          id={"amendModal"}
          modalSize={"lg"}
          content={
            <AmendedMrp
              grnData={grnData}
              closeModal={() => {
                amendModalCloseRef.current.click();
                setIsAmendSubmitLoading(false);
              }}
              confirmSubmit={onAmendMrpSubmit}
              isAmendSubmitLoading={isAmendSubmitLoading}
              widthIsSmall={widthIsSmall}
            />
          }
        />

        {/* <CommonModal
          modalCloseRef={createGDNModalCloseRef}
          modalOpenRef={createGDNModalOpenRef}
          id={"createGDNModal"}
          content={<MismatchCreateGdn />}
        /> */}

        <CommonModal
          modalCloseRef={mailerModalCloseRef}
          modalOpenRef={mailerModalOpenRef}
          id={"mailerModal"}
          content={<SendMail />}
        />

        <CommonModal
          modalCloseRef={mailerSuccessModalCloseRef}
          modalOpenRef={mailerSuccessModalOpenRef}
          id={"mailerSuccessModal"}
          content={<SendMailSuccess />}
        />

        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    grnList: state?.grn?.grnList,
    grnData: state?.grn?.grnData,
    supplier: state?.grn?.supplier,
    invoiceAmount: state?.grn?.invoiceAmount,
    invoiceDate: state?.grn?.invoiceDate,
    rgCheck: state?.grn?.rgCheck,
    po: state?.grn?.po,
    invoiceNo: state?.grn?.invoiceNo,
    receiptId: state?.grn?.receiptId,
    skuCount: state?.grn?.skuCount,
    supplierId: state?.grn?.supplierId,
    gdnDate: state?.grn?.gdnDate,
    widthIsSmall: state?.auth?.widthIsSmall,
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    userId: state?.auth?.user?.userId,
    gdnCheck: state?.grn?.gdnCheck,
    softallocCheck: state?.grn?.softallocCheck,
    gdnNo: state?.grn?.gdnNo,
    previewFileName: state?.grn?.previewFileName,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGrnList: (data) => dispatch(grnActions.addGrnList(data)),
    addGrnData: (data) => dispatch(grnActions.addGrnData(data)),
    updatePO: (data) => dispatch(grnActions.updatePO(data)),
    updateInvoiceNo: (data) => dispatch(grnActions.updateInvoiceNo(data)),
    addRgCheck: (data) => dispatch(grnActions.addRgCheck(data)),
    updateRgChecks: (data) => dispatch(grnActions.updateRgChecks(data)),
    clearGrnData: (data) => dispatch(grnActions.clearGrnData(data)),
    addPreviewFileName: (data) => dispatch(grnActions.addPreviewFileName(data)),
    updateGdnNo: (data) => dispatch(grnActions.updateGdnNo(data)),
    markRgCheckDone: (data) => dispatch(grnActions.markRgCheckDone(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Grn);
