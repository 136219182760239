import RootRoute from "./routes";
import "./App.css";

function App() {
  return (
    <>
      <RootRoute />
    </>
  );
}

export default App;
