import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { authActions } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import MainHeader from "../../containers/header";
import "./profile.css";

const Profile = ({ ...props }) => {
  const [roles, setRoles] = useState([]);
  const [profileSelected, setProfileSelected] = useState("");
  const [changeLoading, setchangeLoading] = useState(false);
  const [profileChanged, setProfileChanged] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const { roleList, dispatchChangeRole, user, widthIsSmall } = props;

  useEffect(() => {
    let rolesForUser = [...roleList].sort((a, b) => a.loc - b.loc) || [];
    setRoles(rolesForUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileChanged) {
      setTimeout(() => {
        navigate("/home");
        window.location.reload(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileChanged]);

  const onRoleChange = () => {
    try {
      setIsError(false);
      if (!profileSelected) {
        setIsError(true);
        return;
      }
      setchangeLoading(true);

      const locDetails = profileSelected?.split("-");
      const profileExist = roles?.find(
        (role) =>
          String(role?.loc) === locDetails[0] && role?.role === locDetails[1]
      );
      dispatchChangeRole({
        ...user,
        role: profileExist?.role,
        loc: Number(profileExist?.loc),
        locationName: profileExist?.loc_name,
      });

      setProfileChanged(true);
    } catch (error) {
      setchangeLoading(false);
    }
  };

  return (
    <div className="profile">
      <MainHeader />
      <div
        className={[
          "d-flex flex-column align-items-center justify-content-center",
          widthIsSmall ? "profile-div-sm" : "profile-div",
        ].join(" ")}
      >
        <div className="profile-head">Change User Profile</div>
        <div className="my-4 w-100">
          <select
            className={[
              "form-select role-select form-select-md",
              isError && "border-red",
            ].join(" ")}
            onChange={(e) => setProfileSelected(e.target.value)}
            defaultValue={""}
          >
            <option disabled value={""}>
              Select Profile
            </option>
            {roles?.map((role, i) => (
              <option
                className="profile-option"
                key={i}
                value={`${role?.loc}-${role?.role}`}
              >{`${role?.loc} ${role?.role} - ${role?.loc_name}`}</option>
            ))}
          </select>
          {isError && (
            <div className="error-text text-end">Please select profile</div>
          )}
        </div>
        <button
          className="btn btn-logout d-flex align-items-center"
          disabled={changeLoading}
          onClick={onRoleChange}
        >
          <div>Change Profile</div>
          {changeLoading && <div className="ms-2 spinner"></div>}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    roleList: state?.auth?.user?.roleList,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchChangeRole: (data) => dispatch(authActions.changeRole(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
