import "./grn_content_view.css";

const GrnContentView = ({
  grndashboard,
  isDashboardLoading,
  onManualGDN,
  isGdnLoading,
  manualSelected,
}) => {
  return (
    <div className="cv-table cv dash-cv d-flex flex-column">
      <div className="content-table">
        <table className="table">
          <thead>
            <tr>
              <th>Order No</th>
              <th>Supplier</th>
              <th>Receipt ID</th>
              <th>Invoice Date</th>
              <th>SKU's Received</th>
              <th>Invoice/DC No</th>
              <th className="th-input">GRN</th>
              <th className="th-input">GDN</th>
              <th>Soft Allocation</th>
              <th>Manual GDN</th>
            </tr>
          </thead>
          <tbody>
            {grndashboard?.map((grn, i) => (
              <tr key={i}>
                <td>{grn?.order_no}</td>
                <td>{grn?.sup_name}</td>
                <td>{grn?.receipt_id}</td>
                <td>{grn?.invoice_date || "-"}</td>
                <td>{grn?.item_count || 0}</td>
                <td>{grn?.invoice_no}</td>
                <td>
                  <button
                    className="btn btn-grn"
                    disabled={!grn?.grn_doc_link_url}
                  >
                    <a
                      className="text-white text-decoration-none"
                      href={grn?.grn_doc_link_url}
                    >
                      View GRN
                    </a>
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-gdn"
                    disabled={!grn?.gdn_doc_link_url}
                  >
                    <a
                      className="text-white text-decoration-none"
                      href={grn?.gdn_doc_link_url}
                    >
                      View GDN
                    </a>
                  </button>
                </td>
                <td>{grn?.soft_alloc_msg || "-"}</td>
                <td>
                  <button
                    className="d-flex align-items-center btn btn-warning text-nowrap"
                    onClick={() => {
                      onManualGDN(grn?.order_no, grn?.invoice_no);
                    }}
                    disabled={
                      isGdnLoading ||
                      grn?.man_gdn_btn === "disable" ||
                      grn?.gdn_doc_link_url
                    }
                  >
                    <div>Manual GDN</div>
                    {isGdnLoading &&
                      manualSelected?.po === grn?.order_no &&
                      manualSelected?.invoiceNo === grn?.invoice_no && (
                        <div className="spinner ms-2"></div>
                      )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDashboardLoading && (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "5%" }}
        >
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {!grndashboard?.length && !isDashboardLoading && (
        <div className="d-flex justify-content-center no-records">
          No records found
        </div>
      )}
    </div>
  );
};
export default GrnContentView;
