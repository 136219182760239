import { useEffect, useState } from "react";
import "./amended_mrp.css";

const AmendedMrp = ({
  grnData,
  confirmSubmit,
  closeModal,
  isAmendSubmitLoading,
  widthIsSmall,
}) => {
  const [isAmendConfirm, setIsAmendConfirm] = useState(false);

  useEffect(() => {
    checkAmendExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grnData]);

  const checkAmendExist = () => {
    let amendListExist = grnData?.filter(
      (grn) => grn?.mrp !== grn?.amended_mrp && grn?.margin_type && grn?.lpn
    );
    if (amendListExist?.length) {
      setIsAmendConfirm(true);
    } else {
      setIsAmendConfirm(false);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={[
          "am-subtext text-red",
          widthIsSmall ? "mb-3" : "mb-5 mx-5 mt-3",
        ].join(" ")}
      >
        Item MRP was changed for the following items. Click on Amend MRP and
        complete PO Refresh
      </div>

      <div className={widthIsSmall ? "px-1" : "px-5"}>
        <table className="table">
          <thead>
            <tr>
              <th>ITEM_ID</th>
              <th>MRP</th>
              <th>Amended MRP</th>
            </tr>
          </thead>
          <tbody>
            {grnData?.map(
              (grn, i) =>
                grn?.mrp !== grn?.amended_mrp &&
                grn?.margin_type &&
                grn?.lpn && (
                  <tr key={i}>
                    <td>{grn?.item || "-"}</td>
                    <td>{grn?.mrp || 0}</td>
                    <td>{grn?.amended_mrp || 0}</td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>

      <div className="align-items-center d-flex justify-content-end mt-4 pt-4 mb-4">
        <button className="btn btn-guide" onClick={() => closeModal()}>
          Cancel
        </button>
        <button
          className="btn btn-preview d-flex align-items-center ms-4"
          onClick={() => confirmSubmit()}
          disabled={isAmendSubmitLoading || !isAmendConfirm}
        >
          <div>PO Refresh</div>
          {isAmendSubmitLoading && <div className="spinner ms-3"></div>}
        </button>
      </div>
    </div>
  );
};
export default AmendedMrp;
