import { useEffect, useState } from "react";
import DownloadExcel from "../download_excel";
import Pagination from "../pagination";
import CloseIcon from "../../assets/icons/close-dark.svg";
import moment from "moment";
import "./report.css";

const ReportTemplate = ({
  tableHeads,
  itemKeys,
  data,
  isTableLoading,
  widthIsSmall,
  loc,
  buttonName,
  filePrefix,
  searchText,
  addPageData,
  allData,
  pageCount,
  filteredData,
  type = "all",
  totalRecords = 0,
  changePage,
  onDownloadUrl,
  isdownloadLoading = false,
}) => {
  const [currentPage, setcurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (type === "all") {
      paginate(currentPage);
    } else {
      changePage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData, currentPage, filteredData]);

  const paginate = (pageSelected) => {
    let dataToBeSearched =
      searchValue === "" ? [...allData] : [...filteredData];
    const pageData = dataToBeSearched?.slice(
      pageSelected * pageCount,
      pageSelected * pageCount + pageCount
    );
    addPageData(pageData);
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="rt-table"
        style={{ padding: widthIsSmall ? "25px" : "40px" }}
      >
        <div className="ms-auto w-25 mb-2 rp-search">
          <input
            className="form-control"
            type={"text"}
            placeholder="Search data"
            onChange={(e) => {
              if (!isTableLoading) {
                setSearchValue(e.target.value);
                searchText(e.target.value);
                setcurrentPage(0);
              }
            }}
            value={searchValue}
          />
          {searchValue !== "" && (
            <img
              src={CloseIcon}
              alt="close"
              className="rp-search-close"
              onClick={() => {
                setSearchValue("");
                searchText("");
                setcurrentPage(0);
              }}
            />
          )}
        </div>
        <table className="table">
          <thead>
            <tr>
              {tableHeads?.map((item, i) => (
                <th key={i}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => (
              <tr key={i}>
                {itemKeys?.map((key, j) => (
                  <td key={j}>{item[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isTableLoading && (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "5%" }}
          >
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {!data?.length && !isTableLoading && (
          <div className="d-flex justify-content-center no-records">
            No records found
          </div>
        )}
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <Pagination
          data={searchValue === "" ? allData : filteredData}
          dataCount={pageCount}
          pageSelected={(pageNo) => setcurrentPage(pageNo)}
          currentPage={currentPage}
          type={type}
          totalRecords={totalRecords}
        />
      </div>
      {type === "all" ? (
        <div className="d-flex justify-content-center my-5">
          <DownloadExcel
            data={filteredData}
            fileName={`${filePrefix}_${loc}_${moment(new Date()).format(
              "YYYY-MM-DD"
            )}`}
            buttonName={buttonName}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center my-5">
          <button
            className="btn btn-download-lpn d-flex align-items-center"
            onClick={() => onDownloadUrl()}
            disabled={!data?.length || isdownloadLoading}
          >
            <div>{buttonName}</div>
            {isdownloadLoading && <div className="spinner ms-2"></div>}
          </button>
        </div>
      )}
    </div>
  );
};
export default ReportTemplate;
