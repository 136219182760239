import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { extractListChange, pendingToListChange } from "../../api";
import ErrorModal from "../../containers/error_modal";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import DownloadExcel from "../../containers/download_excel";
import ExtractExcel from "../../containers/extract_excel";
import CommonModal from "../../containers/common_modal";
import moment from "moment";

const ExtractionList = ({ ...props }) => {
  const [toList, setToList] = useState([]);
  const [allToList, setAllToList] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [lambdaError, setLambdaError] = useState("");
  const [extractId, setExtractId] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  const [isExtractLoading, setIsExtractLoading] = useState(false);
  const fileRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const uploadConfirmOpenRef = useRef();
  const uploadConfirmCloseRef = useRef();
  const uploadOpenRef = useRef();
  const uploadCloseRef = useRef();

  const { loc, locationName, widthIsSmall, username } = props;

  useEffect(() => {
    getPendingToList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPendingToList = async () => {
    try {
      setIsListLoading(true);
      let payload = {
        loc: loc,
      };

      const pendingToListData = await pendingToListChange(payload);

      if (pendingToListData) {
        if (pendingToListData?.status === "error") {
          if (
            pendingToListData?.error_message &&
            pendingToListData?.error_message === "Open orders not available"
          ) {
            setIsListLoading(false);
          } else {
            setLambdaError(pendingToListData?.error_message);
            openErrRef.current.click();
            setIsListLoading(false);
          }
        } else {
          if (Object.keys(pendingToListData?.body)) {
            let formattedData = pendingToListData?.body?.map((item) => {
              return {
                order_no: item?.order_no,
                order_type:item?.order_type,
                item_count: item?.item_count,
                total_qty: item?.total_qty,
                store: item?.to_loc,
              };
            });
            setToList(formattedData);
            setAllToList(formattedData);
            setIsListLoading(false);
          }
        }
      } else {
        setIsListLoading(false);
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
      }
    } catch (error) {
      console.log(error);
      setIsListLoading(false);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const onExtractList = async () => {
    try {
      setExtractId("");
      setIsExtractLoading(true);

      let orderList = uploadData
        .map((item) => {
          return {
            order_no: item?.order_no,
          };
        })
        .filter((item) => item);

      let payload = {
        user_id: String(username),
        details: orderList,
      };

      const extractData = await extractListChange(payload);

      if (extractData) {
        fileRef.current.value = "";
        if (extractData?.status === "error" || extractData?.error_message) {
          uploadConfirmCloseRef.current.click();
          setLambdaError(
            extractData?.error_message === "Please contact administrator"
              ? "Please contact administrator"
              : "Please contact IT support"
          );
          openErrRef.current.click();
        } else if (extractData?.status === "success") {
          setExtractId(extractData?.body);
          uploadConfirmCloseRef.current.click();
          uploadOpenRef.current.click();
        }
        setIsExtractLoading(false);
      } else {
        uploadConfirmCloseRef.current.click();
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsExtractLoading(false);
      }
    } catch (error) {
      console.log(error);
      uploadConfirmCloseRef.current.click();
      setLambdaError(error);
      openErrRef.current.click();
      setIsExtractLoading(false);
    }
  };

  const searchReport = (data) => {
    const pendingData = [...allToList];
    const filteredData =
      data?.length &&
      pendingData.filter((item) => {
        return (
          String(item?.order_no).toLowerCase().includes(data.toLowerCase()) ||
          String(item?.store).toLowerCase().includes(data.toLowerCase())
        );
      });
    if (filteredData && filteredData.length) {
      setToList(filteredData);
    }
    if (data === "") {
      setToList(allToList);
    }
  };

  const showValidationError = (err) => {
    uploadConfirmCloseRef.current.click();
    setLambdaError(err);
    openErrRef.current.click();
  };

  const confirmFileUpload = (data, fileType, type) => {
    setIsContentError(false);
    fileRef.current.value = "";

    if (
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      showValidationError(
        "Invalid file format. Please reupload a proper file format or refer the template file."
      );
      return;
    }

    if (!data?.length) {
      showValidationError(
        "Blank file. Please insert data in file and reupload."
      );
      return;
    }

    const formattedUploadData = data
      .map((item) => {
        if (!item?.order_no) {
          return null;
        } else {
          return item;
        }
      })
      .filter((item) => item);

    setUploadData(formattedUploadData);

    const allKeysNotPresent = formattedUploadData?.find(
      (item) => !item?.order_no
    );

    if (allKeysNotPresent) {
      setIsContentError(true);
    }
    uploadConfirmOpenRef.current.click();
  };

  const UploadConfirm = () => (
    <div
      className={["d-flex flex-column", !widthIsSmall && "up-confirm"].join(
        " "
      )}
    >
      <div
        className={["up-head-text mb-3", widthIsSmall && "text-wrap"].join(" ")}
      >
        Please confirm before submitting Extract data
      </div>
      <div className="mkt-table p-0 m-0">
        <table className="table">
          <thead>
            <tr>
              <th>Order No.</th>
              <th>Order Type</th>
              <th>No of SKU's</th>
              <th>Total Qty</th>
              <th>Store</th>
            </tr>
          </thead>
          <tbody>
            {uploadData?.map((item, i) => (
              <tr key={i}>
                <td>{item?.order_no || "-"}</td>
                <td>{item?.order_type || "-"}</td>
                <td>{item?.item_count || "-"}</td>
                <td>{item?.total_qty || "-"}</td>
                <td>{item?.store || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-column mt-auto">
        {isContentError && (
          <div
            className={["up-error my-3", widthIsSmall && "text-wrap"].join(" ")}
          >
            Missing content in file or wrong data. Please check the file and
            reupload !!!
          </div>
        )}
        <div className={"d-flex ms-auto mt-3"}>
          <button
            className="btn btn-guide me-5"
            onClick={() => {
              uploadConfirmCloseRef.current.click();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-preview d-flex align-items-center"
            onClick={onExtractList}
            disabled={isExtractLoading || isContentError}
          >
            <div>Confirm</div>
            {isExtractLoading && <div className="spinner ms-2"></div>}
          </button>
        </div>
      </div>
    </div>
  );

  const UploadSuccess = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Data Uploaded Successfully</div>
        <div className="warn-text">Extract ID : {extractId}</div>
        &nbsp;&nbsp;&nbsp;
        <button
          className="btn btn-preview mt-3"
          onClick={() => {
            uploadCloseRef.current.click();
            setToList([]);
            getPendingToList();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="ls-grn"
        style={{
          padding: widthIsSmall ? "8px" : "8px 24px",
        }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          progressBar={false}
          headerText={"Extraction"}
        />
        <div
          className="grn-list d-flex flex-column align-items-center position-relative"
          style={{ height: "80vh" }}
        >
          <div className="d-flex align-items-center ms-auto mb-3 w-50 justify-content-end">
            <input
              type={"text"}
              placeholder={"Search Order or Store..."}
              className={"form-control ms-3 w-50"}
              onChange={(e) => searchReport(e.target.value)}
            />
          </div>
          <div className="mkt-table w-100">
            <table className="table">
              <thead>
                <tr>
                  <th>Order No.</th>
                  <th>Order Type</th>
                  <th>No of SKU's</th>
                  <th>Total Qty</th>
                  <th>Store</th>
                </tr>
              </thead>
              <tbody>
                {toList?.map((grn, i) => (
                  <tr key={i}>
                    <td>{grn?.order_no || "-"}</td>
                    <td>{grn?.order_type || "-"}</td>
                    <td>{grn?.item_count || "-"}</td>
                    <td>{grn?.total_qty || "-"}</td>
                    <td>{grn?.store || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isListLoading && (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "5%" }}
              >
                <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            {!toList?.length && !isListLoading && (
              <div className="d-flex justify-content-center no-records">
                No records found
              </div>
            )}
          </div>

          <div className="align-items-center d-flex justify-content-center mt-4 w-100">
            <DownloadExcel
              data={allToList}
              fileName={`Extract_report_ ${loc}_${moment(new Date()).format(
                "YYYY-MM-DD"
              )}`}
              buttonName={"Export To Excel"}
            />
            <div className="ms-5">
              <ExtractExcel
                buttonName={"Upload For Extract"}
                width={widthIsSmall ? "100%" : "150px"}
                onSubmit={(data, fileType, type) =>
                  confirmFileUpload(data, fileType, type)
                }
                inputRef={fileRef}
                extractType={"nftExtract"}
              />
            </div>
          </div>

          <CommonModal
            modalCloseRef={uploadConfirmCloseRef}
            modalOpenRef={uploadConfirmOpenRef}
            id={"uploadModal"}
            content={<UploadConfirm />}
            modalSize={"lg"}
          />

          <CommonModal
            modalCloseRef={uploadCloseRef}
            modalOpenRef={uploadOpenRef}
            id={"uploadSuccessModal"}
            content={<UploadSuccess />}
            header={true}
            headerBorder={false}
            onModalClose={() => getPendingToList()}
          />

          <ErrorModal
            id={"errorModal"}
            openRef={openErrRef}
            closeRef={closeErrRef}
            headerColor={"red"}
            msg={lambdaError}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    grnList: state?.grn?.grnList,
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtractionList);
