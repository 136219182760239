import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getMergeToSummaryChange } from "../../api";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import ReportTemplate from "../../containers/report_template";
import ErrorModal from "../../containers/error_modal";

const MergeTOSummary = ({ ...props }) => {
  const { loc, locationName, widthIsSmall } = props;
  const [mergeToList, setMergeToList] = useState([]);
  const [allMergeToList, setAllMergeToList] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [itemKeys, setItemKeys] = useState([]);
  const [lambdaError, setLambdaError] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const [isdownloadLoading, setIsdownloadLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const tableHeads = [
    "Order no.",
    "item",
    "Supplier",
    "Supplier name",
    "Order ind",
    "Order type",
    "order qty",
    "Sector",
    "Child dc",
    "Child TO",
    "Create date",
    "expiry date",
  ];

  useEffect(() => {
    getMergeToSummaryData();
  }, []);

  useEffect(() => {
    getMergeToSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText]);

  const getMergeToSummaryData = async () => {
    try {
      setMergeToList([]);
      setIsListLoading(true);
      let payload = {
        loc: loc,
        start: currentPage * 10,
        length: 10,
        search_key: searchText,
        file: false,
      };
      const resData = await getMergeToSummaryChange(payload);

      if (resData) {
        if (resData?.status === "error") {
          setLambdaError(resData?.error_message);
          openErrRef.current.click();
        } else {
          if (Object.keys(resData?.body?.records)?.length) {
            let formattedData = resData?.body?.records?.map((to) => {
              return {
                order_no: to?.order_no,
                item: to?.item,
                supplier: to?.supplier,
                sup_name: to?.sup_name,
                order_ind: to?.order_ind,
                order_type: to?.order_type,
                order_qty: to?.order_qty,
                sector: to?.sector,
                child_dc: to?.child_dc,
                child_to: to?.child_to,
                create_date: to?.create_date,
                expiry_date: to?.expiry_date,
              };
            });
            setMergeToList(formattedData);
            setAllMergeToList(formattedData);
            setfilteredData(formattedData);
            setItemKeys(Object.keys(formattedData[0]));
            setTotalRecords(resData?.body?.totalRecords);
          } else {
            setTotalRecords(resData?.body?.totalRecords);
          }
        }
        setIsListLoading(false);
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsListLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsListLoading(false);
    }
  };

  const onDownLoadS3Url = async () => {
    try {
      setIsdownloadLoading(true);
      let payload = {
        loc: loc,
        start: 0,
        length: 0,
        search_key: "",
        file: true,
      };
      const resData = await getMergeToSummaryChange(payload);
      if (resData) {
        if (resData?.status === "error") {
          setLambdaError(resData?.error_message);
          openErrRef.current.click();
        } else {
          if (resData?.body?.file_url) {
            window.open(resData?.body?.file_url);
          }
        }
      } 
      // else {
      //   setLambdaError("Something went wrong");
      //   openErrRef.current.click();
      // }
      setIsdownloadLoading(false);
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsdownloadLoading(false);
    }
  };

  const addPageData = (pageData) => {
    setMergeToList(pageData);
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="lpn-hm d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          headerText={"Merge TO Summary"}
        />
        <ReportTemplate
          widthIsSmall={widthIsSmall}
          tableHeads={tableHeads}
          itemKeys={itemKeys}
          data={mergeToList}
          isTableLoading={isListLoading}
          loc={loc}
          buttonName={"Download Merge TO Summary"}
          filePrefix={"merge_to_summary"}
          searchText={(text) => setSearchText(text)}
          allData={allMergeToList}
          addPageData={addPageData}
          pageCount={10}
          filteredData={filteredData}
          totalRecords={totalRecords}
          type={"paginationFromDb"}
          changePage={(pageNo) => setCurrentPage(pageNo)}
          onDownloadUrl={onDownLoadS3Url}
          isdownloadLoading={isdownloadLoading}
        />
        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MergeTOSummary);
