import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  docVerificationChange,
  filePresignedUrlChange,
  textractChange,
  uploadFile,
} from "../../api";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import ErrorModal from "../../containers/error_modal";
import CommonModal from "../../containers/common_modal";
import guidelines from "../../data/guidelines.pdf";
import DatePicker from "react-datepicker";
import moment from "moment";
import CalendarIcon from "../../assets/icons/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./upload.css";

const UploadInvoice = ({ ...props }) => {
  const { loc, locationName, widthIsSmall , username,} = props;

  const invoiceUploadOpenRef = useRef();
  const invoiceUploadCloseRef = useRef();
  const invoiceUploadPreviewOpenRef = useRef();
  const invoiceUploadPreviewCloseRef = useRef();
  const inputFileRef = useRef();
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const openFileErrRef = useRef();
  const closeFileErrRef = useRef();
  const toSaveSuccessOpenRef = useRef();
  const toSaveSuccessCloseRef = useRef();

  const [po, setPo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [extractRemark, setExtractRemark] = useState("");
  const [supplier, setSupplier] = useState("");
  const [isPoVerifying, setIsPoVerifying] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isToSaveLoading, setIsToSaveLoading] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [poVerified, setPoVerified] = useState(false);
  const [isInvoiceError, setIsInvoiceError] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [lambdaError, setLambdaError] = useState("");
  const [textractInvoiceAmount, setTextractInvoiceAmount] = useState(0);
  const [previewFileUrl, setPreviewFileUrl] = useState("");
  const [orderInd, setOrderInd] = useState("");

  useEffect(() => {
    if (!po || !invoiceNo || !invoiceDate || !invoiceAmount) {
      setIsButtonDisable(true);
    } else {
      setIsButtonDisable(false);
    }
  }, [po, invoiceNo, invoiceDate, invoiceAmount]);

  const verifyPo = async () => {
    try {
      setOrderInd("");
      setExtractRemark("");
      setSupplier("");
      const validateData = validateForm();
      if (!validateData) return;

      setIsInvoiceError(false);
      if (invoiceAmount < 1) {
        setIsInvoiceError(true);
        return;
      }

      setIsPoVerifying(true);
      const poDetails = await docVerificationChange({
        order_no: parseInt(po),
        invoice_no: invoiceNo,
        source: "WEB",
        loc: loc,
      });

      if (poDetails) {
        if (poDetails?.status === "error") {
          invokeToast(poDetails?.error_message, "failed");
          setIsPoVerifying(false);
        } else if (poDetails?.status === "success") {
          if (poDetails?.body?.length) {
            setOrderInd(poDetails?.body?.[0]?.order_ind);
            setSupplier(poDetails?.body?.[0]?.supplier);
          }
          setIsPoVerifying(false);
          setPoVerified(true);
          invokeToast(
            `Order verified. Proceed to ${
              poDetails?.body?.[0]?.order_ind === "TRANSFER"
                ? "save TO."
                : "upload invoice."
            }`,
            "success"
          );
        }
      } else {
        setIsPoVerifying(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generatePresignedUploadUrl = async (filetoUpload) => {
    try {
      setPreviewFileUrl("");
      setIsUploadLoading(true);
      setTextractInvoiceAmount(0);
      const generatedUrl = await filePresignedUrlChange({
        file_name: filetoUpload?.name,
      });

      if (generatedUrl?.url) {
        let fileUploaded = await uploadFileWithPresignedUrl(
          filetoUpload,
          generatedUrl
        );
        if (fileUploaded) {
          return true;
        } else {
          return false;
        }
      } else {
        setIsUploadLoading(false);
        // setLambdaError("Failed to upload file");
        // openErrRef.current.click();
        // return false;
      }
    } catch (error) {
      setIsUploadLoading(false);
      setLambdaError("Failed to upload file");
      openErrRef.current.click();
    }
  };

  const uploadFileWithPresignedUrl = async (filetoUpload, generatedUrl) => {
    try {
      inputFileRef.current.value = "";

      const fileUploadData = new FormData();
      Object.keys(generatedUrl.fields).forEach((key) => {
        fileUploadData.append(key, generatedUrl.fields[key]);
      });
      fileUploadData.append("file", filetoUpload);

      const fileUploaded = await uploadFile(generatedUrl.url, fileUploadData);
      if (fileUploaded) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setIsUploadLoading(false);
      setLambdaError("Failed to upload file");
      openErrRef.current.click();
    }
  };

  const onFileUpload = async (file) => {
    try {
      if (file.type !== "application/pdf") {
        setLambdaError("Invalid file format");
        openFileErrRef.current.click();
        inputFileRef.current.value = "";
        return;
      }
      let fileUploaded = await generatePresignedUploadUrl(file);

      if (fileUploaded) {
        let payload = {
          loc: loc,
          order_no: parseInt(po),
          invoice_no: invoiceNo,
          invoice_date: moment(invoiceDate).format("YYYY-MM-DD"),
          invoice_amount: parseFloat(invoiceAmount) || 0,
          invoice_img_link: file?.name,
          order_ind: orderInd,
          supplier: supplier,
          user_id: String(username),
        };

        let textract = await textractChange(payload);

        if (textract) {
          if (textract?.status === "error") {
            setLambdaError(textract?.error_message);
            openErrRef.current.click();
            setIsUploadLoading(false);
          } else {
            setExtractRemark(textract?.body?.extract_remark);
            setTextractInvoiceAmount(textract?.body?.extracted_amount);
            setPreviewFileUrl(textract?.body?.url);
            if (textract?.body?.extract_remark === "UNMATCHED") {
              // setTextractInvoiceAmount(textract?.body?.extracted_amount);
              invoiceUploadPreviewOpenRef.current.click();
              setIsUploadLoading(false);
            } else if (textract?.body?.extract_remark === "UNDETECTED") {
              setLambdaError(
                "Unable to detect Invoice amount. Please make sure that the Invoice is in proper format and follows the guidelines."
              );
              openErrRef.current.click();
              setIsUploadLoading(false);
            } else {
              invoiceUploadPreviewOpenRef.current.click();
              // invoiceUploadOpenRef.current.click();
              setIsUploadLoading(false);
            }
          }
        } else {
          // setLambdaError("textract lambda failed");
          // openErrRef.current.click();
          setIsUploadLoading(false);
        }
      } else {
        setIsUploadLoading(false);
        // setLambdaError("Failed to upload file");
        // openErrRef.current.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onToOrderSave = async () => {
    try {
      setIsToSaveLoading(true);
      setTextractInvoiceAmount(0);

      let payload = {
        loc: loc,
        order_no: parseInt(po),
        invoice_no: invoiceNo,
        invoice_date: moment(invoiceDate).format("YYYY-MM-DD"),
        invoice_amount: parseFloat(invoiceAmount) || 0,
        invoice_img_link: "",
        order_ind: orderInd,
        supplier: supplier,
        user_id: String(username),
      };

      let textract = await textractChange(payload);

      if (textract) {
        if (textract?.status === "error") {
          setLambdaError(textract?.error_message);
          openErrRef.current.click();
          setIsToSaveLoading(false);
        } else {
          toSaveSuccessOpenRef.current.click();
          setIsToSaveLoading(false);
        }
      } else {
        // setLambdaError("textract lambda failed");
        // openErrRef.current.click();
        setIsToSaveLoading(false);
      }
    } catch (error) {
      setIsToSaveLoading(false);
      console.log(error);
    }
  };

  const clearFields = () => {
    setPo("");
    setInvoiceNo("");
    setInvoiceDate("");
    setInvoiceAmount("");
    setPoVerified(false);
    setOrderInd("");
  };

  const validateForm = () => {
    const amountRegex = /^\d{1,}(\.\d{0,4})?$/;
    if (amountRegex.test(invoiceAmount)) {
      return true;
    } else {
      setIsInvoiceError(true);
      return false;
    }
  };

  const invokeToast = (msg, type) => {
    type === "success"
      ? toast.success(msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      : toast.error(msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
  };

  const WarningContent = ({ amount }) => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column m-2">
        {(extractRemark === "UNMATCHED" && (
          <div className="mb-4 warn-text">Warning!!!</div>
        )) ||
          ""}
        <div className="mb-2">
          {extractRemark === "MATCHED"
            ? `Invoice amount matched the entered amount as Rs.${amount}.`
            : `System detected Invoice Total as Rs.${amount}. Please recheck amount or
          click on proceed if OK`}
        </div>
        <div className="d-flex justify-content-center mb-4 mt-3">
          {(extractRemark === "UNMATCHED" && (
            <a
              className="btn btn-guide me-3"
              href={guidelines}
              target="_blank"
              rel="noreferrer"
            >
              Guidelines
            </a>
          )) ||
            ""}
          <a className="btn btn-preview" href={previewFileUrl}>
            Preview
          </a>
        </div>
      </div>
      <button
        className="btn btn-proceed ms-auto"
        onClick={() => {
          invoiceUploadPreviewCloseRef.current.click();
          invoiceUploadOpenRef.current.click();
          clearFields();
        }}
      >
        Proceed
      </button>
    </div>
  );

  const InvoiceUploaded = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Invoice PDF Uploaded Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <div
          className="btn btn-preview mt-3"
          onClick={() => {
            invoiceUploadCloseRef.current.click();
            clearFields();
          }}
        >
          OK
        </div>
      </div>
    </div>
  );

  const ToSavedSuccess = () => (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column align-items-center mx-5">
        <div className="warn-text">Data Saved Successfully</div>
        &nbsp;&nbsp;&nbsp;
        <div
          className="btn btn-preview mt-3"
          onClick={() => {
            toSaveSuccessCloseRef.current.click();
            clearFields();
          }}
        >
          OK
        </div>
      </div>
    </div>
  );

  const ErrorPreview = () => (
    <div className="d-flex flex-column mb-4">
      <div className="d-flex justify-content-center mb-4 mt-3">
        <a
          className="btn btn-guide me-3"
          href={guidelines}
          target="_blank"
          rel="noreferrer"
        >
          Guidelines
        </a>
        <a className="btn btn-preview" href={previewFileUrl}>
          Preview
        </a>
      </div>
      <button
        className="btn btn-proceed ms-auto"
        onClick={() => {
          invoiceUploadPreviewCloseRef.current.click();
          invoiceUploadOpenRef.current.click();
          clearFields();
        }}
      >
        Proceed
      </button>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="up-inv d-flex flex-column w-100"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          progressBar={false}
          headerText={"Upload Invoices"}
        />
        <div className="up-inv-form">
          <div className={[widthIsSmall ? "" : "form w-50"]}>
            <div className="d-flex flex-column form-field">
              <div className="d-flex justify-content-between align-items-center">
                <label className="sf-label">Order No.</label>
                <input
                  onChange={(e) => setPo(e.target.value)}
                  className="form-control w-50"
                  type="text"
                  value={po}
                  disabled={poVerified}
                />
              </div>
            </div>
            <div className="d-flex flex-column form-field">
              <div className="d-flex justify-content-between align-items-center">
                <label className="sf-label">Invoice/DC No.</label>
                <input
                  onChange={(e) => setInvoiceNo(e.target.value)}
                  className="form-control w-50"
                  type="text"
                  value={invoiceNo}
                />
              </div>
            </div>
            <div className="d-flex flex-column form-field">
              <div className="d-flex justify-content-between align-items-center position-relative">
                <label className="sf-label">Invoice Date</label>
                <DatePicker
                  className="form-control"
                  selected={invoiceDate}
                  onChange={(date) => {
                    setInvoiceDate(date);
                    setOpenDatePicker(!openDatePicker);
                  }}
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="dd-mm-yy"
                  onInputClick={() => setOpenDatePicker(!openDatePicker)}
                  onClickOutside={() => setOpenDatePicker(!openDatePicker)}
                  open={openDatePicker}
                  includeDateIntervals={[
                    {
                      start: new Date(new Date().setDate(new Date().getDate() - 365)),
                      end: new Date(),
                    },
                  ]}
                />
                <img
                  className="calendar-icon"
                  src={CalendarIcon}
                  alt="calendar"
                  onClick={() => setOpenDatePicker(!openDatePicker)}
                />
              </div>
            </div>
            <div className="d-flex flex-column form-field">
              <div className="d-flex justify-content-between align-items-center">
                <label className="sf-label">Invoice Amount</label>
                <input
                  onChange={(e) => setInvoiceAmount(e.target.value)}
                  className={[
                    "form-control w-50",
                    isInvoiceError && "border-red",
                  ].join(" ")}
                  type="text"
                  value={invoiceAmount}
                />
              </div>
              {isInvoiceError && (
                <div className="error ms-auto">
                  Invoice amount should be greater than 0 and should have
                  maximum of 4 decimal places
                </div>
              )}
            </div>
          </div>
          <div className="d-flex align-items-baseline flex-column flex-lg-row flex-xl-row flex-md-row">
            <button
              className="btn btn-preview d-flex align-items-center justify-content-center"
              disabled={isPoVerifying || isButtonDisable}
              onClick={verifyPo}
              style={{
                width: widthIsSmall ? "100%" : "auto",
                marginBottom: widthIsSmall ? "10px" : "0px",
                marginTop: widthIsSmall ? "8px" : "0px",
              }}
            >
              <div>Verify</div>
              {isPoVerifying && <div className="spinner ms-3"></div>}
            </button>

            <button
              className="btn btn-more d-flex align-items-center ms-xl-5 ms-lg-5 ms-md-5 justify-content-center"
              disabled={orderInd !== "TRANSFER" || isToSaveLoading}
              onClick={onToOrderSave}
              style={{
                width: widthIsSmall ? "100%" : "auto",
                marginBottom: widthIsSmall ? "10px" : "0px",
              }}
            >
              <div>Save</div>
              {isToSaveLoading && <div className="spinner ms-3"></div>}
            </button>

            <div
              className="position-relative mt-xl-5 mt-lg-5 mt-md-5 ms-xl-5 ms-lg-5 ms-md-5"
              style={{
                width: widthIsSmall ? "100%" : "auto",
              }}
            >
              <button
                className="btn btn-upload-file me-5 d-flex align-items-center justify-content-center"
                disabled={
                  !poVerified ||
                  isUploadLoading ||
                  isButtonDisable ||
                  orderInd === "TRANSFER"
                }
                style={{
                  width: widthIsSmall ? "100%" : "auto",
                }}
              >
                <div>Upload Invoice PDF</div>
                {isUploadLoading && <div className="spinner ms-3"></div>}
              </button>
              <input
                className="upload-invoice"
                type={"file"}
                onChange={(e) => onFileUpload(e.target.files[0])}
                ref={inputFileRef}
                disabled={
                  isButtonDisable ||
                  isUploadLoading ||
                  !poVerified ||
                  orderInd === "TRANSFER"
                }
                style={{
                  width: widthIsSmall ? "100%" : "165px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <CommonModal
        modalCloseRef={invoiceUploadPreviewCloseRef}
        modalOpenRef={invoiceUploadPreviewOpenRef}
        id={"previewModal"}
        content={<WarningContent amount={textractInvoiceAmount} />}
        onModalClose={() => invoiceUploadPreviewCloseRef.current.click()}
        header={true}
        headerBorder={false}
      />

      <CommonModal
        modalCloseRef={invoiceUploadCloseRef}
        modalOpenRef={invoiceUploadOpenRef}
        id={"invoiceUploadModal"}
        content={<InvoiceUploaded />}
        header={true}
        headerBorder={false}
      />

      <CommonModal
        modalCloseRef={toSaveSuccessCloseRef}
        modalOpenRef={toSaveSuccessOpenRef}
        id={"toSaveSuccessModal"}
        content={<ToSavedSuccess />}
        header={true}
        headerBorder={false}
      />

      <ErrorModal
        id={"errorModal"}
        openRef={openErrRef}
        closeRef={closeErrRef}
        headerColor={"red"}
        msg={lambdaError}
        contents={<ErrorPreview />}
        pb={false}
        backDrop={true}
        // closeModal={clearFields}
      />

      <ErrorModal
        id={"fileErrorModal"}
        openRef={openFileErrRef}
        closeRef={closeFileErrRef}
        headerColor={"red"}
        msg={lambdaError}
        backDrop={true}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadInvoice);
