import { useState, useRef } from "react";
import { connect } from "react-redux";
import { processFlowTypes } from "../../constants/constants";
import { grnActions } from "../../store/grn";
import { adminConsoleChange, grnListChange } from "../../api";
import ErrorModal from "../../containers/error_modal";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import Header from "../../containers/content_header";
import CommonModal from "../../containers/common_modal";
import BackIcon from "../../assets/icons/back-arrow.svg";
import "./admin.css";

const AdminConsole = ({ ...props }) => {
  const { loc, locationName, widthIsSmall, grnList, addGrnList } = props;

  const [processLoadComplete, setProcessLoadComplete] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isProceedLoading, setIsProceedLoading] = useState(false);
  const [processSelected, setProcessSelected] = useState("");
  const [lambdaError, setLambdaError] = useState("");
  const [poSelected, setPoSelected] = useState();
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const deleteModalOpenRef = useRef();
  const deleteModalCloseRef = useRef();

  const onUserSelectProcess = (check, value) => {
    if (check) {
      setProcessSelected(value);
    } else {
      setProcessSelected("");
    }
  };

  const userProceedProcess = () => {
    if (processSelected === "GRN") {
      getGrnList();
    }
  };

  const getGrnList = async () => {
    try {
      setIsListLoading(true);
      let payload = {
        loc: loc,
      };

      const grnListData = await grnListChange(payload);

      if (grnListData) {
        if (grnListData?.status === "error") {
          setLambdaError(grnListData?.error_message);
          openErrRef.current.click();
          setIsListLoading(false);
        } else {
          if (Object.keys(grnListData?.body)) {
            addGrnList(grnListData?.body);
            setIsListLoading(false);
            setProcessLoadComplete(true);
          }
        }
      } else {
        setIsListLoading(false);
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
      }
    } catch (error) {
      console.log(error);
      setIsListLoading(false);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const onAdminUpdatesGrn = async () => {
    try {
      setIsProceedLoading(true);
      let payload = {
        order_no: poSelected?.order_no,
        invoice_no: poSelected?.invoice_no,
      };

      const grnupdated = await adminConsoleChange(payload);

      if (grnupdated) {
        if (grnupdated?.status === "error") {
          setLambdaError(grnupdated?.error_message);
          openErrRef.current.click();
          setIsProceedLoading(false);
        } else {
          deleteModalOpenRef.current.click();
          setIsProceedLoading(false);
          setPoSelected("");
        }
      } else {
        setIsProceedLoading(false);
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
      }
    } catch (error) {
      console.log(error);
      setIsProceedLoading(false);
      setLambdaError(error);
      openErrRef.current.click();
    }
  };

  const onUserSelectPo = (checked, values) => {
    if (checked) {
      setPoSelected(values);
    } else {
      setPoSelected("");
    }
  };

  const resetData = () => {
    setProcessLoadComplete(false);
    setIsListLoading(false);
    setIsProceedLoading(false);
    setProcessSelected("");
    setPoSelected();
  };

  const DeleteSuccess = () => (
    <div className="d-flex flex-column align-items-center">
      <div className="mismatch-text">Data deleted successfully</div>
      <button
        className="btn btn-preview mt-4 d-flex align-items-center"
        onClick={() => {
          deleteModalCloseRef.current.click();
          getGrnList();
        }}
      >
        OK
      </button>
    </div>
  );

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="d-flex flex-column w-100 ad-view"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          progressBar={false}
          headerText={"Data Purge"}
        />
        <div className="ad-content-view">
          {!processLoadComplete ? (
            <div className="d-flex flex-column align-items-center">
              <div className="ad-content-head">Process Flow</div>
              <div className="d-flex flex-column mt-3">
                {processFlowTypes?.map((process, i) => {
                  return (
                    <div className="d-flex flex-row mb-2" key={i}>
                      <input
                        className="me-3"
                        type={"checkbox"}
                        onChange={(e) => {
                          onUserSelectProcess(e.target.checked, process);
                        }}
                        checked={processSelected === process}
                        disabled={process !== "GRN"}
                      />
                      <div
                        className={[
                          "ad-process-name",
                          process !== "GRN" ? "text-black-50" : "",
                        ].join(" ")}
                      >
                        {process}
                      </div>
                    </div>
                  );
                })}
              </div>
              <button
                className="btn btn-preview mt-3 d-flex align-items-center"
                onClick={userProceedProcess}
                disabled={!processSelected || isListLoading}
              >
                <div>Data Purge</div>
                {isListLoading && <div className="spinner  ms-2"></div>}
              </button>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <button
                className="btn btn-proceed me-auto d-flex align-items-center mb-2"
                onClick={() => {
                  setProcessLoadComplete(false);
                  resetData();
                }}
              >
                <img className="back-icon me-2" src={BackIcon} alt="back" />
                <div>Back</div>
              </button>
              <div className="d-flex flex-column">
                {processSelected === "GRN" && (
                  <div className="grn-table grn-list-items">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Order No.</th>
                          <th>Invoice/DC No.</th>
                          <th>Select PO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {grnList?.map((grn, i) => (
                          <tr key={i}>
                            <td>{grn?.order_no || "-"}</td>
                            <td>{grn?.invoice_no || "-"}</td>
                            <td>
                              <input
                                type={"checkbox"}
                                onChange={(e) => {
                                  onUserSelectPo(e.target.checked, {
                                    order_no: grn?.order_no,
                                    invoice_no: grn?.invoice_no,
                                  });
                                }}
                                checked={
                                  poSelected?.order_no === grn?.order_no &&
                                  poSelected?.invoice_no === grn?.invoice_no
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="d-flex  justify-content-center">
                  <button
                    className="btn btn-preview mt-3 d-flex align-items-center"
                    onClick={onAdminUpdatesGrn}
                    disabled={isProceedLoading || !poSelected}
                  >
                    <div>Proceed</div>
                    {isProceedLoading && <div className="spinner ms-2"></div>}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CommonModal
        modalOpenRef={deleteModalOpenRef}
        modalCloseRef={deleteModalCloseRef}
        id={"rgSuccessModal"}
        content={<DeleteSuccess />}
        header={true}
        headerBorder={false}
        modalSize={"sm"}
        onModalClose={() => {
          deleteModalCloseRef.current.click();
          getGrnList();
        }}
      />
      <ErrorModal
        id={"errorModal"}
        openRef={openErrRef}
        closeRef={closeErrRef}
        headerColor={"red"}
        msg={lambdaError}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
    grnList: state?.grn?.grnList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { addGrnList: (data) => dispatch(grnActions.addGrnList(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminConsole);
