import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { authActions } from "../../store/auth";
import { grnActions } from "../../store/grn";
import { useNavigate, useLocation } from "react-router-dom";
import LogoIcon from "../../assets/images/logo.png";
import userIcon from "../../assets/icons/user.svg";
import CommonModal from "../../containers/common_modal";
import ErrorModal from "../../containers/error_modal";
import "./header.css";

const Header = ({ ...props }) => {
  const {
    logout,
    user,
    clearGrnData,
    widthIsSmall,
    clearTokenData,
    token,
    lambdaErr,
    updateLambdaErr,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const invokeReloginModal = useRef();
  const closeReloginModal = useRef();
  const errorOpenErrRef = useRef();
  const errorCloseErrRef = useRef();

  const onLogout = () => {
    logout();
    setTimeout(() => {
      navigate("/login");
      clearTokenData();
      clearGrnData();
    }, 100);
  };

  useEffect(() => {
    if (!token) {
      invokeReloginModal.current.click();
    }
  }, [token]);

  useEffect(() => {
    if (lambdaErr) {
      errorOpenErrRef.current.click();
    }
  }, [lambdaErr]);

  const Relogin = () => (
    <div className="d-flex flex-column mt-4 mx-3 mb-2">
      <div className="add-item-head">Please relogin again</div>
      <div className="d-flex flex-column">
        <div className="submit-add-text">
          You session has expired. Please press Relogin to continue.
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-preview mt-5"
          onClick={() => {
            closeReloginModal.current.click();
            onLogout();
          }}
        >
          Relogin
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div
        className="d-flex justify-content-between hd align-items-center"
        style={{
          padding: widthIsSmall ? "10px 0px 10px 12px" : "12px 26px 12px 60px",
        }}
      >
        <img
          className="head-icon"
          src={LogoIcon}
          alt="img"
          onClick={() => navigate("/home")}
        />
        <div className="d-flex align-items-center">
          <div
            className={[
              "hd-detail d-flex flex-column justify-content-end text-end",
              widthIsSmall && "mr-sm",
            ].join(" ")}
          >
            <div className="hd-user text-capitalize">
              {" "}
              {(user?.name && `${user?.name}`) || ""}
            </div>
            <div className="hd-role text-capitalize">
              {(user?.role &&
                `${user?.role} - ${user?.loc} ( ${user?.locationName} )`) ||
                ""}
            </div>
          </div>
          <div className="dropdown">
            <div
              className="dropdown-toggle"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={userIcon} alt="user" className="user-icon" />
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li
                className="ps-3"
                onClick={() =>
                  navigate(
                    location.pathname === "/profile" ? "/home" : "/profile"
                  )
                }
              >
                {location.pathname !== "/profile" ? "Switch Profile" : "Home"}
              </li>
              <li className="ps-3" onClick={onLogout}>
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CommonModal
        id={"reloginModal"}
        content={<Relogin />}
        modalOpenRef={invokeReloginModal}
        modalCloseRef={closeReloginModal}
      />
      <ErrorModal
        id={"errorCommonModal"}
        openRef={errorOpenErrRef}
        closeRef={errorCloseErrRef}
        headerColor={"red"}
        msg={"Something went wrong"}
        closeModal={() => {
          updateLambdaErr(false);
        }}
        backDrop={true}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    widthIsSmall: state?.auth?.widthIsSmall,
    token: state?.auth?.token,
    lambdaErr: state?.grn?.lambdaErr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authActions.logout()),
    clearGrnData: () => dispatch(grnActions.clearGrnData()),
    clearTokenData: () => dispatch(authActions.clearTokenData()),
    updateLambdaErr: (data) => dispatch(grnActions.updateLambdaErr(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
