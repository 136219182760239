import { connect } from "react-redux";
import {
  outboundPriority,
  inboundPriority,
  viewPriorityList,
  inventoryPriority,
  adminPriority,
  cmPriority,
  internalPriority,
} from "../../constants/constants";
import Unauthorized from "../../containers/unauthorized";

const PrivateRoute = ({ children, ...props }) => {
  const { role } = props;

  const findUserPriority = () => {
    if (role === "inward") return inboundPriority;
    if (role === "outward") return outboundPriority;
    if (role === "inventory") return inventoryPriority;
    if (role === "admin") return adminPriority;
    if (role === "cm") return cmPriority;
    if (role === "wms_internal") return internalPriority;
  };

  let checkKey = viewPriorityList.find(
    (view) => view.value === children?.props?.type
  );

  const exist = findUserPriority().includes(checkKey?.key);

  if (exist) {
    return children;
  } else {
    return <Unauthorized />;
  }
};

const mapStateToProps = (state) => {
  return {
    role: state?.auth?.user?.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
